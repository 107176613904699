import { IUserType } from "@/store/account/interface";
import { useCallback } from "react";
import useUserDetail from "./useUserDetail";

const useRole = () => {
  const info = useUserDetail();

  const auth = useCallback(
    (el: any, authList?: IUserType[]) => {
      if (!authList) {
        return el;
      }
      if (!info) {
        return null;
      }
      // 如果当前客户为admin，开放所有操作权限
      if (info.roleCodes.includes(IUserType.SUPERADMIN)) {
        return el;
      }
      return info.roleCodes.map((t) => (authList.includes(t) ? el : null));
    },
    [info]
  );

  return auth;
};

export default useRole;
