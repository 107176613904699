// import useAsyncData from "@/hooks/useAsyncData";
// import { AxiosRequestConfig } from "axios";
// import { FC, useRef, useState } from "react";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import { DataNode } from "antd/lib/tree";
import { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import EllipsisTooltip from "../ellipsisTooltip";
import { ICallback, ISelectItem } from "../interface";
// import { reqAndRunCallback } from "./utils";

/** 表格省略号 */
export const ellopsisOnCell: any = () => {
  return {
    style: {
      whiteSpace: "nowrap",
      maxWidth: 150,
    },
  };
};

export const ellopsisRender = (text: React.ReactNode | string) => {
  return <EllipsisTooltip title={text}>{text}</EllipsisTooltip>;
};

//重定向路由
export const Redirect = ({ to }: { to: string }) => {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
};

// 将后台需要的枚举值转换为前端展示值
export const transformOptions = (
  options: ISelectItem[] | string[],
  init: boolean = true
) => {
  const map: any = {};
  options.forEach((option: any) => {
    if (typeof option === "string") {
      map[option] = option;
    } else {
      map[`${option.id}`] = option.name;
    }
  });
  return (value: any) => {
    if (!value && value !== 0) {
      return "-";
    }
    if (typeof map[value] === "undefined") {
      console.error("出现未知选项", value);
      return value;
    }
    if (!init) {
      return map[`${value}`];
    }
    return <FormattedMessage id={map[`${value}`]} />;
  };
};


// 将后台需要的枚举值转换为前端展示值
export const transformOptionsNoIntl = (
  options: ISelectItem[] | string[],
  init: boolean = true
) => {
  const map: any = {};
  options.forEach((option: any) => {
    if (typeof option === "string") {
      map[option] = option;
    } else {
      map[`${option.id}`] = option.name;
    }
  });
  return (value: any) => {
    if (!value && value !== 0) {
      return "-";
    }
    if (typeof map[value] === "undefined") {
      console.error("出现未知选项", value);
      return value;
    }
    if (!init) {
      return map[`${value}`];
    }
    return map[`${value}`];
  };
};

export const transformOptionsValueWeb = (
  options: ISelectItem[] | string[],
  value: string | number
) => {
  const map: any = {};
  options.forEach((option: any) => {
    if (typeof option === "string") {
      map[option] = option;
    } else {
      map[`${option.id}`] = option.name;
    }
  });
  if (!value && value !== 0) {
    return "-";
  }
  if (typeof map[value] === "undefined") {
    console.error("出现未知选项", value);
    return value;
  }
  return <FormattedMessage id={map[`${value}`]} />;
};

export const TimeShowComp: FC<{
  value?: any;
  onChange?: ICallback<any>;
  el1: React.ReactNode;
  el2: React.ReactNode;
}> = (props) => {
  return (
    <>
      {props?.el1}
      {props.value ? props.el2 : <></>}
    </>
  );
};

//递归将title转换为国际化
export const TreeNodeTitleInternational = (data: DataNode[]) => {
  const IntlText = useIntlDep();
  const newData = data.map((item: DataNode) => {
    if (item.children) {
      item.children = TreeNodeTitleInternational(item.children);
    }
    if (typeof item.title === "string") {
      item.title = IntlText({ id: item.title });
    }
    return item;
  });
  return newData;
};

export const ICopyableObj = {
  icon: <IntlDep id={"COPY"} />,
  tooltips: [<IntlDep id={"COPY"} />, <IntlDep id={"COPY_SUCCESS"} />],
};

/**
 * 将后台需要的异步枚举值转换为前端展示值
 * @param config api
 * @param listcacheid id
 * @param value 当前值
 * @returns
 */
// export const AsyncTransformOptions: FC<{
//   config: (params?: any, data?: any) => AxiosRequestConfig;
//   value: string | number;
// }> = ({ config, value }) => {
//   const { current: queryConfig } = useRef(config);
//   const [optList, setoptList] = useState<any[]>([]);
//   const map: any = {};
//   // const [optList] = useAsyncData<any>({
//   //   cacheId: listcacheid,
//   //   queryConfig,
//   // });
//   reqAndRunCallback(queryConfig({}, {}), (res) => {
//     if (res) {
//       setoptList(res);
//     }
//   });
//   if (!optList) {
//     return <span>-</span>;
//   }
//   const _optList = optList;
//   _optList.forEach((option: any) => {
//     if (typeof option === "string") {
//       map[option] = option;
//     } else {
//       map[`${option.id}`] = option.name;
//     }
//   });
//   if (!value) {
//     return <span>-</span>;
//   }
//   if (typeof map[value] === "undefined") {
//     return <span>{value}</span>;
//   }
//   return <span>{map[`${value}`]}</span>;
// };
