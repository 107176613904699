import ConditionShow from "@/common/conditionShow";
import IconFont from "@/common/icon";
import { ILoginForm } from "@/common/interface";
import Logo from "@/common/layout/header/img/logo.svg";
import { reqAndRunCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import useSubmitEvent from "@/hooks/useSubmitEvent";
import useVisible from "@/hooks/useVisible";
import { ITokenStr } from "@/store/account/interface";
import accountService from "@/store/account/service";
import userAPI from "@/store/apis/account/user";
import reqServicePlx from "@/store/request/reqServicePlx";
import useWebSiteInfo from "@/store/webSiteInfo/useWebSiteInfo";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, notification, Row, Spin } from "antd";
import querystring from "query-string";
import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import ReCAPTCHA from "react-google-recaptcha";
import { from } from "rxjs";
import LoginForm from "./form/login";
import RegisterForm from "./form/register";
import { googleUrl, grecaptcha } from "./interface";

const Login: FC = () => {
  const [form] = Form.useForm();
  const [isRegister, setIsRegister] = useVisible();
  const [loading, setLoading] = useState(false);
  const [disableFacebook, setDisableFacebook] = useState(false);
  const [RecaptchaKey, setRecaptchaKey] = useState<string>("");
  const [pwdisSame, setPwdisSame] = useState<boolean>(false);
  const IndText = useIntlDep();
  const webSiteInfo = useWebSiteInfo();
  const [logoadd, setLogoAdd] = useState<string>(Logo);
  const usiParamCode = useMemo(
    () => querystring.parseUrl(window.location.href).query,
    []
  );
  const cutLoginReg = useCallback(() => {
    form.resetFields();
    setIsRegister();
  }, [form, setIsRegister]);
  useEffect(() => {
    if (Object.keys(usiParamCode).length) {
      setDisableFacebook(true);
      setLoading(true);
      const config = userAPI.userGoogleGoginCode(
        { code: usiParamCode.code as string },
        {}
      );
      from(reqServicePlx(config)).subscribe((res) => {
        if (res.isSuccess && res.result) {
          accountService.setToken(res.result as ITokenStr);
        } else if (!res.isSuccess) {
          setLoading(false);
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      });
    }
  }, [usiParamCode]);

  useEffect(() => {
    if (webSiteInfo) {
      setLogoAdd(webSiteInfo.logoAddress);
    }
  }, [webSiteInfo]);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  useEffect(() => {
    const config = userAPI.findRecaptchaKey({}, {});
    reqAndRunCallback(config, (res) => setRecaptchaKey(res));
  }, [setRecaptchaKey]);
  const loginFinish = useCallback(
    (data: { username: any; password: any; }) => {
      if (!data.username || !data.password) {
        notification.warning({
          message: IndText({ id: "THE_USER_NAME_OR_PASSWORD_CANNOT_BE_EMPTY" }),
          duration: 1.5,
        });
        return;
      }
      if (loading) {
        return;
      }
      setLoading(true);
      const _data = {
        type: 1,
        ...data,
      };
      const config = userAPI.Login({}, _data);
      from(reqServicePlx(config)).subscribe((res) => {
        if (res.isSuccess && res.result) {
          accountService.setToken(res.result as ITokenStr);
        } else if (!res.isSuccess) {
          setLoading(false);
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      });
    },
    [loading, IndText]
  );
  const registerFinish = useCallback(
    (data:any) => {
      if (!data.username) {
        if (!pwdisSame) {
          return;
        }
        return;
      }
      if (loading) {
        return;
      }
      setLoading(true);
      const _data = {
        ...data,
      };
      delete _data.types;
      const config = userAPI.register({}, _data);
      from(reqServicePlx(config)).subscribe((res) => {
        if (res.isSuccess && res.result) {
          accountService.setToken(res.result as ITokenStr);
        } else if (!res.isSuccess) {
          setLoading(false);
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      });
    },
    [loading, pwdisSame]
  );
  const onFinish = useCallback(
    (data: any) => {
      if (!data.types) {
        loginFinish(data);
      } else {
        registerFinish(data);
      }
    },
    [loginFinish, registerFinish]
  );

  const submitEvent = useCallback(() => {
    const data: ILoginForm = form.getFieldsValue();
    onFinish(data);
  }, [form, onFinish]);
  const facebookCallback = useCallback(
    (fbInfo:any) => {
      setLoading(true);
      if (disableFacebook) {
        return;
      }
      const _data = {
        type: 2,
        facebookLoginForm: {
          token: fbInfo.accessToken,
          name: fbInfo.name,
          faceBookUserId: fbInfo.userID,
        },
      };
      const config = userAPI.Login({}, _data as any);
      from(reqServicePlx(config)).subscribe((res) => {
        if (res.isSuccess && res.result) {
          accountService.setToken(res.result as ITokenStr);
        } else if (!res.isSuccess) {
          setLoading(false);
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      });
    },
    [disableFacebook]
  );
  const submitClick = useSubmitEvent(submitEvent);

  return (
    <section className="login-page-form">
      <div className="login-logo">
        <img src={logoadd} alt="logo" />
      </div>
      <ConditionShow className="login-loading-container" visible={loading}>
        <div className="login-loading">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          <span>Waiting for login...</span>
        </div>
      </ConditionShow>
      <Form
        layout="vertical"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
      >
        <ConditionShow removeMode visible={!isRegister}>
          <LoginForm />
        </ConditionShow>
        <ConditionShow removeMode visible={isRegister}>
          <RegisterForm setPwdisSame={setPwdisSame} pwdisSame={pwdisSame} />
        </ConditionShow>
        {RecaptchaKey && (
          <Form.Item name="recaptchaResult">
            <ReCAPTCHA
              ref={recaptchaRef}
              grecaptcha={grecaptcha}
              sitekey={RecaptchaKey}
            />
          </Form.Item>
        )}
        <Button type="primary" className="btn-primary" onClick={submitClick}>
          {!isRegister
            ? IndText({ id: "LOG_IN" })
            : IndText({ id: "REGISTRATION" })}
        </Button>
        <div className="text-center register-text">
          <ConditionShow visible={!isRegister}>
            <IntlDep id="NO_ACCOUNT" /> &nbsp;
            <Button onClick={cutLoginReg} style={{ padding: 0 }} type="link">
              <IntlDep id="REGISTRATION" />
              &gt;
            </Button>
          </ConditionShow>
          <ConditionShow visible={isRegister}>
            <div style={{ marginBottom: 15 }}>
              <Button onClick={cutLoginReg} style={{ padding: 0 }} type="link">
                <IntlDep id="OTHER_ACCOUNT_LOGIN" />
                &nbsp;
              </Button>
              OR&nbsp;
              <IntlDep id="THIRD_PARTY_LOGIN" />
            </div>
          </ConditionShow>
        </div>
        <ConditionShow visible={!isRegister}>
          <Divider />
          <p className="text-center sanfang-text">
            <IntlDep id="THIRD_PARTY_LOGIN" />
          </p>
        </ConditionShow>
        <Row>
          <Col className="text-center" span={12}>
            <FacebookLogin
              appId="354374566769613"
              onClick={() => setDisableFacebook(false)}
              callback={facebookCallback}
              // callback={}
              render={(renderProps: {
                onClick: MouseEventHandler<HTMLSpanElement> | undefined;
              }) => (
                <IconFont
                  onClick={renderProps.onClick}
                  className="third-party-icon"
                  style={{ fontSize: 68 }}
                  type="iconfacebook"
                />
              )}
            />
          </Col>
          <Col className="text-center" span={12}>
            <a
              rel="noreferrer"
              onClick={() => setDisableFacebook(true)}
              href={googleUrl}
            >
              <IconFont
                className="third-party-icon"
                style={{ fontSize: 68 }}
                type="icona-Group1831"
              />
            </a>
          </Col>
        </Row>
      </Form>
    </section>
  );
};

export default Login;
