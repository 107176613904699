import { AxiosRequestConfig } from "axios";
import {
  IsetCustomerDnsForm,
  IsetSystemBasicInfoForm,
  IsetSystemGoogleRecaptchaForm,
  IsetSystemPrometheusForm
} from "./interface";

class LookupAPI {
  /**
   * countryList
   * 生成请求参数
   */
  countryList = (params: IcountryListParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/country_list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * dnsInfo
   * 生成请求参数
   */
  dnsInfo = (params: IdnsInfoParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/dns_info",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * Lines
   * 生成请求参数
   */
  Lines = (params: ILinesParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/lines",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * nameList
   * 生成请求参数
   */
  nameList = (params: InameListParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/name_list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * regionList
   * 生成请求参数
   */
  regionList = (params: IregionListParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/region_list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  getSystemBasicInfo = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/get_system_basic_info",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  setSystemBasicInfo = (params: {}, data: IsetSystemBasicInfoForm) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/set_system_basic_info",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  getCustomerDns = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/get_customer_dns",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  setCustomerDns = (params: {}, data: IsetCustomerDnsForm) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/set_customer_dns",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  getSystemGoogleRecaptcha = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/get_system_google_recaptcha",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  setSystemGoogleRecaptcha = (
    params: {},
    data: IsetSystemGoogleRecaptchaForm
  ) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/set_system_google_recaptcha",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  getSystemPrometheus = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/get_system_prometheus",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  setSystemPrometheus = (
    params: {},
    data: IsetSystemPrometheusForm
  ) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/set_system_prometheus",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
}

const lookAPI = new LookupAPI();
export default lookAPI;

/** countryList的请求参数*/
interface IcountryListParams {}

/** dnsInfo的请求参数*/
interface IdnsInfoParams {}

/** Lines的请求参数*/
interface ILinesParams {}

/** nameList的请求参数*/
interface InameListParams {}

/** regionList的请求参数*/
interface IregionListParams {}
