import { Descriptions } from "antd";
import React from "react";
import CheckButton from "../checkButton";
import { IMapT } from "@/common/template/interface";
import { IViewRenderItem } from "@/common/form/interface";
import IntlDep from "@/hooks/intl/IntlDep";
import { transformOptions } from "@/common/utils/utilsX";

type IParam = IViewRenderItem & {
  value: string | number;
  hideMap?: IMapT<boolean>;
  key: string | number;
  cols: number;
};

const viewDetail = (props: IParam) => {
  if (props.hideMap && props.hideMap[props.dataItem]) {
    return null;
  }
  if (!props.text) {
    return null;
  }
  let el: React.ReactNode = null;
  let fillSpan = false;
  if (!!props.type) {
    const labelName = props.type;
    if (labelName === CheckButton.name){
      el = (
          <CheckButton
              value={props.value}
              disable
              true={props.true}
              false={props.false}
          />
      )
    }
  } else {
    fillSpan = true;
  }
  if(Array.isArray(props.data)){
    el = transformOptions(props.data)(props.value);
  }
  if (!el) {
    el = props.value;
  }

  return (
    <Descriptions.Item
      key={props.key}
      label={<IntlDep id={props.text} values={props.textValue} />}
      span={fillSpan ? props.cols : 1}
    >
      <div style={{ whiteSpace: "pre-line" }}>{el}</div>
    </Descriptions.Item>
  );
};

export default viewDetail;
