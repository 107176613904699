import FormS from "@/common/form/FormS";
import SelectP from "@/common/form/SelectP";
import { langList } from "@/common/timezoneList";
import { reqAndRunCallback } from "@/common/utils/utils";
import useEventId from "@/hooks/useEventId";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail from "@/hooks/useUserDetail";
import { IAccountInfo } from "@/store/account/interface";
import accountService from "@/store/account/service";
import userAPI from "@/store/apis/account/user";
import areaAPI from "@/store/apis/node/area";
import { INodeAddForm } from "@/store/apis/node/interface";
import nodeAPI from "@/store/apis/node/node";
import messageService from "@/store/messages";
import reqService from "@/store/request";
import reqServicePlx from "@/store/request/reqServicePlx";
import webSiteInfoService from "@/store/webSiteInfo/service";
import { Col, Divider, notification, Row, Steps } from "antd";
import { AxiosRequestConfig } from "axios";
import {
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { FormattedMessage } from "react-intl";
import { forkJoin, from } from "rxjs";
import AssociatedNode from "./form/AssociatedNode";
import CreateNode from "./form/CreateNode";
import InstallNode, { IDataItem } from "./form/InstallNode";
// import "./index.less";

const AdminGuide: FC = () => {
  const [current, setCurrent] = useState<number>(0);
  const [pageCurrent, setPageCurrent] = useState<number>(0);
  const formId = useEventId();
  const originFormId = useEventId();
  const associatedId = useEventId();
  const [nodeType, setNodeType] = useState<string>("");
  const [areaId, setAreaId] = useState<number>(0);
  const IntlDepText = useIntlDep();
  const [needInstallNode, setNeedInstallNode] = useState([]);

  const [edgeNodeList, setEdgeNodeList] = useState<IDataItem[]>([]);
  const [originNodeList, setOriginNodeList] = useState<IDataItem[]>([]);
  const childRef: any = useRef();
  const [hasEdgeNode, setHasEdgeNode] = useState<boolean>();
  const [hasOriginNode, setHasOriginNode] = useState<boolean>();
  const userInfo = useUserDetail();
  const time = useRef<any>();
  const getEdgeNodeList = useCallback(() => {
    const config = nodeAPI.FindNodeTypeList(
      {},
      { typeList: ["edge"], statusList: [0, 1, 2, 3] }
    );
    reqAndRunCallback(config, (res) => {
      setEdgeNodeList(res);
    });
  }, []);
  const getOriginNodeList = useCallback(() => {
    const config = nodeAPI.FindNodeTypeList(
      {},
      { typeList: ["origin"], statusList: [0, 1, 2, 3] }
    );
    reqAndRunCallback(config, (res) => {
      setOriginNodeList(res);
    });
  }, []);
  type NotificationType = "success" | "info" | "warning" | "error";

  const openNotificationWithIcon = useCallback(
    (type: NotificationType, str: string) => {
      notification[type]({
        message: "",
        description: IntlDepText(str),
      });
    },
    [IntlDepText]
  );

  /* 生成ReactNode函数 */
  const createButton = (str1: string, str2: string, num: number) => {
    return num !== 4 ? (
      <>
        <span style={{ fontSize: 12 }}>{str1}</span>
        <span
          style={{
            marginLeft: "5px",
            padding: 0,
            fontSize: 12,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleClick(num)}
        >
          {str2}
        </span>
      </>
    ) : (
      <>
        <span style={{ fontSize: 12 }}>{str1}</span>
        <span
          style={{
            marginLeft: "5px",
            padding: 0,
            fontSize: 12,
            cursor: "pointer",
          }}
        >
          {str2}
        </span>
      </>
    );
  };
  /*步骤点击函数 */
  const handleClick = useCallback(
    (currents: number) => {
      // if (currents > current) {
      //   return;
      // }
      if (current >= currents) {
        switch (currents) {
          case 0: {
            // if (current >= currents) {
            setNodeType("edge");
            messageService.onceUpadte(formId, { nodeType: "edge" });
            setPageCurrent(currents);
            // }
            break;
          }
          case 1: {
            // if (current >= currents) {
            setNodeType("origin");
            messageService.onceUpadte(originFormId, { nodeType: "origin" });
            setPageCurrent(currents);
            // }
            break;
          }
          case 2: {
            // if (current >= currents) {
            messageService.onceUpadte(associatedId, { nodeType: "origin" });
            const config = areaAPI.GetDefaultArea({}, {});
            reqAndRunCallback(config, (res) => {
              const config = areaAPI.AreaFindNodeList({ id: res.id }, {});
              from(reqServicePlx(config)).subscribe((res2) => {
                if (res2.isSuccess && res2.result) {
                  const data = Object.assign({}, res2.result, {
                    areaId: res.id,
                  });
                  messageService.formUpdate(associatedId, data);
                } else {
                  messageService.formUpdate(associatedId, { areaId: res.id });
                }
              });
              setAreaId(res.id);
            });
            setPageCurrent(currents);
            // }
            break;
          }
          case 3: {
            if (current >= currents) {
              setPageCurrent(currents);
              getEdgeNodeList();
              getOriginNodeList();
            }
            break;
          }
          case 4: {
            setCurrent(5);
            break;
          }
        }
      }
    },
    [
      formId,
      associatedId,
      current,
      originFormId,
      getEdgeNodeList,
      getOriginNodeList,
    ]
  );
  /* 步骤条生成数据 */
  const steps = [
    // 创建边缘节点
    {
      title: createButton(
        `${IntlDepText({ id: "FIRST_STEP" })}`,
        `${IntlDepText({ id: "CREATING_EDGE_NODES" })}`,
        0
      ),
      description: `${IntlDepText({ id: "CREATING_EDGE_NODES" })}`,
    },
    // 创建源节点
    {
      title: createButton(
        `${IntlDepText({ id: "SECOND_STEP" })}`,
        `${IntlDepText({ id: "CREATING_SOURCE_NODE" })}`,
        1
      ),
      description: `${IntlDepText({ id: "CREATING_SOURCE_NODE" })}`,
    },
    // 关联节点
    {
      title: createButton(
        `${IntlDepText({ id: "THIRD_STEP" })}`,
        `${IntlDepText({ id: "ASSOCIATED_NODE" })}`,
        2
      ),
      description: `${IntlDepText({ id: "ASSOCIATED_NODE" })}`,
    },
    // 安装节点
    {
      title: createButton(
        `${IntlDepText({ id: "FOURTH_STEP" })}`,
        `${IntlDepText({ id: "INSTALLATION_NODE" })}`,
        3
      ),
      description: `${IntlDepText({ id: "INSTALLATION_NODE" })}`,
    },
    // 完成
    {
      title: createButton(
        `${IntlDepText({ id: "FIVE_STEP" })}`,
        `${IntlDepText({ id: "COMPLETE" })}`,
        4
      ),
      description: `${IntlDepText({ id: "COMPLETE" })}`,
    },
  ];

  /* 创建节点函数 */
  const createNode = useCallback(
    (data: INodeAddForm) => {
      if (current > pageCurrent) {
        if (pageCurrent === 0) {
          setNodeType("origin");
          messageService.onceUpadte(originFormId, { nodeType: "origin" });
          setPageCurrent(1);
        }
        if (pageCurrent === 1) {
          setPageCurrent(2);
          const config = areaAPI.GetDefaultArea({}, {});
          reqAndRunCallback(config, (res) => {
            const config = areaAPI.AreaFindNodeList({ id: res.id }, {});
            from(reqServicePlx(config)).subscribe((res2) => {
              if (res2.isSuccess && res2.result) {
                const data = Object.assign({}, res2.result, {
                  areaId: res.id,
                });
                messageService.formUpdate(associatedId, data);
              } else {
                messageService.formUpdate(associatedId, { areaId: res.id });
              }
            });
            setAreaId(res.id);
          });
        }
        // return;
      }
      const config = nodeAPI.nodeAdd({}, data);
      reqAndRunCallback(config, () => {
        // 创建成功后判断
        if (nodeType === "edge") {
          console.log(nodeType, "nodeType");
          openNotificationWithIcon(
            "success",
            "THE_EDGE_NODE_IS_CREATED_SUCCESSFULLY"
          );
          setHasEdgeNode(true);
          /* 如果添加的是边缘节点，则查询源节点个数和是否关联节点
                有源节点  
                  已经关联过节点    setCurrent(3);
                    已经安装节点    setCurrent(5);
                    未安装节点      setCurrent(3);
                  未关联节点  步骤到关联节点setCurrent(2);
                无源节点  步骤到创建源节点setCurrent(1);
          */
          const originConfig = nodeAPI.NodeTypeCounts({ type: "origin" }, {});
          const areaConfig = areaAPI.FindArearelationNode({}, {});
          const originInstallConfig = nodeAPI.FindInstallNodeCounts(
            {},
            { typeList: ["origin"], statusList: [1, 2, 3] }
          );
          const edgeInstallConfig = nodeAPI.FindInstallNodeCounts(
            {},
            { typeList: ["edge"], statusList: [1, 2, 3] }
          );
          forkJoin([
            reqServicePlx<any>(originConfig),
            reqServicePlx<any>(areaConfig),
            reqServicePlx<any>(originInstallConfig),
            reqServicePlx<any>(edgeInstallConfig),
          ]).subscribe((res) => {
            if (res[0].isSuccess && res[0].result > 0) {
              if (res[1].isSuccess && res[1].result) {
                if (
                  res[2].isSuccess &&
                  res[2].result > 0 && // ?????????????? 0
                  res[3].isSuccess &&
                  res[3].result > 0 // ?????????????? 0
                ) {
                  setCurrent(5);
                  setPageCurrent(5);
                  webSiteInfoService.modifySiteGuideStatus(true);
                } else {
                  setCurrent(3);
                  setPageCurrent(3);
                  getEdgeNodeList();
                  getOriginNodeList();
                }
              } else {
                setCurrent(2);
                setPageCurrent(2);
              }
            } else {
              setCurrent(1);
              setPageCurrent(1);
              setNodeType("origin");
              messageService.onceUpadte(originFormId, { nodeType: "origin" });
            }
          });
        }
        if (nodeType === "origin") {
          setHasOriginNode(true);
          openNotificationWithIcon(
            "success",
            "THE_SOURCE_NODE_IS_CREATED_SUCCESSFULLY"
          );

          // if (current > pageCurrent) {
          //   setPageCurrent(2);
          //   return;
          // }
          /* 如果是创建源节点
              查询是否关联节点
                已关联节点  步骤完成setCurrent(3);
                  已安装节点 setCurrent(4);
                  已安装节点 setCurrent(3);
                未关联节点  步骤关联节点setCurrent(2);
          */
          const areaConfig = areaAPI.FindArearelationNode({}, {});
          const originInstallConfig = nodeAPI.FindInstallNodeCounts(
            {},
            { typeList: ["origin"], statusList: [1] }
          );
          const edgeInstallConfig = nodeAPI.FindInstallNodeCounts(
            {},
            { typeList: ["edge"], statusList: [1] }
          );
          forkJoin([
            reqServicePlx<any>(areaConfig),
            reqServicePlx<any>(originInstallConfig),
            reqServicePlx<any>(edgeInstallConfig),
          ]).subscribe((res) => {
            if (res[0].isSuccess && res[0].result) {
              if (
                res[1].isSuccess &&
                res[1].result > 0 && // ?????????????? 0
                res[2].isSuccess &&
                res[2].result > 0 // ?????????????? 0
              ) {
                setCurrent(5);
                setPageCurrent(5);
                webSiteInfoService.modifySiteGuideStatus(true);
              } else {
                setCurrent(3);
                setPageCurrent(3);
                getEdgeNodeList();
                getOriginNodeList();
              }
            } else {
              setCurrent(2);
              setPageCurrent(2);
            }
          });
        }
        setNodeType("");
      });
    },
    [
      nodeType,
      current,
      pageCurrent,
      associatedId,
      originFormId,
      getEdgeNodeList,
      getOriginNodeList,
      openNotificationWithIcon,
    ]
  );
  /* 关联数据中心函数 */
  const NodeManagementCb = useCallback(
    (data: any) => {
      if (current > pageCurrent) {
        setPageCurrent(3);
        return;
      }
      const newData = { ...data };
      delete newData.yname;
      newData.areaId = areaId;
      newData.edgeIds = newData.edgeNodes;
      newData.originIds = newData.originNodes;
      newData.middleIds = newData.middleNodes;
      for (let key in newData) {
        if (Array.isArray(newData[key])) {
          newData[key] = newData[key].map(
            (t: { key: number; value: string }) => {
              if (t.key) {
                return t.key;
              }
              return t;
            }
          ) as any[];
          newData[key] = newData[key].filter((t: any) => t);
        }
      }
      delete newData.edgeNodes;
      delete newData.middleNodes;
      delete newData.originNodes;
      console.log(newData);
      const config = areaAPI.AreaSetNodeList({}, newData);
      reqAndRunCallback(config, () => {
        // setCurrent(3);
        const areaConfig = areaAPI.FindArearelationNode({}, {});
        const originInstallConfig = nodeAPI.FindInstallNodeCounts(
          {},
          { typeList: ["origin"], statusList: [1] }
        );
        const edgeInstallConfig = nodeAPI.FindInstallNodeCounts(
          {},
          { typeList: ["edge"], statusList: [1] }
        );
        forkJoin([
          reqServicePlx<any>(areaConfig),
          reqServicePlx<any>(originInstallConfig),
          reqServicePlx<any>(edgeInstallConfig),
        ]).subscribe((res) => {
          if (res[0].isSuccess && res[0].result) {
            if (
              res[1].isSuccess &&
              res[1].result > 0 && // ?????????????? 0
              res[2].isSuccess &&
              res[2].result > 0 // ?????????????? 0
            ) {
              setCurrent(5);
              setPageCurrent(5);
              webSiteInfoService.modifySiteGuideStatus(true);
            } else {
              setCurrent(3);
              setPageCurrent(3);
              getEdgeNodeList();
              getOriginNodeList();
            }
          } else {
            setCurrent(2);
            setPageCurrent(2);
          }
        });
      });
    },
    [areaId, current, pageCurrent, getEdgeNodeList, getOriginNodeList]
  );
  /* 安装节点 */
  const nodeInstall = useCallback(
    (data: any) => {
      if (Array.isArray(needInstallNode) && needInstallNode.length > 0) {
        // 遍历需安装节点数组,安装节点
        let countCounter: number = 0
        needInstallNode.forEach(async (item: any) => {
          let config = nodeAPI.InstallNode({}, item);
          try {
            await reqService(config);
          } catch (e) {
            countCounter++;
            openNotificationWithIcon(
              "error",
              "NODE_INSTALLATION_FAILURE"
            );
          }
        });
        if (countCounter === 0) {
          openNotificationWithIcon(
            "info",
            "THE_NODE_IS_BEING_INSTALLED"
          );
        }
        // 清空安装节点数据
        // setNeedInstallNode([]);
        childRef?.current?.clearData();
      }
    },
    [needInstallNode, openNotificationWithIcon]
  );
  const getInstallNode = useCallback((data: SetStateAction<never[]>) => {
    setNeedInstallNode(data);
  }, []);
  // 内存泄漏问题待处理，导致内存泄漏的原因是fokejoin里面卸载组件的时候设置了usestate
  useEffect(() => {
    // let isUnmount = false;
    setNodeType("edge");
    messageService.onceUpadte(formId, { nodeType: "edge" });
    messageService.onceUpadte(originFormId, { nodeType: "origin" });
    /* 
    进入页面先判断是否存在边缘节点，如果存在则setCurrent(1)
    再判断源节点是否存在，如果存在则setCurrent(2)
    */
    const edgeConfig = nodeAPI.NodeTypeCounts({ type: "edge" }, {});
    const originConfig = nodeAPI.NodeTypeCounts({ type: "origin" }, {});
    const areaConfig = areaAPI.FindArearelationNode({}, {});
    const originInstallConfig = nodeAPI.FindInstallNodeCounts(
      {},
      { typeList: ["origin"], statusList: [1] }
    );
    const edgeInstallConfig = nodeAPI.FindInstallNodeCounts(
      {},
      { typeList: ["edge"], statusList: [1] }
    );
    const concatedData$ = forkJoin([
      reqServicePlx<any>(edgeConfig),
      reqServicePlx<any>(originConfig),
      reqServicePlx<any>(areaConfig),
      reqServicePlx<any>(originInstallConfig),
      reqServicePlx<any>(edgeInstallConfig),
    ]);
    // const sub =
    concatedData$.subscribe((res) => {
      if (
        res[0].isSuccess &&
        res[1].isSuccess &&
        res[2].isSuccess &&
        res[3].isSuccess &&
        res[4].isSuccess
        // !isUnmount
      ) {
        if (
          res[0].result > 0 &&
          res[1].result > 0 &&
          res[2].result &&
          res[3].result > 0 && // ?????????????? 0
          res[4].result > 0 // ?????????????? 0
        ) {
          webSiteInfoService.modifySiteGuideStatus(true);
        } else {
          webSiteInfoService.modifySiteGuideStatus(false);
          // webSiteInfoService.modifySiteGuideStatus(true);
        }
        if (res[0].result > 0) {
          setCurrent(1);
          setPageCurrent(1);
          setHasEdgeNode(true);
        } else {
          setHasEdgeNode(false);
          return;
        }
        // 是否创建源节点
        if (res[1].result > 0) {
          setCurrent(2);
          setPageCurrent(2);
          setHasOriginNode(true);
        } else {
          setHasOriginNode(false);
          return;
        }
        // 是否关联节点
        if (res[2].result) {
          setCurrent(3);
          setPageCurrent(3);
          getEdgeNodeList();
          getOriginNodeList();
        } else {
          return;
        }
        // 是否安装节点
        if (res[3].result > 0 && res[4].result > 0) {
          // ?????????????? 0
          setCurrent(5);
          setPageCurrent(5);
        }
      }
    });
    // return () => sub.unsubscribe();
  }, [formId, originFormId, getEdgeNodeList, getOriginNodeList]);
  useEffect(() => {
    if (!areaId) {
      const config = areaAPI.GetDefaultArea({}, {});
      from(reqService(config)).subscribe((res: any) => {
        const config = areaAPI.AreaFindNodeList({ id: res.id }, {});
        from(reqServicePlx(config)).subscribe((res2) => {
          if (res2.isSuccess && res2.result) {
            const data = Object.assign({}, res2.result, {
              areaId: res.id,
            });
            messageService.formUpdate(associatedId, data);
          } else {
            messageService.formUpdate(associatedId, { areaId: res.id });
          }
        });
        setAreaId(res.id);
      });
    }
  }, [areaId, associatedId]);
  const _getLevel = useCallback(() => {
    // getEdgeNodeList();
    // getOriginNodeList();
    
    const originInstallConfig = nodeAPI.FindInstallNodeCounts(
      {},
      { typeList: ["origin"], statusList: [1] }
    );
    const edgeInstallConfig = nodeAPI.FindInstallNodeCounts(
      {},
      { typeList: ["edge"], statusList: [1] }
    );
    const getEdgeNodeListConfig = nodeAPI.FindNodeTypeList(
      {},
      { typeList: ["edge"], statusList: [0, 1, 2, 3] }
    );
    const getOriginNodeListConfig = nodeAPI.FindNodeTypeList(
      {},
      { typeList: ["origin"], statusList: [0, 1, 2, 3] }
    );

    forkJoin([
      reqServicePlx<any>(originInstallConfig),
      reqServicePlx<any>(edgeInstallConfig),
      reqServicePlx<any>(getEdgeNodeListConfig),
      reqServicePlx<any>(getOriginNodeListConfig),
    ]).subscribe((res) => {
      if (res[2].isSuccess && res[2].result) {
        setEdgeNodeList(res[2].result);
        const edgeList = res[2].result;
        edgeList.forEach((item: any) => {
          if (item.status === 3) {
            setNeedInstallNode((arr: any) => {
              let arrNeedInstallNode = [...arr];
              const needInstall = arrNeedInstallNode.filter((item2: any) => item.id === item2.id)
              return needInstall as any
            })
          }
        })
      }
      if (res[3].isSuccess && res[3].result) {
        setOriginNodeList(res[3].result);
        const originList = res[3].result;
        originList.forEach((item: any) => {
          if (item.status === 3) {
            setNeedInstallNode((arr: any) => {
              let arrNeedInstallNode = [...arr];
              const needInstall = arrNeedInstallNode.filter((item2: any) => item.id === item2.id)
              return needInstall as any
            })
          }
        })
      }
      if (
        res[0].isSuccess &&
        res[0].result > 0 && // ?????????????? 0
        res[1].isSuccess &&
        res[1].result > 0 // ?????????????? 0
      ) {
        setCurrent(5);
        webSiteInfoService.modifySiteGuideStatus(true);
        clearInterval(time.current);
      }
    });
  }, []);
  useEffect(() => {
    time.current = setInterval(_getLevel, 10000);
    return () => {
      clearInterval(time.current);
    };
  }, [_getLevel]);

  const changeLanguage = useCallback(
    (data: any) => {
      if (!userInfo) {
        return;
      }
      const language = {
        locale: data
      }
      const _data: IAccountInfo & { password: any } = { ...userInfo, ...language };
      delete _data.password;
      const config: AxiosRequestConfig = userAPI.Update({}, _data);
      reqAndRunCallback(config, () => {
        accountService.RefreshLanguage();
      });
    },
    [userInfo]
  );

  return (
    <section
      style={{
        padding: "30px 40px",
        backgroundColor: "#fff",
        marginBottom: 10,
      }}
      className="stream-page-row stream-page"
    >
      <Row >
        <Col span={20}></Col>
        <Col span={4}>
          <Col span={5}>
            <SelectP style={{ width: 150, borderRadius: 8 }} defaultValue={userInfo?.locale} enableintl={false} data={langList} onChange={changeLanguage} />
          </Col>
        </Col>
      </Row>
      <p style={{ fontSize: 12 }}>
        <FormattedMessage id="DEAR_USERS_CREATE_NODE" />
      </p>
      {/* 步骤条 */}
      <Steps className="stepsBox" current={current} labelPlacement="vertical">
        {steps.map((item) => (
          <Steps.Step key={item.description} title={item.title} />
        ))}
      </Steps>
      <Divider />
      {/* 创建边缘节点 */}
      {pageCurrent === 0 && (
        <FormS
          title="CREATE_NODE"
          id={formId}
          submitEvent={createNode}
          forceShowSubmit
          footerBtnConfig={{
            closeBtn: false,
            okText: "NEXT_STEP",
          }}
        >
          <CreateNode isRequired={!hasEdgeNode} nodeType={"edge"} />
        </FormS>
      )}
      {/* 创建源节点 */}
      {pageCurrent === 1 && (
        <FormS
          title="CREATE_NODE"
          id={originFormId}
          submitEvent={createNode}
          forceShowSubmit
          footerBtnConfig={{
            closeBtn: false,
            okText: "NEXT_STEP",
          }}
        >
          <CreateNode isRequired={!hasOriginNode} nodeType={"origin"} />
        </FormS>
      )}
      {/* 数据中心关联节点 */}
      {pageCurrent === 2 && (
        <FormS
          id={associatedId}
          title="NODE_MANAGEMENT"
          forceShowSubmit
          footerBtnConfig={{
            closeBtn: false,
            okText: "NEXT_STEP",
          }}
          submitEvent={NodeManagementCb}
        >
          <AssociatedNode />
        </FormS>
      )}
      {/* 安装节点 */}
      {pageCurrent === 3 && (
        <InstallNode
          cRef={childRef}
          callback={getInstallNode}
          edgeNodeList={edgeNodeList}
          originNodeList={originNodeList}
          installFunc={nodeInstall}
        />
      )}
    </section>
  );
};

export default AdminGuide;
