import FormItem from "@/common/form/FormItem/item";
import { NodeStatusEnum } from "@/common/utils/columns";
import { getStatusProps } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
  Tag
} from "antd";
import {
  FC,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { FormattedMessage } from "react-intl";
interface IProps {
  callback?: any;
  originNodeList: IDataItem[];
  edgeNodeList: IDataItem[];
  cRef: any;
  installFunc?:any
}
export interface IDataItem {
  nodeIp: string;
  sshPort: string;
  sshName: string;
  sshPassword: string;
  status: number;
  id: number;
}

const NodeInstall: FC<IProps> = ({
  callback,
  edgeNodeList,
  originNodeList,
  cRef,
  installFunc
}) => {
  const [needNodeList, setNeedNodeList] = useState<IDataItem[]>([]);
  // 父组件调用子组件方法
  useImperativeHandle(cRef, () => ({
    clearData: () => {
      setNeedNodeList((e) => []);
    },
  }));
  useEffect(() => {
    if (Array.isArray(needNodeList) && needNodeList.length > 0) {
      callback?.(needNodeList);
    }
  }, [needNodeList, callback]);
  const callBack = useCallback((e: any) => {
    if (typeof e === "number") {
      // 传参为id时，取消勾选，过滤id所在对象
      setNeedNodeList((arr: any) => {
        const data = [...arr];
        const _data = data.filter((item: { id: number }) => item.id !== e);
        return _data;
      });
    }
    if (typeof e === "object") {
      //传参为对象时，勾选，数据存储
      setNeedNodeList((arr: any) => {
        const data = [...arr, e];
        return data;
      });
    }
  }, []);
  return (
    <section>
      <Row style={{ marginBottom: 20 }}>
        <Col span={5}></Col>
        <Col style={{ color: "#999" }}>
          <span>*&nbsp;</span>
          <FormattedMessage id="CURRENTLY_SUPPORTED" />
        </Col>
      </Row>
      {/* 边缘节点 */}
      <Row>
        <Col span={4}>
          <IntlDep id="EDGE_NODE_IP" />
        </Col>
        <Col span={4}>
          <IntlDep id="SSH_PORT" />
        </Col>
        <Col span={4}>
          <IntlDep id="SSH_USER" />
        </Col>
        <Col span={4}>
          <IntlDep id="SSH_PASSWORD" />
        </Col>
        <Col span={4}>
          <IntlDep id="WHETHER_TO_INSTALL" />
        </Col>
        <Col span={4}>
          <IntlDep id="STATUS" />
        </Col>
      </Row>
      <Divider style={{ margin: 10 }} />
      {/* 边缘节点数据表单 */}
      {edgeNodeList.map((item: any) => {
        return (
          <NodeDetailRow data={item} key={item.nodeIp} callBack={callBack} />
        );
      })}
      <Divider style={{ margin: 10 }} />
      {/* 源节点 */}
      <Row>
        <Col span={4}>
          <IntlDep id="SOURCE_NODE_IP" />
        </Col>
        <Col span={4}>
          <IntlDep id="SSH_PORT" />
        </Col>
        <Col span={4}>
          <IntlDep id="SSH_USER" />
        </Col>
        <Col span={4}>
          <IntlDep id="SSH_PASSWORD" />
        </Col>
        <Col span={4}>
          <IntlDep id="WHETHER_TO_INSTALL" />
        </Col>
        <Col span={4}>
          <IntlDep id="STATUS" />
        </Col>
      </Row>
      <Divider style={{ margin: 10 }} />
      {/* 源节点数据表单 */}
      {originNodeList.map((item: any) => {
        return (
          <NodeDetailRow data={item} key={item.nodeIp} callBack={callBack} />
        );
      })}

      <Space style={{ marginTop: 20 }}>
        <Button type="primary" onClick={installFunc}>
          <FormattedMessage id={"APPLY"} />
        </Button>
      </Space>
    </section>
  );
};
export default NodeInstall;

interface INodeDetailRowProps {
  data: IDataItem;
  callBack: any;
}
// 节点表格数据组件
const NodeDetailRow: FC<INodeDetailRowProps> = ({ data, callBack }) => {
  const [checked, setChecked] = useState(false);
  const IndText = useIntlDep();
  const [form] = Form.useForm();
  const onChange = useCallback(
    (e: { target: { checked: any } }) => {
      const datas = form.getFieldsValue();
      if (e.target.checked) {
        // 选中时
        if (datas.sshName && datas.sshPassword && datas.sshPort) {
          // ssh数据全部填写
          callBack?.(datas);
          setChecked(true);
        } else {
          //ssh数据有未填写
          notification.warning({
            message: `${IndText({
              id: "ENTER_THE_SSH_PORT_AND_USER_AND_PASSWORD",
            })}`,
            duration: 1,
          });
          setChecked(false);
        }
      } else {
        // 取消勾选
        callBack?.(datas.id);
        setChecked(false);
      }
    },
    [form, IndText, callBack]
  );
  return (
    <>
      <Form form={form}>
        <Row gutter={[0, 20]}>
          {/* 节点IP */}
          <Col span={4}>{data?.nodeIp}</Col>
          {/* 节点IP和节点id  仅使用不显示 */}
          <Col span={0} hidden>
            <FormItem name="id" initialValue={data?.id}>
              <Input />
            </FormItem>
          </Col>
          <Col span={0} hidden>
            <FormItem name="nodeIp" initialValue={data?.nodeIp}>
              <Input />
            </FormItem>
          </Col>
          {/* SSH端口 */}
          <Col span={4}>
            <FormItem name="sshPort" initialValue={22}>
              <Input style={{ width: 200 }} disabled={checked} />
            </FormItem>
          </Col>
          {/* SSH用户 */}
          <Col span={4}>
            <FormItem name="sshName">
              <Input style={{ width: 200 }} disabled={checked} />
            </FormItem>
          </Col>
          {/* SSH密码 */}
          <Col span={4}>
            <FormItem name="sshPassword">
              <Input style={{ width: 200 }} disabled={checked} />
            </FormItem>
          </Col>
          {/* 是否安装 */}
          <Col span={4}>
            {data.status === 1 || data.status === 2 ? (
              <Checkbox defaultChecked disabled />
            ) : (
              <Checkbox checked={checked} onChange={onChange} />
            )}
          </Col>
          {/* 状态 */}
          <Col span={4}>
            <StatusNode status={data.status} />
          </Col>
        </Row>
        <Divider style={{ margin: 10 }} />
      </Form>
    </>
  );
};

interface IStatus {
  status: any;
}
// 节点安装状态组件
const StatusNode: FC<IStatus> = ({ status }) => {
  const item = getStatusProps(status, NodeStatusEnum);
  return (
    <Tag color={item?.color}>
      <FormattedMessage id={item?.name} />
    </Tag>
  );
};
