import { getEventId } from "@/hooks/useEventId";
import useSubscribe from "@/hooks/useSubscribe";
import messageService from "@/store/messages";
import EInfoType from "@/store/messages/infoType";
import FormItem from "antd/lib/form/FormItem";
import React, { FC, useCallback, useState } from "react";
import UploadFileComponent from "../form/uploadFile/uploadVideo";
import FormP from "../FormP";
import { IUploadModuleConfig } from "./interface";

const defaultConfig = {
    text: "IMPORT",
    suffix: [],
    submitEvent: console.log,
    name: "file"
}


const uploadId = getEventId()

const UploadModule: FC = () => {
    const [config, setConfig] = useState<IUploadModuleConfig>(defaultConfig);

    const loadConfirm = useCallback((newConfig: IUploadModuleConfig) => {
        setConfig(newConfig);
        setTimeout(() => {
            messageService.createEvent(uploadId)
        }, 50)
    }, []);

    useSubscribe(EInfoType.import_file, loadConfirm);

    return (
        <FormP disableTitleFix submitEvent={config.submitEvent} id={uploadId} title={config.text} titleValue={config.textValue} submitText="TO_UPLOAD" >
            <FormItem name={config.name}>
                <UploadFileComponent multiple={config.multiple} accept={config.accept} />
            </FormItem>
        </FormP>
    );
};

export default UploadModule;

export const closeUploadModule = () => {
    messageService.createEvent(uploadId)
}
