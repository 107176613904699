import IconFont from "@/common/icon";
// import { ICategoryConfig } from "@/common/interface";
// import { filterCategoryConfigByAuth } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import useSubmitEvent from "@/hooks/useSubmitEvent";
import useUserDetail from "@/hooks/useUserDetail";
// import { IPermissionTreeNodes } from "@/store/account/interface";
import accountService from "@/store/account/service";
import messageService from "@/store/messages";
import toolService from "@/store/request/tool";
import { Button, Col, Row } from "antd";
import { useCallback } from "react";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
// import useMenu from "../../sider/menus";
// import menu from "./menu";

const CategoryMenu: FC = () => {
  // const menu = useMenu();
  const IndeText = useIntlDep();
  // const [menuConfig, setMenuConfig] = useState<IPermissionTreeNodes[]>([]);
  // useEffect(() => {
  //   setMenuConfig(filterCategoryConfigByAuth(menu));
  //   // console.log(filterCategoryConfigByAuth(menu));

  // }, [menu]);
  const loginOut = useCallback(() => {
    messageService.confirm(
      IndeText({ id: "CONFIRM_LOGOUT_INFO" }),
      () => {
        accountService.logout();
      },
      "CONFIRM_LOGOUT"
    );
  }, [IndeText]);
  const submitLoginOut = useSubmitEvent(loginOut);
  const userinfo = useUserDetail();
  // const config: ICategoryConfig[] =
  return (
    <section className="stream-category-menu  ant-dropdown-menu">
      <div className="menu-loginout-row">
        <Row align="middle" wrap={false}>
          <Col flex={1}>
            <span style={{ fontWeight: 700 }}>support</span>
            <br />
            <span style={{ color: "#999", fontSize: 12 }}>
              {<IntlDep id="YOUR_LAST_LOGIN_TIME_IS" />}
              {userinfo?.lastLoginDate}
            </span>
          </Col>
          <Col>
            <Button onClick={submitLoginOut} type="primary" shape={"round"}>
              <IntlDep id="LOG_OUT" />
            </Button>
          </Col>
        </Row>
      </div>
      <Row wrap>
        {/* {menuConfig.map((item: IPermissionTreeNodes) => ( */}
        <Col span={8}>
          <div
            onClick={() => {
              toolService.goPage(`/personal`);
            }}
            className="category-dom"
          >
            <span>
              <IconFont type={"icongerenzhongxinwode"} />
            </span>
            <span>
              <FormattedMessage id={"PERSONAL_CENTER"} />
            </span>
          </div>
        </Col>
        {/* ))} */}
      </Row>
    </section>
  );
};

export default CategoryMenu;
