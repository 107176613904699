// import { AsyncSelect } from "@/common/fastRender/select";
import CheckButton from "@/common/form/checkButton";
import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import { reqAndRunCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useCustomerList from "@/hooks/useCustomerList";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail, { useIsAdmin } from "@/hooks/useUserDetail";
// import userAPI from "@/store/apis/account/user";
import certAPI from "@/store/apis/node/cert";
import messageService from "@/store/messages";
import { Input, notification } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
interface IProps {
  id: number;
}
const FormDistributionDomain: FC<IProps> = ({ id }) => {
  const [vodDomain, setVodDoamin] = useState<string | number>();
  const customerList = useCustomerList();
  const [userId, setUserId] = useState<any>();
  const isAdmin = useIsAdmin();
  const userInfo = useUserDetail();
  const loadText = useIntlDep();
  const setVodDoaminCb = useCallback(
    (data:any) => {
      // 输入状态下关闭ssl
      messageService.onceUpadte(id, { openSsl: 0 });
      setVodDoamin(data);
    },
    [setVodDoamin, id]
  );
  useEffect(() => {
    if (userInfo) {
      setUserId(userInfo.id)
    }
  }, [userInfo]);
  //域名和证书的对应关系
  const changeSSlorDomain = useCallback(() => {
    if (vodDomain && userId) {
      let _vodDomain: any = vodDomain;
      const config = certAPI.findCertByDomain(
        {},
        { userId, domain: _vodDomain }
      );
      reqAndRunCallback(config, (res) => {
        if (!res) {
          notification.warning({
            message: loadText({
              id: "SSL_FAILS_TO_BE_ENABLED_BECAUSE_THE_CURRENT_DOMAIN_NAME_DOES_NOT_MATCH",
            }),
            duration: 2.5,
          });
          messageService.onceUpadte(id, { openSsl: 0 });
        }
      });
    } else {
      messageService.onceUpadte(id, { openSsl: 0 });
    }
  }, [vodDomain, id, loadText, userId]);

  return (
    <section>
      {isAdmin && customerList && (
        <FormItem label="SELECT_THE_CUSTOMER" name="userId">
          <SelectP onChange={setUserId} data={customerList} />
        </FormItem>
      )}
      <div>
        <FormItem
          label="DOMAIN"
          name="domain"
          rules={[
            {
              required: true,
              message: (
                <IntlDep
                  id="A_B"
                  values={{ name1: "BASIN", name2: "IS_MANDATORY" }}
                />
              ),
            },
            () => ({
              validator(_, value) {
                const reg = /^[a-z0-9A-Z._-]+$/g;
                if (value && !reg.test(value)) {
                  return Promise.reject(new Error(loadText({ id: "DOMAIN_NAME_LIMIT" })));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input onBlur={({ target: { value } }) => setVodDoaminCb(value)} />
          {/* <SelectInput list={vodDomainList} /> */}
        </FormItem>
        <FormItem
          inline
          initialValue={0}
          label="A_B"
          labelValues={{
            name1: "ENABLE",
            name2: "SSL",
          }}
          name="openSsl"
        >
          <CheckButton onChange={changeSSlorDomain} true={1} false={0} />
        </FormItem>
      </div>
    </section>
  );
};

export default FormDistributionDomain;
