import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import { ICallback } from "@/common/interface";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import { Col, InputNumber, Row } from "antd";
import { FC, useCallback, useState } from "react";
import { SelectWidth } from "../selectData";

interface IProps {
  value?: any;
  onChange?: ICallback<any>;
  isRequired?:boolean;
}

const SelectSize: FC<IProps> = ({ value, onChange,isRequired }) => {
  const [wideType, setWideType] = useState(value);
  const IndText = useIntlDep();

  const selectType = useCallback((data: any) => {
    setWideType(data);
  }, []);

  return (
    <>
      {/* 播放器宽度 */}

      <FormItem
        name="wideType"
        label="PLAYER_WIDTH"
        rules={[{ required: isRequired, message: "Please select gender!" }]}
        initialValue={2}
      >
        <SelectP
          value={value}
          data={SelectWidth}
          onChange={selectType}
          enableintl
        />
      </FormItem>
      {/* 
        选择固定宽度(1)显示固定宽度文本框,只需要填宽度,高度根据宽高比:(宽屏)的比值换算
        选择自定义宽度(2),宽高手动输入
        宽度值在(320-4096),高度值在(240-2160)
       */}
      {wideType === 1 ? (
        <>
          <Row>
            <Col span={1} style={{ paddingTop: 5, paddingLeft: 5 }}>
              <IntlDep id="WIDTH" />:
            </Col>
            <Col span={21}>
              <FormItem
                name="wideValue"
                rules={[
                  {
                    required: isRequired,
                    message: `${IndText({ id: "ENTER_WIDTH_PLAYER" })}`,
                  },
                  () => ({
                    validator(_, value) {
                      if (value < 320 || value > 4096) {
                        return Promise.reject(
                          new Error(IndText({ id: "WIDTH_VALUES_IN_320_4096" }))
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={`${IndText({ id: "WIDTH_VALUES_IN_320_4096" })}`}
                  min={320}
                  max={4096}
                />
              </FormItem>
            </Col>
            <Col span={1}>
              <span>&nbsp;px</span>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={2} style={{ paddingTop: 5, paddingLeft: 5 }}>
                  <IntlDep id="WIDTH" />:
                </Col>
                <Col span={21}>
                  <FormItem
                    name="wideValue"
                    rules={[
                      {
                        required: isRequired,
                        message: `${IndText({ id: "ENTER_WIDTH_PLAYER" })}`,
                      },
                      () => ({
                        validator(_, value) {
                          if (value < 320 || value > 4096) {
                            return Promise.reject(
                              new Error(
                                IndText({ id: "WIDTH_VALUES_IN_320_4096" })
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder={`${IndText({
                        id: "WIDTH_VALUES_IN_320_4096",
                      })}`}
                      min={320}
                      max={4096}
                    />
                  </FormItem>
                </Col>
                <Col span={1}>
                  <span>&nbsp;px</span>
                </Col>
              </Row>
            </Col>
            <Col span={1}></Col>
            <Col span={11}>
              <Row>
                <Col span={2} style={{ paddingTop: 5, paddingLeft: 5 }}>
                  <IntlDep id="HEIGHT" />:
                </Col>
                <Col span={21}>
                  <FormItem
                    name="heightValue"
                    rules={[
                      {
                        required: isRequired,
                        message: `${IndText({ id: "ENTER_HEIGHT_PLAYER" })}`,
                      },
                      () => ({
                        validator(_, value) {
                          if (value < 240 || value > 2160) {
                            return Promise.reject(
                              new Error(
                                IndText({ id: "HEIGHT_VALUE_IN_240_2160" })
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder={`${IndText({
                        id: "HEIGHT_VALUE_IN_240_2160",
                      })}`}
                      min={240}
                      max={2160}
                    />
                  </FormItem>
                </Col>
                <Col span={1}>
                  <span>&nbsp;px</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SelectSize;
