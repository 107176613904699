import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import { ICallback } from "@/common/interface";
import { reqAndRunCallback } from "@/common/utils/utils";
// import IntlDep from "@/hooks/intl/IntlDep";
import nodeAPI from "@/store/apis/node/node";
import { Col, Row, Tag } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
interface IProps {
  value?: any;
  onChange?: ICallback<number[]>;
  type: "edge" | "origin" | "middle";
}
const NodeForm: FC<IProps> = ({ value, onChange, type }) => {
  const [dataList, setDataList] = useState<any[]>([]);
  const [submitData, setsubmitData] = useState<any[]>();
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [selectValue, setSelectValue] = useState("");

  useEffect(() => {
    if (Array.isArray(value) && value.length && typeof value[0] !== "number") {
      const newValue = value.map((t) => {
        return {
          nodeIp: t.value,
          id: t.key,
        };
      });
      setDisplayData(newValue);
      setsubmitData(newValue.map((t) => t.id));
    }
  }, [value]);
  useEffect(() => {
    if (type) {
      const config = nodeAPI.NodeTypeList({ type }, {});
      reqAndRunCallback(config, (res) => {
        if (Array.isArray(res)) {
          setDataList(res);
        }
      });
    }
  }, [type]);
  useEffect(() => {
    if (submitData) {
      onChange?.(submitData);
    }
  }, [submitData, onChange]);

  const changeYRecope = useCallback(
    (key: ConcatArray<never>) => {
      if (dataList && !displayData.find((d) => d.key === key)) {
        const newData = dataList.filter((t) => t.id === key);
        // const _newData = newData.filter((t) => t);
        setSelectValue("");
        setDataList((list) => list.filter((t) => t.id !== key));
        setDisplayData((d: any[]) => d.concat(newData));
        setsubmitData((d: any) =>
          d
            ? Array.from(new Set(d.concat(key)))
            : Array.from(new Set([].concat(key)))
        );
      }
    },
    [dataList, displayData]
  );

  const removeFilter = useCallback(
    (key: string) => {
      if (submitData && displayData) {
        const _newData = submitData.filter((item) => item !== key);
        const _displayData = displayData.filter((item) => item.id !== key);
        if (key === selectValue) {
          setSelectValue("");
        }
        setDisplayData(_displayData);
        setsubmitData(_newData);
        setDataList((list) =>
          list.concat(displayData.filter((item) => item.id === key))
        );
      }
    },
    [submitData, selectValue, displayData]
  );
  return (
    <section>
      <FormItem noStyle>
        <Row align="middle" wrap={false} gutter={10}>
          <Col span={12}>
            <SelectP
              // style={{ width: 350 }}
              value={selectValue}
              config={{
                idKey: "id",
                textKey: "nodeIp",
              }}
              onChange={changeYRecope}
              data={dataList}
            />
          </Col>
          {Array.isArray(displayData) && displayData.length ? (
            <>
              {/* <Col span={2}>
                <FormattedMessage id="SELECTED" />:
              </Col> */}
              <Col span={12}>
                <Row wrap gutter={[5, 5]} style={{ marginTop: 5 }}>
                  {Array.isArray(displayData) &&
                    displayData.map((filter: any, idx) => (
                      <Col key={idx}>
                        <Tag
                          key={filter.id}
                          closable
                          color="default"
                          onClose={() => removeFilter(filter.id)}
                        >
                          {filter.nodeIp}
                        </Tag>
                      </Col>
                    ))}
                </Row>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      </FormItem>
    </section>
  );
};

export default NodeForm;
