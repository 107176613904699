import { ISelectItem } from './interface'

// 时区选择键值对
export let timezoneList: ISelectItem[] = [
  { name: 'TIMEZONE_UTC_P_ZERO', id: '0' },
  { name: 'TIMEZONE_UTC_P_ONE', id: '1' },
  { name: 'TIMEZONE_UTC_P_TWO', id: '2' },
  { name: 'TIMEZONE_UTC_P_THREE', id: '3' },
  { name: 'TIMEZONE_UTC_P_FOUR', id: '4' },
  { name: 'TIMEZONE_UTC_P_FIVE', id: '5' },
  { name: 'TIMEZONE_UTC_P_SIX', id: '6' },
  { name: 'TIMEZONE_UTC_P_SEVEN', id: '7' },
  { name: 'TIMEZONE_UTC_P_EIGHT', id: '8' },
  { name: 'TIMEZONE_UTC_P_NINE', id: '9' },
  { name: 'TIMEZONE_UTC_P_TEN', id: '10' },
  { name: 'TIMEZONE_UTC_P_ELEVEN', id: '11' },
  { name: 'TIMEZONE_UTC_P_TWELVE', id: '12' },
  { name: 'TIMEZONE_UTC_N_ONE', id: '-1' },
  { name: 'TIMEZONE_UTC_N_TWO', id: '-2' },
  { name: 'TIMEZONE_UTC_N_THREE', id: '-3' },
  { name: 'TIMEZONE_UTC_N_FOUR', id: '-4' },
  { name: 'TIMEZONE_UTC_N_FIVE', id: '-5' },
  { name: 'TIMEZONE_UTC_N_SIX', id: '-6' },
  { name: 'TIMEZONE_UTC_N_SEVEN', id: '-7' },
  { name: 'TIMEZONE_UTC_N_EIGHT', id: '-8' },
  { name: 'TIMEZONE_UTC_N_NINE', id: '-9' },
  { name: 'TIMEZONE_UTC_N_TEN', id: '-10' },
  { name: 'TIMEZONE_UTC_N_ELEVEN', id: '-11' },
  { name: 'TIMEZONE_UTC_N_TWELVE', id: '-12' },
]

// 语言键值对
export let langList: ISelectItem[] = [
  { name: '中文简体', id: 'zh_CN' },
  { name: '中文繁体', id: 'zh_TW' },
  { name: 'English', id: 'en_US' },
]
