import { useEffect, useState } from "react";
import LiveService from ".";

/**
 * 当前的选中的客户
 */
const useCustomerLiveId = () => {
  const [id, setId] = useState<number | null>();
  useEffect(() => {
    LiveService.liveCustomerId$.subscribe((t) => {
      setId(t);
    });
  }, []);
  return id;
};

export default useCustomerLiveId;
