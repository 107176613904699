import { propertyMerge, propertyReplace } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useFormP from "@/hooks/useFormP";
import useResource from "@/hooks/useResource";
import useResourceData from "@/hooks/useResourceData";
import useVisible from "@/hooks/useVisible";
import messageService from "@/store/messages";
import EInfoType from "@/store/messages/infoType";
import { Button, Form, Input, Space } from "antd";
import React, { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import useIntlDep from "../../hooks/useIntlDep";
import { ICallback, IFormProps, ITrigger } from "../interface";
import ModalP from "../modal";

interface IProps {
  /** 禁用修正弹窗标题(去掉新增，编辑字样) */
  disableTitleFix?: boolean;
  /** 提交文字 */
  submitText?: string;
  /** 表单内容修改通知事件 */
  onFieldsChange?: ICallback<any>;
  /** 展示在标题中的字段值  */
  titleContentKey?: string;
  /**关闭弹窗的回调事件 */
  closeCb?: ITrigger;
  width?: number | string;
  children: React.ReactNode;
}

/**
 *  弹窗表单
 */
const FormP: FC<IFormProps & IProps> = (props) => {
  const [isEdit, setEdit] = useState(false);
  const [visible, changeVisible] = useVisible();
  useResource(props.id, changeVisible);
  const onFieldsChangeProps = props.onFieldsChange;
  // 合成提交事件
  const submitMemo = useMemo(() => {
    return (data: any, oldData?: any) =>
      props.submitEvent(
        propertyMerge(
          propertyReplace(data, props.propertyReplaceMap || {}, true),
          props.propertyMerge || {},
          true
        ),
        oldData
      );
  }, [props]);

  const [formData, submit] = useFormP(submitMemo);

  const open = useMemo(() => {
    return () => messageService.createEvent(props.id);
  }, [props]);

  const create = useCallback(() => {
    formData.resetFields();
    setEdit(false);
    open();
  }, [formData, open]);
  useResourceData(EInfoType.form_new, props.id, create);

  // 存放当前的编辑内容
  const [currentData, setCurrentData] = useState<any>(null);
  const {
    titleContentKey,
    title: titleProps,
    titleValue: titleValueProps,
    propertyMerge: propertyMergeProps,
    propertyReplaceMap: propertyReplaceMapProps,
  } = props;

  const load = useCallback(
    (data: any) => {
      setEdit(true);
      // 先合并属性
      let _data = propertyMerge(data, propertyMergeProps || {});
      // 再替换属性
      _data = propertyReplace(_data, propertyReplaceMapProps || {});
      formData.setFieldsValue({
        ..._data,
        mode: "edit",
        originData: JSON.stringify(_data),
      });
      open();
      if (onFieldsChangeProps) {
        onFieldsChangeProps(_data);
      }
      setCurrentData(_data);
    },
    [
      formData,
      open,
      propertyMergeProps,
      propertyReplaceMapProps,
      onFieldsChangeProps,
    ]
  );

  useResourceData(EInfoType.form_load, props.id, load);
  //载入单条数据
  useResourceData(EInfoType.once_update, props.id, (data) =>
    formData.setFieldsValue(data)
  );
  const loadText = useIntlDep();
  const titleValue = useMemo(() => {
    if (titleContentKey && currentData) {
      return currentData[titleContentKey];
    }
    return "";
  }, [titleContentKey, currentData]);

  const propsTitle = useMemo(() => {
    const title = loadText({ id: titleProps, values: titleValueProps });
    if (!titleValue) {
      return title;
    }
    return loadText({
      id: "A_B",
      values: {
        _name1: title,
        _name2: `(<${titleValue}>)`,
      },
    });
  }, [titleValue, titleProps, titleValueProps, loadText]);

  const title = props.disableTitleFix ? (
    propsTitle
  ) : (
    <IntlDep
      id={isEdit ? "EDIT_TITLE" : "NEW_TITILE"}
      values={{
        _title: propsTitle,
      }}
    />
  );

  // 附件按钮
  const submitBtns = useMemo(() => {
    if (props.submitBtns) {
      return props.submitBtns.map((btn) => ({
        ...btn,
        event() {
          const newValue = formData.getFieldsValue();
          delete newValue.originData;
          delete newValue.mode;
          btn.event(newValue);
        },
      }));
    }
    return [];
  }, [formData, props.submitBtns]);

  return (
    <ModalP
      title={title}
      width={props.width}
      visible={visible}
      showFotterBtn={false}
      onCancel={changeVisible}
      onSubmit={submit}
      submitBtns={submitBtns}
      afterClose={() => {
        formData.resetFields();
        setCurrentData(null);
        props.closeCb && props.closeCb();
      }}
    >
      {/* <ModalID
      id={props.id}
      width={props.width}
      title={title}
      submit={submit}
      okText={props.submitText}
      submitBtns={submitBtns}
      showFotterBtn={false}
      afterClose={() => {
        formData.resetFields();
        setCurrentData(null);
        props.closeCb && props.closeCb();
      }}
    > */}
      <Form
        form={formData}
        initialValues={props.initialValues}
        layout={props.layout || "vertical"}
        autoComplete="off"
        onFinish={submit}
        validateMessages={{
          // eslint-disable-next-line no-template-curly-in-string
          required: loadText({ id: "FORM_REQUIRED" }),
          types: {
            email: loadText({ id: "EMAIL_NOT_VALID" }),
            url: loadText({ id: "URL_NOT_VALID" }),
          },
        }}
        onFieldsChange={(e: any[]) => {
          if (onFieldsChangeProps) {
            if (e.length > 0) {
              const obj = e[0];
              if (obj.name.length > 0) {
                const changeField = {
                  [obj.name.toString()]: obj.value,
                };
                /** 通知外层 */
                onFieldsChangeProps(changeField);
              }
            }
          }
        }}
      >
        {props.children}
        <Form.Item hidden name="mode">
          <Input />
        </Form.Item>
        <Form.Item hidden name="originData">
          <Input />
        </Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id={"APPLY"} />
          </Button>

          <Button type="default" onClick={changeVisible}>
            <FormattedMessage id={"CANCEL"} />
          </Button>
        </Space>
      </Form>
    </ModalP>
  );
};

export default FormP;
