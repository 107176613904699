import AuthRouter from "@/common/helpers/authRouter";
import LoadingPlx from "@/common/LoadingPlx/LoadingPlx";
import useLogin from "@/hooks/useLogin";
import useUserDetail from "@/hooks/useUserDetail";
import { ELogin, IUserType } from "@/store/account/interface";
import webSiteInfoService from "@/store/webSiteInfo/service";

import { FC, Suspense, useEffect, useState } from "react";
import AllRouterP from "./menus";
import GuideRouterP from "./menus/guide";
import LoginRouterP from "./menus/login";

/**
 * 项目路由组件
 * 可以在此根据用户相应的权限组装路由
 * @constructor
 */
const ProjectRouter: FC = () => {
  const loginStatus = useLogin();
  const userInfo = useUserDetail();
  const [showGuide, setShowGuide] = useState<boolean>(false);
  // const isAdmin = useIsAdmin();
  useEffect(() => {
    webSiteInfoService.showSiteGuide$.subscribe((status) => {
      setShowGuide(status);
    });
  }, []);
  if (loginStatus === ELogin.LOGINOUT) {
    return <LoginRouterP />;
  }

  if (!userInfo) {
    return null;
  }

  if (userInfo.roleCodes.includes(IUserType.ADMIN) && !showGuide) {
    return (
      <Suspense fallback={<LoadingPlx />}>
        <AuthRouter>
          <GuideRouterP />
        </AuthRouter>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<LoadingPlx />}>
      <AuthRouter>
        <AllRouterP />
      </AuthRouter>
    </Suspense>
  );
};

export default ProjectRouter;
