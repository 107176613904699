import IconTip from "@/common/icon/tip";
import IntlDep from "@/hooks/intl/IntlDep";
// import { useLicense } from "@/hooks/useLicense";
import useSubmitEvent from "@/hooks/useSubmitEvent";
import toolService from "@/store/request/tool";
import useWebSiteInfo from "@/store/webSiteInfo/useWebSiteInfo";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Col, Dropdown, Menu, Row } from "antd";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
// import { FormattedMessage } from "react-intl";
import CategoryList from "./CategoryList";
import logo from "./img/logo.svg";
import "./index.less";

const HeaderPlx: FC = () => {
  const webSiteInfo = useWebSiteInfo();
  const [logoadd, setLogoAdd] = useState<string>(logo);
  const [hideAuthorization, setHideAuthorization] = useState<boolean>(true);
  const show = { opacity: 1, height: "80px", transition: ".5s all ease-in" };
  const hide = { opacity: 0, height: "0px", transition: ".5s all ease-in" };
  // const isExpire = useLicense();
  const isExpire = false;
  useEffect(() => {
    if (webSiteInfo) {
      setLogoAdd(webSiteInfo.logoAddress);
    }
  }, [webSiteInfo]);
  const goMain = useCallback(() => {
    toolService.goPage("/live-management");
  }, []);
  const click = useSubmitEvent(goMain);
  const handleIcon = useCallback(() => {
    setHideAuthorization(true);
  }, []);
  useEffect(() => {
    if (isExpire) {
      setHideAuthorization(false);
    } else {
      setHideAuthorization(true);
    }
  }, [isExpire]);
  return (
    <Fragment>
      <Row
        className="license-header-box"
        style={hideAuthorization ? hide : show}
      >
        <Col span={4}></Col>
        <Col span={18} className="license-header">
          {/* <Tooltip title={<FormattedMessage />}> */}
          <span>
            <IconTip style={{ color: "#f0ad4e" }} />
            <span style={{ marginLeft: "10px", color: "#f0ad4e" }}>
              <IntlDep id={"LICENSE_EXPIRATION_TIME"} />
            </span>
          </span>
          {/* </Tooltip> */}
          <div className="license-title">
            <IntlDep id={"CONTACT_CUSTOMER_SERVICE"} />
          </div>
        </Col>
        <Col span={2} className="fold-arrow">
          <UpOutlined onClick={handleIcon} />
        </Col>
      </Row>
      <nav className="comp-header">
        <Row wrap={false}>
          <Col flex={1}>
            <div>
              <img
                onClick={click}
                style={{ cursor: "pointer", height: 42 }}
                src={logoadd}
                alt="logo"
              />
            </div>
          </Col>
          <Col span={6}>
            <Row wrap={false} gutter={15}>
              <Col>
                <CategoryList />
              </Col>
              <Col>
                <div className="dierge-xiala-header" style={{ marginLeft: 20 }}>
                  <span>
                    <Dropdown
                      arrow
                      overlayStyle={{
                        borderRadius: 5,
                      }}
                      placement="bottom"
                      overlay={
                        <Menu
                          className="docu-dropdown-item ant-dropdown-menu"
                          items={[
                            {
                              key: "api",
                              label: (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://doc.stream-svc.com/api"
                                >
                                  <IntlDep id="API_DOCUMENT" />
                                </a>
                              ),
                            },
                            {
                              key: "console",
                              label: (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://doc.stream-svc.com/console"
                                >
                                  <IntlDep id="CONSOLE_OPERATION_DOCUMENT" />
                                </a>
                              ),
                            },
                          ]}
                        />
                      }
                    >
                      {/* <Space style={{ color: "#1890FF", fontSize: 14 }}> */}
                      <span
                        style={{
                          color: "#1890FF",
                          display: "inline-block",
                          height: 48,
                        }}
                      >
                        <IntlDep id="DOCUMENT" />
                        <span style={{ marginLeft: 10, fontSize: 16 }}>
                          <DownOutlined />
                        </span>
                      </span>
                    </Dropdown>
                    {/* <Tooltip placement="top" title="icon2">
                  <IconFont
                    style={{ fontSize: 18 }}
                    type="icona-Iconmaterial-help"
                  />
                </Tooltip> */}
                  </span>
                </div>
              </Col>
            </Row>
            {/* <Space wrap={false}>
              
             
            </Space> */}
          </Col>
        </Row>
      </nav>
    </Fragment>
  );
};

export default HeaderPlx;
