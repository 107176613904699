import useUserDetail from "@/hooks/useUserDetail";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Button, Row, Col } from "antd";
import { FC } from "react";
import CategoryMenu from "./categoryMenu";
import "./index.less";
const CategoryList: FC = () => {
  const userInfo = useUserDetail();
  return (
    <section>
      <Dropdown overlay={<CategoryMenu />} placement="bottomRight" arrow>
        <Button shape={"round"} style={{ minWidth: 200, textAlign: "start" }}>
          <Row justify="space-between">
            <Col>
              {userInfo
                ? userInfo.username
                  ? userInfo.username
                  : userInfo.name
                : "-"}
            </Col>
            <Col>
              <DownOutlined />
            </Col>
          </Row>
        </Button>
      </Dropdown>
      
    </section>
  );
};

export default CategoryList;
