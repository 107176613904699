import ConditionShow from "@/common/conditionShow";
import useUserDetail from "@/hooks/useUserDetail";
import { IUserType } from "@/store/account/interface";
// import areaAPI from "@/store/apis/node/area";
import domainAPI from "@/store/apis/node/domain";
import liveAPI from "@/store/apis/node/live";
// import nodeAPI from "@/store/apis/node/node";
import reqServicePlx from "@/store/request/reqServicePlx";
// import webSiteInfoService from "@/store/webSiteInfo/service";
import { FC, useEffect, useMemo, useState } from "react";
import { forkJoin } from "rxjs";
import AdminGuide from "./adminGuide";
import CustomerGUide from "./customerGUide";

const Guide: FC = () => {
  // const isAdmin = useIsAdmin();
  //是否有节点    如果有则不显示引导栏    如果没有则显示
  // const [isNode, setisNode] = useState<boolean>(false);
  const userIndo = useUserDetail();
  //是否有直播或者点播    如果有则不显示引导栏    如果没有则显示
  const [isLiveOrDomain, setIsLiveOrDomain] = useState<boolean>(false);
  const userInfo = useUserDetail();

  const isAdmin = useMemo(() => {
    if (userIndo) {
      return (
        userIndo?.roleCodes.includes(IUserType.ADMIN) ||
        userIndo?.roleCodes.includes(IUserType.SUPERADMIN)
      );
    }
    return false;
  }, [userIndo]);

  useEffect(() => {
    /* 
    当直播和点播都不存在时，显示
    直播： get   /live/find_user_live_counts   userId   返回直播数量
    点播： get   /domain/find_domain_vod_counts   userId  返回点播分发域名数量
    */
    if (!isAdmin && userInfo) {
      const liveConfig = liveAPI.FindUserLiveCounts(
        { userId: userInfo.id },
        {}
      );
      const domainConfig = domainAPI.FindDomainVodCounts(
        { userId: userInfo.id },
        {}
      );
      forkJoin([
        reqServicePlx<any>(liveConfig),
        reqServicePlx<any>(domainConfig),
      ]).subscribe((res) => {
        if (
          (res[0].isSuccess && res[0].result > 0) ||
          (res[1].isSuccess && res[1].result > 0)
        ) {
          setIsLiveOrDomain(true);
        }
      });
    }
  }, [isAdmin, userInfo]);
  
  return (
    <section>
      <ConditionShow  removeMode visible={isAdmin}>
        
        <AdminGuide />
      </ConditionShow>
      <ConditionShow removeMode visible={!isAdmin && !isLiveOrDomain}>
        
        <CustomerGUide />
      </ConditionShow>
    </section>
  );
};
export default Guide;
