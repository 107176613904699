import resourceService from "@/resource";
import accountService from "@/store/account/service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { from } from "rxjs";

const STREAM_LAN_TYPE_LUNAGE = "STREAM_LAN_TYPE_LUNAGE";
export let languagePkg: any = null;
const useLanguage = () => {
  const [lanSys, setLanSys] = useState(accountService.lanInfo$.value);
  useEffect(() => {
    accountService.lanInfo$.subscribe((t) => setLanSys(t));
  }, []);
  const lan: string = useMemo(() => {
    if (lanSys) {
      resourceService.setItem(STREAM_LAN_TYPE_LUNAGE, lanSys);
      return lanSys;
    }
    return resourceService.getItem(STREAM_LAN_TYPE_LUNAGE) || "en_US";
  }, [lanSys]);

  const [pkg, setPkg] = useState<any>(null);

  const requirePkg = useCallback((lan: string) => {
    const lanKey = `${STREAM_LAN_TYPE_LUNAGE}-${lan}-PKG`;
    from(loadLanPkg(lan)).subscribe((values: any) => {
      let pkgMap: any = {};
      values.map((value: any) => {
        if (value && value.default) {
          const pkg = value.default;
          pkgMap = { ...pkgMap, ...pkg };
        }
        return true;
      });
      setPkg(pkgMap);
      resourceService.setItem(lanKey, pkgMap);
    });
  }, []);

  useEffect(() => {
    const lanKey = `${STREAM_LAN_TYPE_LUNAGE}-${lan}-PKG`;
    const _pkg = resourceService.getItem(lanKey);
    // 载入
    if (_pkg) {
      setPkg(_pkg);
    } else {
      // 获取语言包
      requirePkg(lan);
    }
  }, [lan, requirePkg]);

  useEffect(() => {
    languagePkg = pkg;
  }, [pkg]);

  return [pkg];
};

export default useLanguage;

const loadLanPkg = (lan: string) => {
  switch (lan) {
    case "zh_CN":
      return Promise.all([
        import("@/locale/other/zh_CN"),
        import("@/locale/tips/zh_CN"),
        import("@/locale/all/zh_CN"),
        import("@/locale/pkg"),
      ]);
    case "zh_TW":
      return Promise.all([
        import("@/locale/other/zh_TW"),
        import("@/locale/tips/zh_TW"),
        import("@/locale/all/zh_TW"),
        import("@/locale/pkg"),
      ]);
    default:
      return Promise.all([
        import("@/locale/other/en_US"),
        import("@/locale/tips/en_US"),
        import("@/locale/all/en_US"),
        import("@/locale/pkg"),
      ]);
  }
};
// export let languagePkg: any = _languagePkg;
