import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import { ISelectItem } from "@/common/interface";
import { reqAndRunCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail, { useIsAdmin } from "@/hooks/useUserDetail";
import {
  ISliceTimeSource,
  ISliceTimeSourceOnlyPainting
} from "@/pages/guide/adminGuide/form/interface";
import transcodingAPI from "@/store/apis/node/transcoding";
import toolService from "@/store/request/tool";
import { Button, Col, Form, InputNumber, Row } from "antd";
import { AxiosRequestConfig } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import useCustomerLiveId from "../../Service/useCustomerLiveId";
import { aspectRatio } from "../selectData";
import CheckPopconfirm from "./CheckPopconfirm";

interface IProps {
  hidden: boolean;
  form: any;
  isRequired?:boolean;
}

const VideoSet: FC<IProps> = ({ hidden, form,isRequired }) => {
  const [transcoding, setTranscoding] = useState<ISelectItem[]>([]);
  const customerId = useCustomerLiveId();
  const userInfo = useUserDetail();
  const isAdmin = useIsAdmin();
  const [userId, setUserId] = useState<number>();
  const loadText = useIntlDep();
  const [isShowCutSliceTime, setIsShowCutSliceTime] = useState<boolean>();
  const [showSharding, setShowSharding] = useState<boolean>(true);

  const getTranscodingType = useCallback(() => {
    if (userId) {
      const config: AxiosRequestConfig = transcodingAPI.TranscodingLiveList(
        { userId },
        {}
      );
      reqAndRunCallback(config, (res) => {
        if (res) {
          setTranscoding([
            { id: 0, name: `${loadText({ id: "ORIGINAL" })}` },
            ...res,
          ]);
        }
      });
    }
  }, [userId, loadText]);
  /**
   * 是否是管理员账户
   */
  useEffect(() => {
    /* 
      是否是管理员账户 
        ? 是否选择客户customerId 
          ? userId = customerId 
          : return 
        : userId=userInfo?.id */
    if (isAdmin) {
      if (!customerId) {
        return;
      }
      setUserId(customerId);
    } else {
      setUserId(userInfo?.id);
    }
  }, [isAdmin, customerId, userInfo]);

  useEffect(() => {
    getTranscodingType();
  }, [getTranscodingType]);

  useEffect(() => {
    const transcodingId = form.getFieldValue("transcodingId");
    if (transcodingId === 0) {
      setIsShowCutSliceTime(true);
    }
  }, [form]);

  const onChangeTranscodingId = useCallback(
    (e: number) => {
      if (e === 0) {
        setIsShowCutSliceTime(true);
        form.setFieldsValue({ sliceTimeSource: 2 });
        setShowSharding(true);
      } else {
        setIsShowCutSliceTime(false);
        form.setFieldsValue({ sliceTimeSource: 1 });
        setShowSharding(false);
      }
    },
    [form]
  );

  const changeSource = useCallback(
    (e: number) => {
      if (e === 1) {
        setShowSharding(false);
      }
      if (e === 2) {
        setShowSharding(true);
      }
      form.setFieldsValue({ cutSliceTime: null });
    },
    [form]
  );
  useEffect(() => {
    const sliceTimeSource = form.getFieldValue("sliceTimeSource");
    if (sliceTimeSource === 2) {
      setShowSharding(true);
    }
    if (sliceTimeSource === 1) {
      setShowSharding(false);
    }
  }, [form]);

  return (
    <section hidden={hidden}>
      {/* 转码模板 */}
      <Form.Item
        label={
          <>
            <span>
              <IntlDep id="TRANSCODING_TEMPLATE" />
            </span>
            &emsp;&emsp;
            <span>
              (<IntlDep id="CLICK_TO_ENTER" />[
              <Button
                type="link"
                style={{ padding: 0, margin: 0 }}
                onClick={() => {
                  toolService.goPage(`/transcoder`);
                }}
              >
                <IntlDep id="TRANSCODER_SETTINGS" />
              </Button>
              ],
              <IntlDep id="CREATES_OR_MODIFIES_A_TRANSCODER" />)
            </span>
          </>
        }
        name="transcodingId"
        rules={[{ required: isRequired }]}
        initialValue={0}
      >
        <SelectP data={transcoding} onChange={onChangeTranscodingId} />
      </Form.Item>
      <Row>
        <Col span={11}>
          {/* 分片时长来源 */}
          <FormItem
            name="sliceTimeSource"
            label="A_B"
            labelValues={{ name1: "FRAGMENTATION_TIME", name2: "SECONDS" }}
            initialValue={2}
            key="ISliceTimeSource"
          >
            <SelectP
              enableintl
              data={
                !!isShowCutSliceTime
                  ? ISliceTimeSourceOnlyPainting
                  : ISliceTimeSource
              }
              onChange={changeSource}
            />
          </FormItem>
        </Col>
        <Col span={2}></Col>
        <Col span={11}>
          {/* 分片时长(秒) */}
          {showSharding && (
            <FormItem
              name="cutSliceTime"
              style={{ marginTop: 29 }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("sliceTimeSource") === 2 && !value) {
                      return Promise.reject(
                        new Error(
                          `${loadText({
                            id: "FRAGMENT_DURATION_IS_MANDATORY",
                          })}`
                        )
                      );
                    }
                    if (value < 1) {
                      return Promise.reject(
                        new Error(
                          `${loadText({
                            id: "MINIMUM_FRAGMENT_DURATION_IS_1",
                          })}`
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              initialValue={3}
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                placeholder={loadText({
                  id: "A_B",
                  values: { name1: "FRAGMENTATION_TIME", name2: "SECONDS" },
                })}
              />
            </FormItem>
          )}
        </Col>
      </Row>

      {/* 是否创建延迟减少的HLS流 */}
      {/* <FormItem
        label="WHETHER_TO_CREATE_HLS"
        initialValue={1}
        name="hlsSwitch"
        inline
      >
        <CheckButton true={1} false={0} />
      // </FormItem> */}
      {/* 宽高比:(宽屏) */}
      <FormItem
        label="ASPECT_RATIO"
        name="aspectRatio"
        rules={[{ required: isRequired }]}
        initialValue={"16X9"}
      >
        <SelectP data={aspectRatio} />
      </FormItem>
      {/* 直播录制 */}
      <FormItem
        label="LIVE_RECORDING"
        initialValue={0}
        name="recordSwitch"
        inline
        tiptype="tip"
        tipText="DEMAND_DISTRIBUTION_DOMAIN"
      >
        <CheckPopconfirm />
      </FormItem>
    </section>
  );
};
export default VideoSet;
