import React, { lazy } from "react";
import LayoutPlx from "@/common/layout";
import lazyLoad from "@/common/helpers/lazyLoad";
import { RouteObject } from "react-router-dom";
// 普通路由匹配
const publicRouter: Array<RouteObject> = [
  {
    element: <LayoutPlx />,
    children: [
      {
        path: "customer",
        element: lazyLoad(lazy(() => import("@/pages/Account/customer"))),
      },

      {
        path: "transcoder",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/transcodingSet/transcoder"))
        ),
      },
      // {
      //   path: "real-time-monitoring",
      //   element: lazyLoad(
      //     lazy(() => import("@/pages/main/pages/realTimeMonitoring"))
      //   ),
      // },
      {
        path: "transcoding-group",
        element: lazyLoad(
          lazy(
            () => import("@/pages/main/pages/transcodingSet/TranscodingGroup")
          )
        ),
      },

      {
        path: "personal",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/personalCenter"))
        ),
      },

      {
        path: "certificate-management",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/certManagement"))
        ),
      },

      {
        path: "area-management",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/areaManagement"))
        ),
      },

      {
        path: "node-group",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/Record/nodeGroupManagement"))
        ),
      },
      {
        path: "config-template",
        element: lazyLoad(lazy(() => import("@/pages/main/pages/configTmpl"))),
      },

      {
        path: "statistics-all",
        element: lazyLoad(lazy(() => import("@/pages/main/pages/dataChart"))),
      },

      {
        path: "record-management",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/recordManagement"))
        ),
      },

      {
        path: "node-administration",
        element: lazyLoad(lazy(() => import("@/pages/nodeManagement"))),
      },

      {
        path: "idc-management",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/IDCManagement"))
        ),
      },

      {
        path: "role-management",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/roleManagement"))
        ),
      },

      {
        path: "authority-management",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/authorityManagement"))
        ),
      },

      {
        path: "system-settings-config",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/systemSettingsConfig"))
        ),
      },

      {
        path: "storage-management",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/storageManagement"))
        ),
      },

      // {
      //   path: "rtc-sagestatistics",
      //   element: lazyLoad(
      //     lazy(() => import("@/pages/main/pages/rtcUsageStatistics"))
      //   ),
      // },
      {
        path: "module-management",
        element: lazyLoad(
          lazy(() => import("@/pages/main/pages/moduleManagement"))
        ),
      },
    ],
  },
];

export default publicRouter;
