// import { AsyncSelect } from "@/common/fastRender/select";
import CheckButton from "@/common/form/checkButton";
import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import { reqAndRunCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useCustomerList from "@/hooks/useCustomerList";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail, { useIsAdmin } from "@/hooks/useUserDetail";
// import userAPI from "@/store/apis/account/user";
import certAPI from "@/store/apis/node/cert";
import { Input, notification } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import LiveService from "../../Service";
import { LiveType } from "../selectData";
interface IProps {
  hidden: boolean;
  mode?: number;
  form: any;
  isRequired?: boolean;
}

const AddLive: FC<IProps> = ({ hidden, mode, form, isRequired }) => {
  const userInfo = useUserDetail();
  const [userId, setUserId] = useState<number>();
  const [pushDomian, setPushDomian] = useState<string>("");
  const [pullDomian, setPullDomian] = useState<string>("");
  const customerList = useCustomerList();
  const loadText = useIntlDep();
  useEffect(() => {
    if (userInfo) {
      setUserId(userInfo.id);
    }
  }, [userId, userInfo]);
  const IndText = useIntlDep();
  const isAdmin = useIsAdmin();

  const setVodDoaminPush = useCallback(
    (data: any) => {
      // 输入状态下关闭ssl
      form.setFieldsValue({ pushDomainOpenSsl: 0 });
      setPushDomian(data);
    },
    [setPushDomian, form]
  );
  const setVodDoaminPull = useCallback(
    (data: any) => {
      // 输入状态下关闭ssl
      form.setFieldsValue({ pullDomainOpenSsl: 0 });
      setPullDomian(data);
    },
    [setPullDomian, form]
  );

  const changeUserId = useCallback((e: number) => {
    LiveService.setLiveCustomerId(e);
    setUserId(e);
  }, []);

  /* 推流域名开启SSL */
  // const checkPushSsl = useCallback(
  //   (e) => {
  //     if (pushDomian && userId) {
  //       let _pushDomian: string = pushDomian;
  //       const config = certAPI.findCertByDomain(
  //         {},
  //         { userId, domain: _pushDomian }
  //       );
  //       reqAndRunCallback(config, (res) => {
  //         console.log(res);
  //         if (!res) {
  //           notification.warning({
  //             message: loadText({
  //               id: "SSL_FAILS_TO_BE_ENABLED_BECAUSE_THE_CURRENT_DOMAIN_NAME_DOES_NOT_MATCH",
  //             }),
  //             duration: 2.5,
  //           });
  //           form.setFieldsValue({ pushDomainOpenSsl: 0 });
  //         }
  //       });
  //     } else {
  //       form.setFieldsValue({ pushDomainOpenSsl: 0 });
  //     }
  //   },
  //   [pushDomian, userId, loadText, form]
  // );
  const checkPullSsl = useCallback(() => {
    if (pullDomian && userId) {
      let _pushDomian: string = pullDomian;

      const config = certAPI.findCertByDomain(
        {},
        { userId, domain: _pushDomian }
      );

      reqAndRunCallback(config, (res) => {
        if (!res) {
          notification.warning({
            message: loadText({
              id: "SSL_FAILS_TO_BE_ENABLED_BECAUSE_THE_CURRENT_DOMAIN_NAME_DOES_NOT_MATCH",
            }),
            duration: 2.5,
          });
          form.setFieldsValue({ pullDomainOpenSsl: 0 });
        }
      });
    } else {
      form.setFieldsValue({ pullDomainOpenSsl: 0 });
    }
  }, [pullDomian, userId, loadText, form]);
  return (
    <section hidden={hidden}>
      {/* 选择客户 */}
      {isAdmin && customerList && (
        <FormItem label="SELECT_THE_CUSTOMER" name="userId">
          <SelectP onChange={changeUserId} data={customerList} />
        </FormItem>
      )}
      {/* 服务名称 */}
      <FormItem
        label="SERVICE_NAME"
        name="appName"
        tiptype="tip"
        tipText="ONLY_LETTERS_AND_DIGITS"
        rules={[
          {
            required: isRequired,
            message: (
              <IntlDep
                id="A_B"
                values={{ name1: "SERVICE_NAME", name2: "IS_MANDATORY" }}
              />
            ),
          },
          () => ({
            validator(_, value) {
              const reg = /^[A-Za-z0-9]+$/g;
              if (value && !reg.test(value)) {
                return Promise.reject(
                  new Error(IndText({ id: "ONLY_LETTERS_AND_DIGITS" }))
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input readOnly={mode === 1} />
      </FormItem>
      {/* 推流方式 */}
      <FormItem label="PUSH_FLOW_MODE" name="liveType" initialValue={0}>
        <SelectP data={LiveType} />
      </FormItem>
      {/* 推流域名 */}
      <FormItem
        name="pushStreamingDomain"
        label="A_B"
        labelValues={{
          name1: "PUSH",
          name2: "BASIN",
        }}
        tiptype="tip"
        tipText="DOMAIN_NAME_LIMIT"
        rules={[
          {
            required: isRequired,
            message: (
              <IntlDep
                id="A_B_C"
                values={{
                  name1: "PUSH",
                  name2: "BASIN",
                  name3: "IS_MANDATORY",
                }}
              />
            ),
          },
          () => ({
            validator(_, value) {
              const reg = /^[a-z0-9A-Z._-]+$/g;
              if (value && !reg.test(value)) {
                return Promise.reject(
                  new Error(IndText({ id: "DOMAIN_NAME_LIMIT" }))
                );
              } else if (
                pushDomian !== "" &&
                pullDomian !== "" &&
                pushDomian === pullDomian
              ) {
                return Promise.reject(
                  new Error(IndText({ id: "DOMAIN_NOT_REPEATABLE" }))
                );
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input onChange={({ target: { value } }) => setVodDoaminPush(value)} />
      </FormItem>
      {/* 推流域名SSL开关 */}
      {/* 推流暂不支持SSL，等之后能支持再打开 */}
      {/* <FormItem
        name="pushDomainOpenSsl"
        label="PUSH_SSL_SWITCH"
        initialValue={0}
        inline
        tiptype="tip"
        tipText="MATCH_CERTIFICATE_MANAGEMENT"
      >
        <CheckButton true={1} false={0} onChange={checkPushSsl} />
      </FormItem> */}
      {/* 播流域名 */}
      <FormItem
        label="A_B"
        labelValues={{
          name1: "SOWING",
          name2: "BASIN",
        }}
        name="pullStreamingDomain"
        tiptype="tip"
        tipText="DOMAIN_NAME_LIMIT"
        rules={[
          {
            required: isRequired,
            message: (
              <IntlDep
                id="A_B_C"
                values={{
                  name1: "SOWING",
                  name2: "BASIN",
                  name3: "IS_MANDATORY",
                }}
              />
            ),
          },
          () => ({
            validator(_, value) {
              const reg = /^[a-z0-9A-Z._-]+$/g;
              if (value && !reg.test(value)) {
                return Promise.reject(
                  new Error(IndText({ id: "DOMAIN_NAME_LIMIT" }))
                );
              } else if (
                pushDomian !== "" &&
                pullDomian !== "" &&
                pushDomian === pullDomian
              ) {
                return Promise.reject(
                  new Error(IndText({ id: "DOMAIN_NOT_REPEATABLE" }))
                );
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input onChange={({ target: { value } }) => setVodDoaminPull(value)} />
      </FormItem>
      <FormItem
        name="pullDomainOpenSsl"
        label="LIVE_SSL_SWITCH"
        initialValue={0}
        inline
        tiptype="tip"
        tipText="MATCH_CERTIFICATE_MANAGEMENT"
      >
        <CheckButton true={1} false={0} onChange={checkPullSsl} />
      </FormItem>
    </section>
  );
};
export default AddLive;
