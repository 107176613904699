import { useEffect } from "react"
import messageService from "@/store/messages";
import EInfoType from "@/store/messages/infoType";
import { ICallback } from "@/common/interface";
import tool from "@/store/request/tool";

const log = tool.createDebug(false, "自动全局订阅hook");

const useSubscribe = <T>(actionType: EInfoType, load: ICallback<T>) => {
    useEffect(() => {
        const sub = messageService.createSubscibe(actionType).subscribe(action => {
            const _value = action.value as T;
            log("自动订阅触发", actionType, "事件")
            log("自动订阅数据", _value)
            load(_value);
        });
        return () => sub.unsubscribe();
    }, [load, actionType])
}

export default useSubscribe;
