// import { languagePkg } from "@/locale";
import liveService from "@/pages/main/pages/LiveStreaming/Service";
import userAPI from "@/store/apis/account/user";
// import { notification } from "antd";
import md5 from "md5";
import {
  BehaviorSubject,
  debounceTime,
  delay,
  from,
  of,
  Subject,
  switchMap,
} from "rxjs";
import reqService from "../request";
import reqServicePlx from "../request/reqServicePlx";
import { ELogin, IAccountInfo, ITokenStr } from "./interface";

const STREAM_TOKEN_KEY = md5(window.location.host);
/**
 * 登录账户
 */
class Account {
  /**用户当前信息 */
  readonly info$ = new BehaviorSubject<IAccountInfo | null>(null);
  public lanInfo$ = new BehaviorSubject<string | null>(null);
  /**登录状态 */
  readonly login$: BehaviorSubject<ELogin>;
  /** token */
  readonly token$: BehaviorSubject<ITokenStr>;
  /** logout service */
  readonly logout$ = new Subject();

  constructor() {
    const token = localStorage.getItem(STREAM_TOKEN_KEY) || "";
    this.login$ = new BehaviorSubject<ELogin>(
      !!token ? ELogin.UNSET : ELogin.LOGINOUT
    );
    this.token$ = new BehaviorSubject<ITokenStr>("");

    this.token$.subscribe((token) => {
      if (token) {
        setTimeout(() => {
          this.initBasic(token);
        }, 100);
      } else {
        localStorage.removeItem(STREAM_TOKEN_KEY);
      }
    });
    // 自动载入缓存中的有效token
    if (token) {
      this.token$.next(token);
    }

    this.logout$
      .pipe(debounceTime(50))
      .pipe(
        switchMap(() => {
          return of(this.login$.value !== ELogin.LOGINOUT);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.resetLoginState();
        }
      });
  }
  /**
   * 初始化登录信息
   * @step1 验证token有效
   * @step2 token存储
   */
  private initBasic = (token: string) => {
    const config = userAPI.userDetail({}, {});
    config?.headers && (config.headers["Authorization"] = token);
    // console.log(config);

    // const old_token = localStorage.getItem(STREAM_TOKEN_KEY);
    from(reqServicePlx<any>(config, false)).subscribe((res) => {
      if (res.isSuccess) {
        const info = res.result;
        if (info) {
          this.info$.next(info);
          this.lanInfo$.next(info.locale);
          this.login$.next(ELogin.LOGINSU);
          // console.log(window.location);
        }
        // token验证有效
        localStorage.setItem(STREAM_TOKEN_KEY, token);
      } else {
        // if (token !== old_token) {
        //   notification.error({
        //     message:
        //       languagePkg &&
        //       languagePkg["LOGIN_HAS_EXPIRED_PLEASE_LOG_OUT_AND_LOG_IN_AGAIN"],
        //   });
        // const url = window.location.host;
        // window.location.replace(url);
        // return;
        // } else {
        this.logout();
        // }
      }
    });
  };

  /**
   * 刷新语言
   */
  readonly RefreshLanguage = () => {
    const config = userAPI.userDetail({}, {});
    from(reqService<IAccountInfo>(config)).subscribe((info) => {
      if (info) {
        this.lanInfo$.next(info.locale);
      } else {
        this.logout();
      }
    });
  };
  /**
   * 登录信息
   */
  readonly queryDetail = () => {
    const config = userAPI.userDetail({}, {});
    from(reqService<IAccountInfo>(config)).subscribe((info) => {
      if (info) {
        this.info$.next(info);
      } else {
        this.logout();
      }
    });
  };
  setToken(token: string) {
    // 登录成功之后将数据设置到token就可以触发登录信息的更新
    this.token$.next(token);
  }
  /**
   * 退出登录
   * 清空并重置
   */
  readonly logout = () => {
    const subs = of("logout")
      .pipe(delay(30))
      .subscribe(() => {
        localStorage.removeItem(STREAM_TOKEN_KEY);
        this.resetLoginState();
        subs.unsubscribe();
      });
  };
  /**
   * token失效，注销登录信息
   */
  readonly removeLoginInfo = () => {
    this.logout$.next(true);
  };

  readonly resetLoginState = () => {
    this.info$.next(null);
    this.token$.next("");
    liveService.data$.next(null);
    setTimeout(() => {
      this.login$.next(ELogin.LOGINOUT);
    }, 500);
  };
}

const accountService = new Account();
export default accountService;
