import { ISearchPage } from "@/common/template/interface";
import { AxiosRequestConfig } from "axios";
import { ITranscodingForm } from "./interface";

/**
 * @class Transcoding
 * @description
 * @return 返回request的config
 */
class TranscodingAPI {
  /**
   * FindPage
   * 生成请求参数
   * 转码器列表
   */
  FindPage = (params: IFindPageParams, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * transcodingCreate
   * 生成请求参数
   * 转码器新增
   */
  Create = (params: ITranscodingCreateParams, data: ITranscodingForm) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * Detail
   * 生成请求参数
   * 转码器详情
   */
  Detail = (params: ItranscodingDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * Update
   * 生成请求参数
   * 转码器修改
   */
  Update = (params: ItranscodingUpdateParams, data: ITranscodingForm) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * FindList
   * 生成请求参数
   * 转码器列表
   */
  FindList = (params: ItranscodingListParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  Delete = (params: ItranscodingDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * TranscodingList
   * 生成请求参数
   * 直播转码器列表
   */
  TranscodingLiveList = (params: {}, data: any) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/live/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  TranscodingVodList = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/vod/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
}
const transcodingAPI = new TranscodingAPI();
export default transcodingAPI;

interface IFindPageParams {}
interface ItranscodingDetailParams {
  id: number;
}
interface ItranscodingUpdateParams {}
interface ITranscodingCreateParams {}
interface ItranscodingListParams {}
interface ItranscodingDeleteParams {
  id: number;
}
