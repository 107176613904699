import React, { FC } from "react";
// import IconFont from "@/common/icon";
import { Link } from "react-router-dom";
// import { IMenuLv1 } from "../interface";
import { Col, Row } from "antd";
import IntlDep from "@/hooks/intl/IntlDep";
import { IMenuLv2 } from "../interface";
const CompMenu: FC<
  IMenuLv2 & { selectd: boolean; className?: string; lv?: number }
> = (props) => {
  if (props.selectd) {
    return (
      <li className={`selected`}>
        <Row align="middle">
          <Col>
            <span style={{ lineHeight: "20px", display: "inline-block" }}>
              <IntlDep id={props.language} />
            </span>
          </Col>
        </Row>
      </li>
    );
  }

  return (
    <Link style={{ color: "unset" }} to={`${props.routeAddress}`}>
      <li>
        <Row align="middle">
          <Col>
            <p>
              <span
                style={{ lineHeight: "20px", display: "inline-block" }}
                className="menu-text"
              >
                <IntlDep id={props.language} />
              </span>
            </p>
          </Col>
        </Row>
      </li>
    </Link>
  );
};

export default CompMenu;
