import { Radio } from "antd";
import { FC } from "react";
import "./positionsRadio.less";
interface IProps {
  onChange?: (e: any) => void;
  value?: number;
}
/**
 * 选择水印在播放器的位置组件
 */
const RadioP: FC<IProps> = ({ onChange, value }) => {
  const Change = (e: any) => {
    onChange?.(e.target.value);
  };
  return (
    <Radio.Group onChange={Change} className="colBox" value={value}>
      <Radio className="posAbs leftTop" value={1}></Radio>
      <Radio className="posAbs rightTop" value={2}></Radio>
      <Radio className="posAbs leftBottom" value={3}></Radio>
      <Radio className="posAbs rightBottom" value={4}></Radio>
    </Radio.Group>
  );
};
export default RadioP;
