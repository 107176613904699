import IntlDep from "@/hooks/intl/IntlDep";
import { Button, Row, Space } from "antd";
import moment from "moment";
import { FC, useCallback } from "react";
import "./error.less";
import ErrorImg from "./errorbg.png";
const location = window.location;
const ErrorPage: FC = () => {
  const RefreshPrevious = useCallback(() => {
    window.history.go(-1);
    setTimeout(() => {
      location.reload();
    }, 300);
  }, []);
  return (
    <section className="stream-error-component">
      <img src={ErrorImg} alt="" />
      <Row>
        <IntlDep id="AN_ERROR_OCCURRED_WHILE_ACCESSING_THE_PAGE" />
        <Button onClick={() => window.location.reload()} type="link">
          <IntlDep id="REFRESH_THE_PAGE" />
        </Button>
        <IntlDep id="OR" />
        <Button onClick={RefreshPrevious} type="link">
          <IntlDep id="PREVIOUS_PAGE" />
        </Button>
      </Row>
      <Row>
        <Space size="large">
          <Button onClick={() => window.location.reload()} type="primary">
            <IntlDep id="REFRESH_THE_PAGE" />
          </Button>
          <Button onClick={RefreshPrevious}>
            <IntlDep id="PREVIOUS_PAGE" />
          </Button>
        </Space>
      </Row>
      <p>
        Copyright &copy; {moment(new Date()).format("YYYY")} Greypanel. All
        Rights Reserved.
      </p>
    </section>
  );
};

export default ErrorPage;
