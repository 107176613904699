import { IFormViewProps } from "@/common/form/interface";
import { IMapT } from "@/common/template/interface";
import { getPropertyP } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useEventId from "@/hooks/useEventId";
import useResourceData from "@/hooks/useResourceData";
import messageService from "@/store/messages";
import EInfoType from "@/store/messages/infoType";
import { Descriptions, Divider } from "antd";
import React, { FC, useCallback, useState } from "react";
import viewDetail from "./item";
import ViewModal from "./modal";

const ViewTemplate: FC<IFormViewProps> = (props) => {
  const cols = props.cols;
  const viewId = useEventId();

  /** 展示的数据 */
  const [formData, setForm] = useState<any>({});
  const [hideMap, setHideMap] = useState<IMapT<boolean>>({});

  const reset = useCallback(() => {
    setForm({});
    setName("");
  }, []);

  const [name, setName] = useState("");

  // 渲染内容
  const listRender: React.ReactNode[] = [];
  const singleRender: React.ReactNode[] = [];
  props.config.map((cof, idx1) => {
    // 有子列表
    if (Array.isArray(cof.child)) {
      if (idx1 > 0) {
        listRender.push(<Divider key={`${idx1}-line`} />);
      }
      listRender.push(
        <Descriptions
          size="middle"
          column={{ xs: 1, xl: props.cols }}
          bordered
          layout={props.layout}
          key={idx1}
          title={<IntlDep id={cof.text} values={cof.textValue} />}
        >
          {cof.child.map((cofChild:any, idx2:any) =>
            viewDetail({
              hideMap,
              key: `${idx1}-${idx2}`,
              ...cofChild,
              value: formData[cofChild.dataItem],
              cols,
            })
          )}
        </Descriptions>
      );
    } else {
      if (cof.dataItem) {
        singleRender.push(
          viewDetail({
            hideMap,
            key: `${idx1}`,
            ...cof,
            value: formData[cof.dataItem],
            cols,
          })
        );
      }
    }
    return true;
  });

  const loadData = useCallback(
    (data: any) => {
      setHideMap(data.hideMap || {});
      setForm(data.value);
      const displayName = getPropertyP(data.value, data.name);
      setName(displayName);
      setTimeout(() => {
        messageService.createEvent(viewId);
      }, 50);
    },
    [viewId]
  );

  // 查看的监听事件
  useResourceData(EInfoType.form_view, props.id, loadData);

  return (
    <ViewModal
      id={viewId}
      title={
        <IntlDep
          id="A_BC"
          values={{
            name1: "VIEW",
            name2: props.title,
            _name3: !!name ? `(${name})` : "",
          }}
        />
      }
      afterClose={reset}
      size={props.layout === "vertical" ? "small" : undefined}
    >
      {listRender.length > 0 && listRender}
      {singleRender.length > 0 && (
        <Descriptions
          size="middle"
          column={{ xs: 1, xl: props.cols }}
          bordered
          layout={props.layout}
        >
          {singleRender}
        </Descriptions>
      )}
    </ViewModal>
  );
};

export default ViewTemplate;
