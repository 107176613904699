import { FC } from 'react'
import AddLive from './form/AddLive'
import HostPage from './form/HostPage'
import PlaySet from './form/PlaySet'
import VideoSet from './form/VideoSet'

interface Iprops {
  current: number
  mode?:number
  form:any
}
const NodeForm: FC<Iprops> = ({ current,mode,form }) => {
  return (
    <section>
      {/* 直播新增 */}
      <AddLive hidden={current !== 0} mode={mode} form={form} isRequired={current===0}/>
      {/* 视频源和转码设置 */}
      <VideoSet hidden={current !== 1} form={form} isRequired={current===1}/>
      {/* 播放设置 */}
      <PlaySet hidden={current !== 2} isRequired={current===2}/>
      {/* 托管页面设置 */}
      <HostPage hidden={current !== 3} />
    </section>
  )
}

export default NodeForm
