import moment from "moment";
import "./index.less";
import FormMain from "./form";
import LoginBgcLeft from "./img/scott-webb-57628-unsplash.jpg";
import "./re";
import { useEffect, useState } from "react";
import versionAPI from "@/store/apis/lookup/version";
import { reqAndRunCallback } from "@/common/utils/utils";
const Login = () => {
  const [version, setVersion] = useState("0");
  useEffect(() => {
    const config = versionAPI.getSystemVersion({}, {});
    reqAndRunCallback(config, (res) => setVersion(res));
  }, [setVersion]);
  return (
    <section className="stream-login-content">
      <img
        src={LoginBgcLeft}
        style={{ width: "100%", height: "100%" }}
        alt="background"
      />
      <div className="stream-login-rignt">
        <FormMain />
        <div className="ft">
          Copyright &copy; {moment(new Date()).format("YYYY")} Greypanel. All
          Rights Reserved. Version {version}
        </div>
      </div>
    </section>
  );
};
export default Login;
