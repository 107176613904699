import CheckButton from "@/common/form/checkButton";
import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import { timezoneList } from "@/common/timezoneList";
import useIntlDep from "@/hooks/useIntlDep";
import { Col, DatePicker, Row } from "antd";
import { FC } from "react";

interface IProps {
  [propName: string]: any;
}
/**
 * 倒计时时钟组件
 */
const CountdownModel: FC<IProps> = ({ value }) => {
  const IndText = useIntlDep();
  return (
    <>
      {/* 选择按钮 */}
      <FormItem name="countdownSwitch" inline>
        <CheckButton true={1} false={0} />
      </FormItem>
      {/* 当按钮关闭时时间和时区不显示 */}
      {value === 1 ? (
        <Row>
          <Col span={6}>
            <FormItem inline name="countdownDate">
              {/* 时间选择 */}
              <DatePicker
                showTime
                placeholder={IndText({ id: "PLEASE_SELECT_TIME" })}
                format="YYYY-MM-DD HH:mm"
              />
            </FormItem>
          </Col>
          <Col span={10}>
            {/* 时区选择 */}
            <FormItem inline name="countdownTimezone">
              <SelectP enableintl data={timezoneList}></SelectP>
            </FormItem>
          </Col>
        </Row>
      ) : null}
    </>
  );
};
export default CountdownModel;
