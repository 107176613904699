import useUserDetail from "@/hooks/useUserDetail";
import { IUserType } from "@/store/account/interface";
import webSiteInfoService from "@/store/webSiteInfo/service";
import { Layout } from "antd";
import { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ContentP from "./content";
import HeaderPlx from "./header";
import "./index.less";
import SideBar from "./sider";
import useMenu from "./sider/menus";
const LayoutPlx: FC = () => {
  const menu = useMenu();
  const [showGuide, setShowGuide] = useState<boolean>(false);
  const userInfo = useUserDetail();
  useEffect(() => {
    webSiteInfoService.showSiteGuide$.subscribe((status) => {
      setShowGuide(status);
    });
  }, []);
  return (
    <Layout>
      <HeaderPlx />
      <Layout className="comp-stream-content">
        {userInfo?.roleCodes.includes(IUserType.ADMIN) && !showGuide ? null : (
          <SideBar list={menu} />
        )}
        <ContentP>
          <Outlet />
        </ContentP>
      </Layout>
    </Layout>
  );
};

export default LayoutPlx;
