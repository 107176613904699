import { IModule } from "@/common/interface";
import ModalP from "@/common/modal";
import useResource from "@/hooks/useResource";
import useVisible from "@/hooks/useVisible";
import React, { FC } from "react";
import { IModalModule } from "../interface";

interface IProps {
  title?: React.ReactNode;
  size?: "small";
  children?: React.ReactNode;
}

const ViewModal: FC<IModule & IModalModule & IProps> = (props) => {
  const [visible, changeVisible] = useVisible();
  const { id, title, afterClose } = props;
  useResource(id, changeVisible);
  return (
    <ModalP
      visible={visible}
      title={title}
      onCancel={changeVisible}
      afterClose={afterClose}
      size={props.size}
    >
      {props.children}
    </ModalP>
  );
};

export default ViewModal;
