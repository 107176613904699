import CheckButton from "@/common/form/checkButton";
import FormItem from "@/common/form/FormItem/item";
import UploadPicOnlyOne from "@/common/form/uploadFile/uploadPicOnlyOne";
import useIntlDep from "@/hooks/useIntlDep";
import { Input } from "antd";
import { FC } from "react";

interface IProps {
  hidden: boolean;
}

const HostPage: FC<IProps> = ({ hidden }) => {
  const IndText = useIntlDep();
  return (
    <section hidden={hidden}>
      {/* Greypanel Media Hub 托管 */}
      <FormItem label="HOSTED_HUB" initialValue={0} name="hostingSwitch" inline>
        <CheckButton true={1} false={0} />
      </FormItem>
      {/* 页面标题 */}
      <FormItem
        label="PAGE_TITLE"
        name="title"
        tiptype="tip"
        tipText="THE_PAGE_TITLE_IS_MANDATORY"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("hostingSwitch") === 1 && !value) {
                return Promise.reject(
                  new Error(IndText({ id: "PAGE_TITLE_IS_MANDATORY" }))
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </FormItem>
      {/* 描述 */}
      <FormItem
        label="DESCRIBE"
        name="description"
        tiptype="tip"
        tipText="DESCRIPTION_IS_MANDATORY"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("hostingSwitch") === 1 && !value) {
                return Promise.reject(
                  new Error(IndText({ id: "PAGE_DESCRIPTION_IS_MANDATORY" }))
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.TextArea />
      </FormItem>
      {/* 托管页面中显示徽标 */}
      <FormItem
        label="HOST_LOGO"
        name="microLabelAddress"
        inline
        tiptype="tip"
        tipText="LOGO_PICTURE_IS_MANDATORY"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("hostingSwitch") === 1 && !value) {
                return Promise.reject(
                  new Error(IndText({ id: "LOGO_IS_MANDATORY" }))
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <UploadPicOnlyOne />
      </FormItem>
      {/* 托管页面中显示背景图 */}
      <FormItem
        label="HOST_PAGE_BACKGROUND_IMAGE"
        name="backgroundAddress"
        inline
        tiptype="tip"
        tipText="BACKGROUND_IMAGE_IS_MANDATORY"
        style={{ height: "100%" }}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("hostingSwitch") === 1 && !value) {
                return Promise.reject(
                  new Error(IndText({ id: "BACKGROUND_IS_MANDATORY" }))
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <UploadPicOnlyOne />
      </FormItem>
    </section>
  );
};
export default HostPage;
