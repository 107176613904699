import useUserDetail, { useIsAdmin } from "@/hooks/useUserDetail";
import Guide from "@/pages/guide/content";
import { Layout } from "antd";
import { FC } from "react";
import "./index.less";
import { IUserType } from "@/store/account/interface";
interface IPropsChildren {
  children: React.ReactNode;
}
const ContentP: FC<IPropsChildren> = (props) => {
  const isAdmin = useIsAdmin();
  const userInfo = useUserDetail();
  return (
    <Layout.Content className="comp-layout-content cdn-scroll">
      {!isAdmin && !userInfo?.roleCodes.includes(IUserType.SALE) && <Guide />}
      {props.children}
    </Layout.Content>
  );
};

export default ContentP;
