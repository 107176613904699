import React, { FC } from "react";
import IconFont from "@/common/icon/index";
import { ITrigger } from "@/common/interface";

interface IProps {
  onClick?: ITrigger;
  style?: React.CSSProperties;
}

const IconTip: FC<IProps> = ({ onClick, style }) => {
  return (
    <IconFont
      onClick={onClick}
      style={style || Istyle}
      className="comp-icon-tip"
      type="icona-Component406"
    />
  );
};

export default IconTip;
const Istyle: React.CSSProperties = {
  color: "#72AFFD",
  fontSize: 18,
  marginLeft: 8
};
