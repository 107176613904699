import { ISearchPage } from "@/common/template/interface";
import { AxiosRequestConfig } from "axios";
import { ITranscodingGroupForm } from "./interface";

class TranscodingGroupAPI {
  /**
   * FindPage
   * 生成请求参数
   * 转码组列表
   */
  FindPage = (params: ItranscodingGroupFindPageParams, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/group/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  FindList = (params: ItranscodingGroupFindPageParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/group/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * Create
   * 生成请求参数
   * 转码组新增
   */
  Create = (
    params: ITranscodingGroupCreateParams,
    data: ITranscodingGroupForm
  ) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/group/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * Detail
   * 生成请求参数
   * 转码组详情
   */
  Detail = (params: ItranscodingGroupDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/group/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * Update
   * 生成请求参数
   * 转码组修改
   */
  Update = (
    params: ItranscodingGroupUpdateParams,
    data: ITranscodingGroupForm
  ) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/group/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * Delete
   * 生成请求参数
   * 转码组删除
   */
  Delete = (params: ItranscodingGroupDeleteParams, data: any) => {
    const config: AxiosRequestConfig = {
      url: "/transcoding/group/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
}
const transcodingGroupAPI = new TranscodingGroupAPI();
export default transcodingGroupAPI;

interface ItranscodingGroupFindPageParams {}
interface ItranscodingGroupDetailParams {
  id: number;
}
interface ItranscodingGroupUpdateParams {}
interface ITranscodingGroupCreateParams {}
interface ItranscodingGroupDeleteParams {
  id: number;
}
