import { AsyncSelect } from "@/common/fastRender/select";
import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import IntlDep from "@/hooks/intl/IntlDep";
import idcAPI from "@/store/apis/node/idc";
import { Col, Input, Row } from "antd";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { INodeTypeSelect } from "./interface";
interface IProps {
  nodeType:string
  isRequired?:boolean;
}
const CreateEdgeNode: FC<IProps> = ({ nodeType,isRequired }) => {
  return (
    <section style={{width:800}}>
      <Row style={{ marginBottom: 20 }}>
        {/* <Col span={1}></Col> */}
        <Col style={{ color: "#999" }}>
          <span>*&nbsp;</span>
          <FormattedMessage id="CURRENTLY_SUPPORTED" />
        </Col>
      </Row>

      <Row gutter={[0, 20]}>
        <Col span={24}>
          {/* 节点IP */}
          <FormItem
            label="NODE_IP"
            name="nodeIp"
            rules={[
              {
                required: isRequired,
                message: (
                  <IntlDep
                    id="A_B"
                    values={{ name1: "NODE_IP", name2: "IS_MANDATORY" }}
                  />
                ),
              },
            ]}
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={24}>
          {/* 节点类型 */}
          <FormItem
            name="nodeType"
            label="NODE_TYPE"
            initialValue={nodeType}
            rules={[
              {
                required: isRequired,
                message: (
                  <IntlDep
                    id="A_B"
                    values={{ name1: "NODE_TYPE", name2: "IS_MANDATORY" }}
                  />
                ),
              },
            ]}
          >
            <SelectP data={INodeTypeSelect} enableintl disabled />
          </FormItem>
        </Col>
        <Col span={24}>
          {/* IDC */}
          <FormItem
            name="idcId"
            label="IDC"
            rules={[
              {
                required: isRequired,
                message: (
                  <IntlDep
                    id="A_B"
                    values={{ name1: "IDC", name2: "IS_MANDATORY" }}
                  />
                ),
              },
            ]}
          >
            <AsyncSelect queryConfig={idcAPI.findAll} />
          </FormItem>
        </Col>
        <Col span={24}>
          <Row wrap={false} gutter={15}>
            <Col span={12}>
              <FormItem label="COUNTRYANDREGION" name="country">
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="PROVINCES" name="province">
                <Input />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {/* 带宽 */}
          <FormItem label="BANDWIDTH" name="bandwidth">
            <Input />
          </FormItem>
        </Col>
        <Col span={24}>
          <Row wrap={false} gutter={15}>
            <Col span={12}>
              {/* CPU */}
              <FormItem label="CPU" name="cpu">
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              {/* 内存 */}
              <FormItem label="MEMORY" name="memory">
                <Input />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row wrap={false} gutter={15}>
            <Col span={12}>
              {/* SRS进程数 */}
              <FormItem
                label="SRS_PROCESS_SEVERAL"
                name="srsProcess"
                tiptype="tip"
                tipText="THIS_PARAMETER_IS_MANDATORY"
                initialValue={1}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              {/* 局域网IP */}
              <FormItem
                label="LAN_IP"
                name="lanIp"
                tiptype="tip"
                tipText="LIVE_BROADCAST_SOURCE_NODE_MANDATORY"
              >
                <Input />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{marginBottom:20}}>
          {/* 备注 */}
          <FormItem label="REMARK" name="remark">
            <Input.TextArea />
          </FormItem>
        </Col>
      </Row>
    </section>
  );
};

export default CreateEdgeNode;
