import React from "react";
import LayoutPlx from "@/common/layout";
import lazyLoad from "@/common/helpers/lazyLoad";
import { Outlet, RouteObject } from "react-router-dom";

// 监控
const monitoringRouter: Array<RouteObject> = [
  {
    element: <LayoutPlx />,
    children: [
      {
        path: "monitoring-management",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: lazyLoad(
              React.lazy(
                () => import("@/pages/main/pages/monitoring/ContentPage")
              )
            ),
          },
          {
            path: "info",
            element: lazyLoad(
              React.lazy(() => import("@/pages/main/pages/monitoring/info"))
            ),
          },
        ],
      },
    ],
  },
];

export default monitoringRouter;
