import React, { FC } from "react";
import IconFont from "@/common/icon";
import { Link } from "react-router-dom";
import { IMenuLv2 } from "./interface";
import { Col, Row } from "antd";
import IntlDep from "@/hooks/intl/IntlDep";
const CompMenu: FC<
  IMenuLv2 & { selectd: boolean; className?: string; lv?: number }
> = (props) => {
  if (props.selectd) {
    return (
      <li className={`selected`}>
        <Row align="middle">
          <Col span={7}>
            {props.icon && (
              <div className="menu-icon-rd">
                <IconFont
                  type={props.icon}
                  style={{ fontSize: 18 }}
                  className="str-icon"
                />
                <div className="stream-side-icon-bg">
                  <span></span>
                </div>
              </div>
            )}
          </Col>
          <Col>
            <IntlDep id={props.language} />
          </Col>
        </Row>
      </li>
    );
  }

  return (
    <Link style={{ color: "unset" }} to={`${props.routeAddress}`}>
      <li>
        <Row align="middle">
          <Col span={7}>
            {props.icon && (
              <div className="menu-icon-rd">
                <div className="stream-side-icon-bg">
                  <span></span>
                </div>
                <IconFont
                  style={{ fontSize: 18 }}
                  type={props.icon}
                  className="str-icon"
                />
              </div>
            )}
          </Col>
          <Col>
            <span className="menu-text">
              <IntlDep id={props.language} />
            </span>
          </Col>
        </Row>
      </li>
    </Link>
  );
};

export default CompMenu;
