import { ICallback } from "@/common/interface";
import { Button, Col, Input, Row, Space } from "antd";
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import IconFont from "@/common/icon";
import { XOR } from "ts-xor";
import "./index.less";
import { IResetModule } from "@/common/template/interface";
import IntlDep from "@/hooks/intl/IntlDep";
import { useTableStyleSize } from "@/styles";

export enum EInputType {
  TEXTAREA = "TEXTAREA",
  INPUT = "INPUT",
}

/**
 *
 * @param props
 */
const CustomSearch: FC<IProps & IResetModule> = (props) => {
  const [keyword, setKeyWord] = useState<string>();
  const { current: forceStyle } = useRef(props.forceStyle);
  const [btnSize = "small"] = useTableStyleSize(forceStyle);
  const {
    rows,
    name,
    placeholder,
    buttonName,
    type = EInputType.INPUT,
    keyName,
    submit: submitProps,
    reset,
  } = props;

  const fmtKey = useMemo(() => {
    return keyName || "keyword";
  }, [keyName]);

  // 提交搜索
  const submit = useCallback(() => {
    submitProps &&
      submitProps({
        [fmtKey]: keyword,
      });
  }, [submitProps, fmtKey, keyword]);

  const changeHandler = useCallback((e: { target: { value: React.SetStateAction<any>; }; }) => {
    setKeyWord(e.target.value);
  }, []);

  useEffect(() => {
    if (reset) {
      reset(() => {
        setKeyWord("");
      });
    }
  }, [reset]);

  return (
    <div className="comp-search" style={{ width: "100%" }}>
      {type === EInputType.INPUT && (
        <Row gutter={[15, 15]}>
          {!props.hiddenSearch && (
            <>
              <Col>
                <div className="keyword">
                  <Input
                    prefix={
                      <IconFont
                        type="iconsearch"
                        style={{
                          color: "rgba(0,0,0,.25)",
                          fontSize: "18px",
                          position: "relative",
                          top: "1px",
                          left: "-2px",
                        }}
                      />
                    }
                    value={keyword}
                    onChange={changeHandler}
                    autoComplete="off"
                    placeholder={placeholder}
                    name={name}
                    style={{ minWidth: 360 }}
                    onPressEnter={submit}
                    allowClear
                  />
                </div>
              </Col>
              <Col>
                <Button type="primary" size={btnSize} onClick={submit}>
                  <IntlDep id={buttonName} />
                </Button>
              </Col>
            </>
          )}
          {props.otherBtns &&
            props.otherBtns.map((btn: ReactNode, idx: React.Key) => {
              return <Col key={idx}>{btn}</Col>;
            })}
        </Row>
      )}
      {type === EInputType.TEXTAREA && (
        <div>
          <Input.TextArea value={keyword} rows={rows || 3} />
          <Space>
            <Button onClick={submit}>
              <IntlDep id={buttonName} />
            </Button>
            {props.children}
          </Space>
        </div>
      )}
    </div>
  );
};

interface IBasicProps {
  placeholder?: string;
  needIntl?: boolean;
  submit: ICallback<any>;
  buttonName: string;
  /** 查询字段 */
  keyName: string;
  /** 其他按钮 */
  otherBtns?: React.ReactNode[];
  /** 隐藏搜索输入部分 */
  hiddenSearch?: boolean;
}

interface IFouStyle {
  forceStyle?: {
    size: "small" | "large";
    fixHeight?: number;
  };
}
interface ITypeInput {
  type?: EInputType.INPUT;
  className?: string;
  name?: string;
}

interface ITypeTextarea {
  type: EInputType.TEXTAREA;
  rows?: number;
  className?: string;
}
interface IchildrenProps {
  children?: ReactNode;
}
type IProps = XOR<ITypeInput, ITypeTextarea> &
  IBasicProps &
  IFouStyle &
  IchildrenProps;

export default CustomSearch;
