import { IAccountInfo, IUserType } from "@/store/account/interface";
import accountService from "@/store/account/service";
import { useEffect, useState } from "react";

/**
 * 用户当前登录状态监控
 */
const useUserDetail = () => {
  const [state, setState] = useState<IAccountInfo | null>(
    accountService.info$.value
  );
  useEffect(() => {
    const sub = accountService.info$.subscribe((value) => {
      setState(value);
    });
    return () => sub.unsubscribe();
  }, []);
  return state;
};

export default useUserDetail;

export const useIsAdmin = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>();
  useEffect(() => {
    const sub = accountService.info$.subscribe((value) => {
      const flag =
        value?.roleCodes.includes(IUserType.ADMIN) ||
        value?.roleCodes.includes(IUserType.SUPERADMIN);
      setIsAdmin(flag);
    });
    return () => sub.unsubscribe();
  }, []);
  return isAdmin;
};
