import IconTip from "@/common/icon/tip";
import { IMap } from "@/common/interface";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import messageService from "@/store/messages";
import { Col, Form, Row, Tooltip } from "antd";
import { FormItemProps } from "antd/lib/form";
import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";

interface IFormItemP extends FormItemProps {
  /** label的values */
  labelValues?: IMap;
  /** 展示成一行 */
  inline?: boolean;
  label?: string | React.ReactNode;
  extEl?: React.ReactNode;
  // 子级左边缩进
  haveLeftPadding?: boolean;
}

interface TipProps {
  tipId?: number;
  tiptype?: "tip" | "default";
  tipText?: string;
}

const FormItem: FC<IFormItemP & TipProps> = (props) => {
  const {
    label,
    labelValues,
    inline = false,
    haveLeftPadding = false,
    tipId,
    tiptype = "default",
    extEl,
    tipText,
    ...rest
  } = props;
  const loadText = useIntlDep();
  const span = useMemo(() => {
    if (extEl) {
      return undefined;
    }
    let _span = 24;
    if (label) {
      _span -= 5;
    }
    return _span;
  }, [label, extEl]);

  if (inline) {
    return (
      <Form.Item noStyle={rest.noStyle}>
        <Row gutter={15} align="middle">
          {!!label && (
            <Col span={5}>
              <div className={haveLeftPadding ? "cdn-padding-left-child" : ""}>
                {typeof label === "string" ? (
                  <IntlDep id={label} values={labelValues} />
                ) : (
                  label
                )}
                {tipId && tiptype === "default" && (
                  <IconTip onClick={() => messageService.sendMessage(tipId)} />
                )}
                {tiptype === "tip" && (
                  <Tooltip title={<FormattedMessage id={props.tipText} />}>
                    <span>
                      <IconTip />
                    </span>
                  </Tooltip>
                )}
              </div>
            </Col>
          )}
          <Col span={span}>
            <div
              className={
                span === 24 && haveLeftPadding ? "cdn-padding-left-child" : ""
              }
            >
              <Form.Item {...rest} noStyle>
                {props.children}
              </Form.Item>
            </div>
          </Col>
          {!!extEl && <Col>{extEl}</Col>}
        </Row>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      label={
        !!label &&
        (tiptype === "tip" ? (
          <>
              {typeof label === "string" ? (
                  <IntlDep id={label} values={labelValues} />
                ) : (
                  label
                )}
            <Tooltip title={<FormattedMessage id={props.tipText} />}>
              <span>
                <IconTip />
              </span>
            </Tooltip>
          </>
        ) : (
          typeof label === "string" ? loadText({ id: label, values: labelValues }):label
        ))
      }
      validateFirst
      {...rest}
    >
      {props.children}
    </Form.Item>
  );
};
export default FormItem;
