import useSubmitEvent from "@/hooks/useSubmitEvent";
import { Button, Col, Row } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import "./footer.less";
import { ITrigger } from "./interface";

interface IProps {
  cancelText?: string;
  okText?: string;
  submit?: ITrigger;
  cancel?: ITrigger;
  size?: SizeType;
  children?: ReactNode;
}

const FooterP: FC<IProps> = ({
  size,
  submit,
  cancel,
  okText,
  cancelText,
  children,
}) => {
  const click = useSubmitEvent(submit);
  if (!submit) {
    return null;
  }
  return (
    <div className="comp-submit">
      <Row justify="space-between">
        <Col>
          <Button
            size={size}
            type="default"
            style={{ backgroundColor: "#DCDCDC" }}
            onClick={cancel}
          >
            <FormattedMessage id={cancelText || "CANCEL"} />
          </Button>
        </Col>
        <Col>
          <Button size={size} type="primary" onClick={click}>
            <FormattedMessage id={okText || "APPLY"} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default FooterP;
