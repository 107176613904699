import IntlDep from "@/hooks/intl/IntlDep";
import useDiff from "@/hooks/useDiff";
import useFormP from "@/hooks/useFormP";
import useIntlDep from "@/hooks/useIntlDep";
import useResourceData from "@/hooks/useResourceData";
import EInfoType from "@/store/messages/infoType";
import { Button, Form, Space } from "antd";
import React, { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { ICallback, ITrigger } from "../../interface";
// import FooterP from "../../modal/footer";
import { IFormProps } from "../interface";
import "./index.less";
/**
 *  @title 简单表单
 *  @notice 此表单没有创建和编辑模式之分
 * @props forceShowSubmit 强制显示提交按钮
 */
const FormS: FC<
  IFormProps & {
    onFieldsChange?: ICallback<any>;
    forceShowSubmit?: boolean;
    children?: React.ReactNode;
    footerBtnConfig?: {
      closeBtn?: boolean;
      okText?: string;
    };
    onCancel?: ITrigger;
  }
> = (props) => {
  const [originData, onFieldsChange, isDifferent, loadDiffData] =
    useDiff<any>();
  const [formData, submit] = useFormP(props.submitEvent);
  const onFieldsChangeProps = props.onFieldsChange;
  // 载入数据
  const loadFormData = useCallback(
    (data: any) => {
      loadDiffData(data);
      formData.setFieldsValue(data);
      onFieldsChangeProps && onFieldsChangeProps(data);
    },
    [loadDiffData, formData, onFieldsChangeProps]
  );
  const resetData = useCallback(() => {
    loadFormData(originData);
    console.log(props.onCancel)
    props.onCancel?.();
  }, [loadFormData, originData,props]);
  const loadText = useIntlDep();
  useResourceData(EInfoType.form_load, props.id, loadFormData);
  //载入单条数据
  useResourceData(EInfoType.once_update, props.id, (data) =>
    formData.setFieldsValue(data)
  );
  return (
    <Form
      className="comp-form-simple"
      form={formData}
      onFinish={submit}
      onFieldsChange={(e: any[]) => {
        if (e.length > 0) {
          const obj = e[0];
          if (obj.name.length > 0) {
            const changeField = {
              [obj.name.toString()]: obj.value,
            };
            onFieldsChange(changeField);
            onFieldsChangeProps && onFieldsChangeProps(changeField);
          }
        }
      }}
      layout={props.layout || "vertical"}
      autoComplete="off"
      validateMessages={{
        required: loadText({ id: "FORM_REQUIRED" }),
        types: {
          email: loadText({ id: "EMAIL_NOT_VALID" }),
          url: loadText({ id: "URL_NOT_VALID" }),
        },
      }}
      colon={false}
    >
      {props.title && (
        <p className="title">
          <IntlDep id={props.title} />
        </p>
      )}
      {props.children}
      <div>
        {(isDifferent || props.forceShowSubmit) && (
          <Space style={{ marginTop: 20 }}>
            <Button type="primary" htmlType="submit">
              <FormattedMessage
                id={
                  (props.footerBtnConfig && props.footerBtnConfig.okText) ||
                  "APPLY"
                }
              />
            </Button>
            {!props.footerBtnConfig?.closeBtn && (
              <Button onClick={resetData} type="default">
                <FormattedMessage id={"CANCEL"} />
              </Button>
            )}
          </Space>
        )}
      </div>
      {/* {(isDifferent || props.forceShowSubmit) && (
        <FooterP
          submit={submit}
          footerBtnConfig={props.footerBtnConfig}
          cancel={resetData}
        />
      )} */}
    </Form>
  );
};

export default FormS;
