
import View from "@/common/form/view/template";
import { IModal, IText } from "@/common/interface";
import { findElementPropsInNode } from "@/common/utils/utils";
import React, { FC } from "react";
import FormItem from "../FormItem/item";
import { IFormViewProps, IViewItem, IViewRenderArrItem } from "../interface";

interface ReactElementConfig extends IText {
  el: FC<any>;
}

interface IProps {
  layout?: "horizontal" | "vertical",
  /** 多少列 */
  cols?: number;
  /** 从表单提取属性展示 */
  config: ReactElementConfig[] | FC<any>;
}

const ViewForm: FC<IProps & IModal> = (props) => {
  // 数据抽取-模板抽取
  let queue: IViewItem[] = [];
  try {
    if (Array.isArray(props.config)) {
      props.config.forEach((el) => {
        const elConf: IViewRenderArrItem = {
          text: el.text,
          child: [],
        };
        const elNodeObj = el.el({});
        // console.log("elNodeObj", elNodeObj);
        if (elNodeObj) {
          // 遍历
          const child = findElementPropsInNode(elNodeObj, FormItem.name);
          elConf.child = child.map((item) => {
            const basic: any =
              !!item.children && item.children.props
                ? { ...item.children.props }
                : {};
            basic.dataItem = item.name;
            basic.text = item.label;
            basic.textValue = item.labelValues;
            basic.type = item.children.type.name;
            return basic;
          });
          // 配置放入队列
          queue.push(elConf);
        }
      });
    } else {
      const el = props.config({});
      const child = findElementPropsInNode(el, FormItem.name);
      // console.log("elNodeObj.child", child);
      queue = child.map((item) => {
        const basic: any =
          !!item.children && item.children.props
            ? { ...item.children.props }
            : {};
        basic.dataItem = item.name;
        basic.text = item.label;
        basic.textValue = item.labelValues;
        basic.type = item.children.type.name;
        return basic;
      });
      // console.log("queue", queue);
    }
  } catch (error) {
    console.error("查看模板编译失败", error);
  }

  const viewProps: IFormViewProps = {
    id: props.id,
    title: props.title,
    config: queue,
    cols: props.cols || 2,
    layout: props.layout
  };
  return <View {...viewProps} />;
};

export default ViewForm;
