import liveAPI from "@/store/apis/node/live";
import reqService from "@/store/request";
import { BehaviorSubject, from } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

class LiveService {
  private id$: BehaviorSubject<number | null>;
  readonly data$ = new BehaviorSubject<any>(null);
  readonly liveWideType$ = new BehaviorSubject<number | null>(1);
  readonly liveCustomerId$ = new BehaviorSubject<number | null>(null);
  constructor() {
    this.id$ = new BehaviorSubject<number | null>(null);
    this.id$.pipe(distinctUntilChanged()).subscribe((id) => {
      if (typeof id !== "number") {
        return;
      }
      const config = liveAPI.Detail({ id }, {});
      from(reqService(config)).subscribe((info) => {
        if (info) {
          this.data$.next(info);
        }
      });
    });
  }
  
  readonly loadLiveInfo = (id: number) => {
    const config = liveAPI.Detail({ id }, {});
    from(reqService(config)).subscribe((info) => {
      if (info) {
        this.data$.next(info);
      }
    });
  };
  readonly loadLiveById = (id: number) => {
    this.id$.next(id);
  };

  readonly setliveWideType = (code: number) => {
    this.liveWideType$.next(code);
  };

  readonly setLiveCustomerId = (id: number) => {
    this.liveCustomerId$.next(id);
  };
}

const liveService = new LiveService();

export default liveService;
