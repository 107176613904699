import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { FC, ReactNode, useCallback } from "react";
import { IFormComponent } from "../../interface";
interface IProps {
  children?: ReactNode;
}
const FormCheckBox: FC<IFormComponent & IProps> = ({
  value,
  onChange,
  children,
}) => {
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      onChange && onChange(e.target.checked ? 1 : 0);
    },
    [onChange]
  );
  return (
    <Checkbox checked={!!value} onChange={handleChange}>
      {children}
    </Checkbox>
  );
};

export default FormCheckBox;
