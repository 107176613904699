import { AxiosRequestConfig } from "axios";
import { IListAddForm, ILiveListForm, ILiveSetLiveTimeForm, IUpdateForm } from "./interface";
class LiveAPI {
  /**
   * LiveList
   * 生成请求参数
   */
  LiveList = (params: ILiveListParams, data: ILiveListForm) => {
    const config: AxiosRequestConfig = {
      url: "/live/find_page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * ListAdd 
   * 生成请求参数
   */
  Create = (params: ILiveAddParams, data: IListAddForm) => {
    const config: AxiosRequestConfig = {
      url: "/live/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * Update
   * 生成请求参数
   */
  Update = (params: IUpdateParams, data: IUpdateForm) => {
    const config: AxiosRequestConfig = {
      url: "/live/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * listDetail
   * 生成请求参数
   */
  Detail = (params: ILiveDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/live/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * userDetail
   * 生成请求参数
   */
  Delete = (params: ILiveDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/live/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  Find = (params: ILiveFindParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/live_log/find",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  PairDto = (params: ILivePairDtoParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/live/find_distinct_list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  LiveCname = (params: ILiveCnameParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/live/live_cname",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  // 查询是否存在直播
  FindUserLiveCounts = (params: IFindUserLiveCountsParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/live/find_user_live_counts",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  // /live_log/find_page
  LiveLogPage = (params: {}, data: ILiveLogPage) => {
    const config: AxiosRequestConfig = {
      url: "/live_log/find_page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  // 查看直播定时信息
  liveGetLiveTime = (params: ILiveFindParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/live/get_live_time",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * 设置直播定时
   */
   liveSetLiveTime  = (params: {}, data: ILiveSetLiveTimeForm) => {
    const config: AxiosRequestConfig = {
      url: "/live/set_live_time",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
}

const liveAPI = new LiveAPI();
export default liveAPI;

/**livelist的请求参数 */
interface ILiveListParams {}

/** Update的请求参数*/
interface IUpdateParams {}

/** liveadd的请求参数*/
interface ILiveAddParams {}
/** Detail的请求参数*/
interface ILiveDetailParams {
  id: number;
}
/** delete的请求参数*/
interface ILiveDeleteParams {
  id: number;
}
/** Find*/
interface ILiveFindParams {
  liveId: number;
}
/** PairDto*/
interface ILivePairDtoParams {
  userId?: number;
}

/** PairDto*/
interface ILiveCnameParams {
  liveId: number;
}

interface IFindUserLiveCountsParams {
  userId: number;
}

interface ILiveLogPage {}
