import { IMapT } from "@/common/interface";
import accountService from "@/store/account/service";
import historyService from "@/store/history";
import reqServicePlx from "@/store/request/reqServicePlx";
import { BehaviorSubject, Subject } from "rxjs";
import { map } from "rxjs/operators";

/**
 * 页面级别缓存
 * 1 支持同步缓存
 * 2 支持异步缓存
 */
class PageCache {
  private url = "";
  private data$ = new Subject<any>();
  private cache$ = new BehaviorSubject<IMapT<any>>({});
  // 当前正在处理的缓存, 1表示已经在处理，0表示完成处理
  private stack: IMapT<number> = {};
  private userId = 0;

  /** 提供直接读取功能 */
  public readonly read = (key: string) => {
    return this.cache$.value[key] || null;
  };

  /** 订阅 */
  public readonly pipe = (key: string) => {
    return this.cache$.pipe(map((value) => value[key]));
  };

  /**
   * 创建数据
   * @param key
   * @param value
   * @param isAsync 是否为异步
   */
  public readonly setItem = (key: string, value: any, isAsync = false) => {
    if (!this.cache$.value[key]) {
      if (!isAsync) {
        this.data$.next({
          [key]: value,
        });
      } else {
        if (this.stack[key] !== 1) {
          if (!!value) {
            // 加入处理站
            this.stack[key] = 1;
            reqServicePlx<any>(value).then((res) => {
              if (res.isSuccess) {
                this.data$.next({
                  [key]: res.result,
                });
              }
            });
          }
        }
      }
    }
    return this.pipe(key);
  };

  /**
   * 动态刷新缓存
   * -只针对异步
   */
  public readonly refreshItem = (key: string, config: any) => {
    if (this.stack[key] !== 1) {
      if (config) {
        reqServicePlx<any>(config).then((res) => {
          if (res.isSuccess) {
            this.data$.next({
              [key]: res.result,
            });
          }
        });
      }
    }
  };

  /**
   * 删除某个值
   */
  public readonly removeItem = (key: string) => {
    if (this.cache$.value[key]) {
      // 清理数据缓存
      this.data$.next({
        [key]: null,
      });
    }
    if (this.stack[key] === 1) {
      // 清理工作栈
      this.stack[key] = 0;
    }
  };

  constructor() {
    historyService.listen((history: any) => {
      if (!!this.url && this.url !== history.pathname) {
        this.url = history.pathname;
        this.cache$.next({});
      }
    });
    this.data$.subscribe((data: any) => {
      const keys = Object.keys(data);
      keys.forEach((key) => {
        if (this.stack[key]) {
          this.stack[key] = 0;
        }
      });
      this.cache$.next({
        ...this.cache$.value,
        ...data,
      });
    });
    // 等不不同用户，清除应用缓存
    accountService.info$.subscribe((info) => {
      if (info) {
        if (info.id !== this.userId) {
          this.cache$.next({});
        }
      }
    });
  }
}

// 页面级别缓存
const pageCacheService = new PageCache();

export default pageCacheService;
