import { ILoginForm } from "@/common/interface";
import { AxiosRequestConfig } from "axios";
import {
  IRegisterForm,
  ISetPasswordForm,
  IUpdateForm,
  IUpdatePasswordForm,
  IUserAddForm,
  IUserListForm,
} from "./interface";
/**
 * @class AuthAPI 
 * @description 
 * @return 返回request的config
 */
class UserAPI {
  /**
   * login
   * 生成请求参数
   */
  Login = (params: ILoginParams, data: ILoginForm) => {
    const config: AxiosRequestConfig = {
      url: "/user/login",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * register
   * 生成请求参数
   */
  register = (params: {}, data: IRegisterForm) => {
    const config: AxiosRequestConfig = {
      url: "/user/register",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * detail
   * 生成请求参数
   */
  userDetail = (params: IUserDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/user/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  findRecaptchaKey = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/user/find_recaptchaKey",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * userLoginRecaptchaCheck
   * 生成请求参数
   */
  userLoginRecaptchaCheck = (
    params: IuserLoginRecaptchaCheckParams,
    data: {}
  ) => {
    const config: AxiosRequestConfig = {
      url: "/user/login_recaptcha_check",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * Update
   * 生成请求参数
   */
  Update = (params: IUpdateParams, data: IUpdateForm) => {
    const config: AxiosRequestConfig = {
      url: "/user/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * set_password
   * 生成请求参数
   */
  SetPassword = (params: ISetPasswordParams, data: ISetPasswordForm) => {
    const config: AxiosRequestConfig = {
      url: "/user/set_password",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * update_password
   * 生成请求参数
   */
  UpdatePassword = (
    params: IUpdatePasswordParams,
    data: IUpdatePasswordForm
  ) => {
    const config: AxiosRequestConfig = {
      url: "/user/update_password",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * UserList
   * 生成请求参数
   */
  UserList = (params: IUserListParams, data: IUserListForm) => {
    const config: AxiosRequestConfig = {
      url: "/user/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * nodeAdd
   * 生成请求参数
   */
  Create = (params: IUserAddParams, data: IUserAddForm) => {
    const config: AxiosRequestConfig = {
      url: "/user/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   */
  Delete = (params: IUserDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/user/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * Update
   * 生成请求参数
   */
  Enable = (params: IUserEnableParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/user/enable",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * Update
   * 生成请求参数
   */
  Disable = (params: IUserDisableParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/user/disable",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * nodeAdd
   * 生成请求参数
   */
  findAll = (params: IUserFindAllParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/user/all_user",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  setUserYrecode = (params: {}, data: IsetUserYrecodeData) => {
    const config: AxiosRequestConfig = {
      url: "/user_yrecord/set_user",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /* 获取用户appKey列表 */
  getUserAppKey = (params: {}, data: IGetUserAppKey) => {
    const config: AxiosRequestConfig = {
      url: "/user/key/find_page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 用户创建appKey */
  createAppKey = (params: ICreateAppKey, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/user/key/generate",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 用户删除appKey */
  deleteAppKey = (params: {}, data: IDeleteAppKey) => {
    const config: AxiosRequestConfig = {
      url: "/user/key/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  userGoogleGoginCode = (params: { code: string }, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/user/google/login/code",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
}

const userAPI = new UserAPI();
export default userAPI;

/** login的请求参数*/
interface ILoginParams {}

/** UserDetail的请求参数*/
interface IUserDetailParams {}

/** Update的请求参数*/
interface IUpdateParams {}

/** SetPassword的请求参数*/
interface ISetPasswordParams {}

/** UpdatePassword的请求参数*/
interface IUpdatePasswordParams {}

/**userlist的请求参数 */
interface IUserListParams {}

/**findalllist的请求参数 */
interface IUserFindAllParams {}

/** useradd的请求参数*/
interface IUserAddParams {}

/** userDelete的请求参数*/
interface IUserDeleteParams {
  id: number;
}

/** userDelete的请求参数*/
interface IUserEnableParams {
  id: number;
}

/** userDelete的请求参数*/
interface IUserDisableParams {
  id: number;
}
/**userLoginRecaptchaCheck的请求参数*/
interface IuserLoginRecaptchaCheckParams {}

/* setUserYrecode的请求参数 */
interface IsetUserYrecodeData {
  userId: number;
  streamingRecord?: string;
  pushStreamingRecord?: string;
  srtRecord?:string;
}

interface ICreateAppKey {
  userId: number | undefined;
}
interface IGetUserAppKey {
  keyword: string;
  page: number;
  pageSize: number;
}
interface IDeleteAppKey {}
