import FormItem from "antd/lib/form/FormItem";
import useVisible from "@/hooks/useVisible";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { FC } from "react";

const LoginForm: FC = () => {
  const [visible, changeVisible] = useVisible();
  return (
    <section>
      <FormItem label="Username" name="username">
        <Input />
      </FormItem>
      <div className="zdy-password">
        <FormItem label="Password" name="password">
          <Input type={visible ? "text" : "password"} className="password" />
        </FormItem>
        <span onClick={changeVisible} className="zdy-password-icon">
          {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </span>
      </div>
    </section>
  );
};

export default LoginForm;
