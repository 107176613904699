import { ISearchPage } from "@/common/template/interface";
import { AxiosRequestConfig } from "axios";
import { ICertCreateForm, IFindCertByDomainForm } from "./interface";

/**
 * @class NodeAPI
 * @description
 * @return 返回request的config
 */
class CertAPI {
  /**
   * doaminList
   * 生成请求参数
   * 	证书列表
   */
  
  certList = (params: ICertListParams, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/cert/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * findall
   * 生成请求参数
   */
  certFindAll = (params: ICertFindAllParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/cert/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * create
   * 生成请求参数
   * 证书新增
   */
  createCert = (params: ICertCreateParams, data: ICertCreateForm) => {
    const config: AxiosRequestConfig = {
      url: "/cert/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   * 证书详情
   */
  Detail = (params: ICertDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/cert/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

    /**
   * findCertByDomain查看域名和证书的对应关系
   * 生成请求参数
   * 查看证书和域名对应关系
   */
     findCertByDomain = (params: {}, data: IFindCertByDomainForm) => {
      const config: AxiosRequestConfig = {
        url: "/cert/find_cert_by_domain",
        method: "post",
        params,
        data,
      };
      config.headers = {};
      config.headers["Content-Type"] = "application/json";
      return config;
    };

  /**
   * userDetail
   * 生成请求参数
   * 	证书删除
   */
  Delete = (params: ICertDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/cert/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * Update
   * 生成请求参数
   * 证书修改
   */
  Update = (params: ICertUpdateParams, data: ICertCreateForm) => {
    const config: AxiosRequestConfig = {
      url: "/cert/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
}
const certAPI = new CertAPI();
export default certAPI;

/** list的请求参数*/
interface ICertListParams {}

/** Detail的请求参数*/
interface ICertDetailParams {
  id: number;
}

/** FindAll的请求参数*/
interface ICertFindAllParams {}

/** add的请求参数*/
interface ICertCreateParams {}

/** delete的请求参数*/
interface ICertDeleteParams {
  id: number;
}

interface ICertUpdateParams {}
