import { ISelectItem } from "@/common/interface";

export enum INodeTypeE {
  NORMAL = 1, // *正常
  DISABLE = 0, // !禁用
  PUSHINGFLOW = 2 // *推流中
}

export const INodeTypeSelect: ISelectItem[] = [
  { id: INodeTypeE.NORMAL, name: "NORMAL" },
  { id: INodeTypeE.DISABLE, name: "DISABLE" },
  { id: INodeTypeE.PUSHINGFLOW, name: "PUSHING_FLOW" },
];

export interface ILiveRouterparams {
  id: number;
}
