import { ICallback } from "@/common/interface";
import useIntlDep from "@/hooks/useIntlDep";
import useVisible from "@/hooks/useVisible";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { FC, useCallback, useState } from "react";
interface IProps {
  pwdisSame: boolean;
  setPwdisSame: ICallback<boolean>;
}
const RegisterForm: FC<IProps> = ({ pwdisSame, setPwdisSame }) => {
  const [visible, changeVisible] = useVisible();
  const [visible2, changeVisible2] = useVisible();
  const [pwd, setPwd] = useState<string>();
  const IntlText = useIntlDep();
  const focusNewPwd = useCallback(
    (value: string) => {
      setPwdisSame(true);
      if (pwd === value) {
        setPwdisSame(false);
      }
    },
    [pwd, setPwdisSame]
  );
  return (
    <section>
      <FormItem label="Username" name="username">
        <Input placeholder={IntlText({ id: "PLEASE_ENTER_EMAIL_ADDRESS" })} />
      </FormItem>
      <div className="zdy-password">
        <FormItem label="Password" name="p1">
          <Input
            onChange={(e) => setPwd(e.target.value)}
            placeholder={IntlText({ id: "PLEASE_ENTER_YOUR_PASSWORD" })}
            type={visible ? "text" : "password"}
            className="password"
          />
        </FormItem>
        <span onClick={changeVisible} className="zdy-password-icon">
          {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </span>
      </div>
      <div className="zdy-password">
        <FormItem
          validateStatus={pwdisSame ? "error" : undefined}
          label="Confirm Password"
          name="p2"
        >
          <Input
            placeholder={IntlText({ id: "CONFIRM_PASSWORD" })}
            onChange={(e) => focusNewPwd(e.target.value)}
            type={visible2 ? "text" : "password"}
            className="password"
          />
        </FormItem>
        <span onClick={changeVisible2} className="zdy-password-icon">
          {visible2 ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </span>
      </div>
      <FormItem hidden initialValue={"reg"} name="types">
        <Input />
      </FormItem>
    </section>
  );
};

export default RegisterForm;
