import { AxiosRequestConfig } from "axios";

/**
 * @class NodeAPI
 * @description
 * @return 返回request的config
 */
class IdcAPI {
  /**
   * findall
   * 生成请求参数   
   */
  findList = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/idc/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  Create = (params: {}, data: ICreateIdc) => {
    const config: AxiosRequestConfig = {
      url: "/idc/create",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  Update = (params: {}, data: IUpdateIdc) => {
    const config: AxiosRequestConfig = {
      url: "/idc/edit",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  Delete = (params: IDeleteIdc, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/idc/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  findAll = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/idc/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
}

const idcAPI = new IdcAPI();
export default idcAPI;

interface ICreateIdc {
  idcName:string;
  remark:string;
}

interface IUpdateIdc {
  id:number;
  idcName:string;
  remark:string;
}

interface IDeleteIdc {
  id:number;
}

