import { ISearchPage } from "@/common/template/interface";
import { AxiosRequestConfig } from "axios";
import { INodeAddForm } from "./interface";

/**
 * @class NodeAPI
 * @description
 * @return 返回request的config
 */
class NodeAPI {
  /**
   * nodeList
   * 生成请求参数
   * 节点列表
   */
  nodeList = (params: INodeListParams, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/node/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * nodeAdd
   * 生成请求参数
   * 添加节点
   */
  nodeAdd = (params: INodeAddParams, data: INodeAddForm) => {
    const config: AxiosRequestConfig = {
      url: "/node/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   * 节点详情
   */
  Detail = (params: INodeDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/node/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   * 节点类型列表
   */
  NodeTypeList = (params: INodeTypeListParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/node/type_list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * Update
   * 生成请求参数
   * 节点修改
   */
  nodeUpdate = (params: INodeUpdateParams, data: INodeAddForm) => {
    const config: AxiosRequestConfig = {
      url: "/node/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   * 节点删除
   */
  Delete = (params: INodeDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/node/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /* 安装节点 节点安装*/
  InstallNode = (params: {}, data: IIinstallNodeData) => {
    const config: AxiosRequestConfig = {
      url: "/node/install",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 安装日志 */
  InstallLog = (params: IInstallLog, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/node/find_install_log",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /* 查找节点数量 */
  NodeTypeCounts = (params: INodeTypeCounts, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/node/find_node_type_counts",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 查找安装节点个数 */

  FindInstallNodeCounts = (params: {}, data: IFindInstallNodeCounts) => {
    const config: AxiosRequestConfig = {
      url: "/node/find_install_node_counts",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /*按类型查找节点列表  */
  /* type:edge边缘节点 origin源节点  status: 0未安装 1安装成功 2安装中 3安装失败*/
  FindNodeTypeList = (params: {}, data: IFindNodeTypeList) => {
    const config: AxiosRequestConfig = {
      url: "/node/find_node_type_list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 根据id查询节点安装状态 */

  FindInstallLogList = (params: {}, data: IFindInstallLogList) => {
    const config: AxiosRequestConfig = {
      url: "/node/find_install_log_list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 获取用户数据中心拉流转播节点数量 /node/find_area_ingest */  
  findAreaIngest = (params: IFindAreaIngest, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/node/find_area_ingest",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
}
const nodeAPI = new NodeAPI();
export default nodeAPI;

/** list的请求参数*/
interface INodeListParams {}

/** Detail的请求参数*/
interface INodeDetailParams {
  id: number;
}

/** Update的请求参数*/
interface INodeUpdateParams {}

/** add的请求参数*/
interface INodeAddParams {}

/** delete的请求参数*/
interface INodeDeleteParams {
  id: number;
}
interface INodeTypeListParams {
  type:
    | "edge"
    | "origin"
    | "middle"
    | "ingest"
    | "vod-transcode"
    | "vod-origin";
}
interface IIinstallNodeData {
  nodeIp: string;
  sshPort: number;
  sshName: string;
  sshPassword: string;
  id: number;
}
interface IInstallLog {
  id: number;
}
interface INodeTypeCounts {
  type: string;
}
interface IFindInstallNodeCounts {
  typeList: string[];
  statusList: number[];
}

interface IFindNodeTypeList {
  typeList: string[];
  statusList: number[];
}

interface IFindInstallLogList {
  ids: number[];
}

interface IFindAreaIngest {
  userId:number;
}
