import { FC } from "react";
import { ITrigger } from "../interface";
import { Button, Space } from "antd";
import "./footer.less";
import { FormattedMessage } from "react-intl";
import { SizeType } from "antd/es/config-provider/SizeContext";
import useSubmitEvent from "@/hooks/useSubmitEvent";

interface IProps {
  cancelText?: string;
  okText?: string;
  submit?: ITrigger;
  cancel?: ITrigger;
  size?: SizeType;
  children: React.ReactNode;
  footerBtnConfig?: {
    closeBtn?: boolean;
    okText?: string;
  };
}

const FooterP: FC<IProps> = ({
  size,
  submit,
  cancel,
  okText,
  cancelText,
  footerBtnConfig = {
    closeBtn: true,
    justify: "end",
    okText,
  },
  children,
}) => {
  const click = useSubmitEvent(submit);
  if (!submit) {
    return null;
  }
  return (
    <div className="comp-submit">
      <Space>
        {children}
        <Button size={size} type="primary" onClick={click}>
          <FormattedMessage id={okText || footerBtnConfig.okText || "APPLY"} />
        </Button>
        {footerBtnConfig.closeBtn && (
          <Button size={size} type="default" onClick={cancel}>
            <FormattedMessage id={cancelText || "CANCEL"} />
          </Button>
        )}
      </Space>
    </div>
  );
};

export default FooterP;
