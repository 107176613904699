import ModalP from "@/common/modal";
import IntlDep from "@/hooks/intl/IntlDep";
import useSubscribe from "@/hooks/useSubscribe";
import useVisible from "@/hooks/useVisible";
import EInfoType from "@/store/messages/infoType";
import React, { FC, useState, useCallback } from "react";
import icon from "./images/info.png";
import { IConfirmInfo } from "./interface";

const Confirm: FC = () => {
  const [visible, switchVisible] = useVisible();
  const [config, setConfig] = useState<IConfirmInfo>({
    text: "",
    event: () => {},
  });

  const loadConfirm = useCallback(
    (zconfig: IConfirmInfo) => {
      setConfig(zconfig);
      setTimeout(switchVisible, 50);
    },
    [switchVisible]
  );

  const reset = useCallback(() => {
    setConfig({
      text: "",
      event: () => {},
    });
  }, []);

  useSubscribe(EInfoType.confirm_normal, loadConfirm);

  return (
    <ModalP
      size="small"
      title={<IntlDep id="TIP" />}
      visible={visible}
      onCancel={switchVisible}
      onSubmit={() => {
        config.event();
        switchVisible();
      }}
      okText={config.okText}
      zIndex={7000}
      afterClose={reset}
    >
      <div className="comp-global-dialog">
        <img style={{ height: 38 }} src={icon} alt="tip" />
        <p>{config.text}</p>
      </div>
    </ModalP>
  );
};

export default Confirm;
