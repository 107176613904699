import CheckButton from "@/common/form/checkButton";
import { reqAndRunCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail, { useIsAdmin } from "@/hooks/useUserDetail";
import vodDomainRelationAPI from "@/store/apis/node/vodDomain";
import { notification, Popconfirm } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { FC, useCallback, useEffect, useState } from "react";
import useCustomerLiveId from "../../Service/useCustomerLiveId";
interface IProps {
  value?: 0 | 1;
  onChange?: any;
  placement?:TooltipPlacement;
}
const CheckPopconfirm: FC<IProps> = ({ value, onChange,placement='top' }) => {
  const [_value, setValue] = useState<number>(0);
  const loadText = useIntlDep();
  const customerId = useCustomerLiveId();
  const userInfo = useUserDetail();
  const isAdmin = useIsAdmin();
  const [userId, setUserId] = useState<number>();
  useEffect(() => {
    /* 
      是否是管理员账户 
        ? 是否选择客户customerId 
          ? userId = customerId 
          : return 
        : userId=userInfo?.id */
    if (isAdmin) {
      if (!customerId) {
        return;
      }
      setUserId(customerId);
    } else {
      setUserId(userInfo?.id);
    }
  }, [isAdmin, customerId, userInfo]);
  const confirm = useCallback(() => {
    if (value === 0 && typeof value === "number") {
      if (userId) {
        const config = vodDomainRelationAPI.vodDomainList({ userId }, {});
        reqAndRunCallback(config, (res) => {
          if (Array.isArray(res) && res.length !== 0) {
            setValue(1);
            onChange?.(1);
          } else {
            setValue(0);
            onChange?.(0);
            notification.warning({
              message: loadText({
                id: "FAILED_TO_ENABLE_LIVE_RECORDING",
              }),
              duration: 2.5,
            });
          }
        });
      } else {
        setValue(0);
        onChange?.(0);
      }
    }
  }, [onChange, value, userId, loadText]);
  useEffect(() => {
    if (typeof value === "number") {
      setValue(value);
    }
  }, [value]);
  const checkLiveRecord = useCallback(() => {
    setValue(0);
    onChange?.(0);
  }, [onChange]);

  const PopconfirmTit = useCallback(() => {
    return (
      <div style={{ width: 200 }}>
        <p>
          <IntlDep id="LR_RELEASE_TOOLTIP_1" />
        </p>
        <p>
          <IntlDep id="ENABLING_LIVE_RECORDING" />
        </p>
        <p>
          <IntlDep id="CAN_BE_PUSHED_STREAM_FILE_CONTENT_RECORDED_INTO_A_FILE" />
        </p>
      </div>
    );
  }, []);
  return (
    <>
      {value === 1 && typeof value === "number" && (
        <CheckButton
          value={_value}
          true={1}
          false={0}
          onChange={checkLiveRecord}
        />
      )}
      {value === 0 && typeof value === "number" && (
        <Popconfirm
          placement={placement}
          title={PopconfirmTit}
          onConfirm={confirm}
          // onCancel={cancel}
          okText={loadText({
            id: "CONFIRM",
          })}
          cancelText={loadText({
            id: "CANCEL",
          })}
        >
          <CheckButton
            value={_value}
            true={1}
            false={0}
              // onChange={checkLiveRecord}
          />
        </Popconfirm>
      )}
    </>
  );
};
export default CheckPopconfirm;
