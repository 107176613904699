import { ELogin } from "@/store/account/interface";
import accountService from "@/store/account/service";
import { useEffect, useState } from "react";

/**
 * 用户当前登录状态监控
 */
const useLogin = () => {
  const [state, setState] = useState<ELogin>(accountService.login$.value);
  useEffect(() => {
    const sub = accountService.login$.subscribe((login) => {
      setState(login);
    });
    return () => sub.unsubscribe();
  }, []);
  return state;
};

export default useLogin;
