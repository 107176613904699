import { ICallback } from "@/common/interface";
import { AxiosRequestConfig } from "axios";

class FileAPI {
  /**
   * 上传图片的api
   * 生成请求参数
   */
  uploadPic = (params: IUploadParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/file/upload/pic",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * 上传视频的api
   * 生成请求参数
   */
  uploadVideo = (params: IUploadParams, data: {}, cb?: ICallback<number>) => {
    const config: AxiosRequestConfig = {
      url: "/file/upload/video",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.timeout = 30000000;
    config.onUploadProgress = (progressEvent) => {
      let persent = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
      cb && cb(persent);
    };
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 上传许可证 */
  uploadLicenseCrt = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/file/upload_license_crt",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
}
const fileAPI = new FileAPI();
export default fileAPI;

interface IUploadParams {}
