import { IMapT } from '@/common/template/interface'
import pageCacheService from '@/resource/page'
import { useCallback, useReducer, useMemo, useEffect } from 'react'
import { Subject, Subscription } from 'rxjs'

function reducerKey(state: any, action: any) {
  if (action.type === 'add') {
    if (typeof state[action.value] === 'undefined') {
      return { ...state, [action.value]: null }
    }
  }
  if (action.type === 'edit') {
    return { ...state, ...action.value }
  }
  return state
}

/**
 * 缓存异步处理的字典
 * 当前正在执行的异步处理都存储在这里
 * @returns
 */
const useAsyncDicts = () => {
  const [data, dispatch] = useReducer(reducerKey, {} as IMapT<any>)
  const key$ = useMemo(() => new Subject(), [])

  const addKey = useCallback(
    (key: string) => {
      key$.next(key)
    },
    [key$]
  )

  useEffect(() => {
    const sub = key$.subscribe((key) => {
      // 初始化
      dispatch({
        type: 'add',
        value: key,
      })
    })
    return () => sub.unsubscribe()
  }, [key$])

  // 将初始化好的数据，自动载入订阅
  useEffect(() => {
    const subs: Subscription[] = []
    const keys = Object.keys(data)
    if (keys.length > 0) {
      keys.forEach((key) => {
        if (data[key] === null) {
          subs.push(
            pageCacheService.pipe(key).subscribe((value) => {
              if (value) {
                dispatch({
                  type: 'edit',
                  value: {
                    [key]: value,
                  },
                })
              }
            })
          )
        }
      })
    }
    return () => {
      subs.forEach((sub) => sub.unsubscribe())
    }
  }, [data])

  return [data, addKey]
}

export default useAsyncDicts
