import { ISearchPage } from "@/common/template/interface";
import { AxiosRequestConfig } from "axios";
import { ICreateRecordForm } from "./interface";

class RecordAPI {
  /**
   * List
   * 生成请求参数
   * 记录列表
   */
  recordList = (params: IRecordListParams, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/record/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * 获取Y记录
   * 生成请求参数
   */
  findGenerateY = (params: IFindGenerateYParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/record/generate_y",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * getuser
   * 生成请求参数
   */
  getUser = (params: IGetUserRecordParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/record/get_user",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * nodeAdd
   * 生成请求参数
   * 记录新增
   */
  CreateRecord = (params: ICreateRecordParams, data: ICreateRecordForm) => {
    const config: AxiosRequestConfig = {
      url: "/record/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * Detail
   * 生成请求参数
   * 记录详情
   */
  Detail = (params: IRecordDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/record/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * Update
   * 生成请求参数
   * 记录修改
   */
  Update = (params: IRecordUpdateParams, data: ICreateRecordForm) => {
    const config: AxiosRequestConfig = {
      url: "/record/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * Update
   * 生成请求参数
   */
  setUser = (params: ISetUserRecordParams, data: ICreateRecordForm) => {
    const config: AxiosRequestConfig = {
      url: "/record/set_user",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   * 记录删除
   */
  Delete = (params: IRecordDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/record/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * Enable
   * 生成请求参数
   * 记录启用
   * 记录禁用
   */
  Enable = (params: IRecordEnableParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/record/enable",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * Disable
   * 生成请求参数
   */
  Disable = (params: IRecordDisableParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/record/disable",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * nodeAdd
   * 生成请求参数
   */
  userYRecordList = (params: {}, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/record/user/y_record/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * DefaultYrecord
   * 生成请求参数
   */
  DefaultYRecord = (params: DefaultYRecordParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/get_default_yrecord",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * setDefaultYRecord
   * 生成请求参数
   */
  setDefaultYRecord = (
    params: ISetDefaultYRecordParams,
    data: ISetDefaultYRecordForm
  ) => {
    const config: AxiosRequestConfig = {
      url: "/lookup/set_default_yrecord",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * YRecordManagement
   * 生成请求参数
   */
  YRecordManagement = (params: {}, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * Detail
   * 生成请求参数
   * 节点组详情
   */
  YRecordDetail = (params: IRecordDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * CreateYRecord
   * 生成请求参数
   * 节点组新增
   */
  CreateYRecord = (params: ICreateYRecordParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /* 修改节点组 节点组修改*/
  UpdateYRecord = (params: {}, data: IUpdateYRecordData) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * YRecordFindAll
   * 生成请求参数
   */
  YRecordFindAll = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * getGenerateY
   * 获取Y记录
   */
  getGenerateY = (params: IGetYRecord, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/record/generate_y",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * DeleteYRecord
   * 删除Y记录
   * 节点组删除
   */
  DeleteYRecord = (params: IRecordYDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /* 节点组分页查询 节点组列表  /y_record/find_page */

  GetNodeGroup = (params: {}, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/find_page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /* 设置缺省节点组 */
  SetDefaultYrecord = (params: ISetDefaultYrecord, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/set_default_yrecord",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /* 推流用户数分页： /y_record/push_stream_page  节点组推流用户*/
  PushStreamPage = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/push_stream_page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /* 播流用户数分页： /y_record/pull_stream_page  节点组播流用户数*/

  PullStreamPage = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/pull_stream_page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  SrtStreamPage = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/y_record/srt_stream_page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  diskFindDistinctList = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/disk/find_distinct_list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
}

const recordAPI = new RecordAPI();
export default recordAPI;

/** list的请求参数*/
interface IRecordListParams {}
/** Detail的请求参数*/
interface IRecordDetailParams {
  id: number;
}

/** Update的请求参数*/
interface IRecordUpdateParams {}

/** create的请求参数*/
interface ICreateRecordParams {}

/** delete的请求参数*/
interface IRecordDeleteParams {
  id: number;
}
/** delete RecoredY的请求参数*/
interface IRecordYDeleteParams {
  id: number;
}

/** enable的请求参数*/
interface IRecordDisableParams {
  id: number;
}

/** disable的请求参数*/
interface IRecordEnableParams {
  id: number;
}

/**获取Y记录的请求参数 */
interface IFindGenerateYParams {}

/**给用户设置y记录的请求参数 */
interface ISetUserRecordParams {}

/*getuser的请求参数 */
interface IGetUserRecordParams {}

/*DefaultYRecord的请求参数 */
interface DefaultYRecordParams {}

/*setDefaultYRecord */
interface ISetDefaultYRecordForm {
  pullStreamingYRecord: string;
  pushStreamingYRecord: string;
}
interface ISetDefaultYRecordParams {}

/** create的请求参数*/
interface ICreateYRecordParams {
  name: string;
}

/** IGetYRecord*/
interface IGetYRecord {}

/* IUpdateYRecordData */
interface IUpdateYRecordData {
  id: number;
  name: string;
}

/* 设置缺省节点组 */
interface ISetDefaultYrecord {
  id: number;
}
