import React, { FC, useState, useCallback } from "react";
import { Col, notification, Row } from "antd";
import ModalID from "../modal/id";
import { getEventId } from "@/hooks/useEventId";
import ClipBoardContext from "./context";
import messageService from "@/store/messages";
import "./modal.less";
import useSubscribe from "@/hooks/useSubscribe";
import EInfoType from "@/store/messages/infoType";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";

const clipBoardId = getEventId();

const ClipModal: FC = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const loadtext = useIntlDep();
  const load = useCallback(
    ({ title, text }: any) => {
      if (title && text) {
        setTitle(title);
        setText(text);
        messageService.createEvent(clipBoardId);
      } else {
        notification.error({
          message: loadtext({ id: "EMPTY_VALUE_IS_PROHIBITED" }),
        });
      }
    },
    [loadtext]
  );
  useSubscribe(EInfoType.copy_to_clipboard, load);

  return (
    <ModalID title={<IntlDep id={title || "CLIPBOARD"} />} id={clipBoardId}>
      <Row justify="center">
        <Col className="comp-clip">
          <ClipBoardContext value={text} />
        </Col>
      </Row>
    </ModalID>
  );
};

export default ClipModal;
