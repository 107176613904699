import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import { ISelectItem } from "@/common/interface";
import { reqAndRunCallback } from "@/common/utils/utils";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail from "@/hooks/useUserDetail";
import transcodingAPI from "@/store/apis/node/transcoding";
import { Input } from "antd";
import { AxiosRequestConfig } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { LiveType, Radio, SelectPlayer, SelectWidthSize, Switch } from "./selectData";

const NodeForm: FC = () => {
  const [transcoding, setTranscoding] = useState<ISelectItem[]>([]);
  const userInfo = useUserDetail();
  const loadText = useIntlDep();

  const getTranscodingType = useCallback(() => {
    const config: AxiosRequestConfig = transcodingAPI.TranscodingLiveList(
      { userId: userInfo?.id },
      {}
    );
    reqAndRunCallback(config, (res) => {
      if (res) {
        setTranscoding([
          { name: `${loadText({ id: "ORIGINAL" })}`, id: 0 },
          ...res,
        ]);
      }
    });
  }, [userInfo, loadText]);
  useEffect(() => {
    getTranscodingType();
  }, [getTranscodingType]);
  return (
    <section>
      {/* 服务名称 */}
      <FormItem label="SERVICE_NAME" name="appName">
        <Input />
      </FormItem>
      {/* 推流方式 */}
      <FormItem label="PUSH_FLOW_MODE" name="liveType">
      <SelectP data={LiveType} enableintl />
      </FormItem>
      {/* 直播/推流 */}
      <FormItem
        label="A_B"
        labelValues={{
          name1: "PUSH",
          name2: "BASIN",
        }}
        name="pushStreamingDomain"
      >
        <Input />
      </FormItem>
      {/* 播流 */}
      <FormItem
        label="A_B"
        labelValues={{
          name1: "SOWING",
          name2: "BASIN",
        }}
        name="streamingDomain"
      >
        <Input />
      </FormItem>
      {/* 转码模板 */}
      <FormItem label="TRANSCODING_TEMPLATE" name="transcodingId">
        <SelectP data={transcoding} />
      </FormItem>
      {/* HLS VOD流 */}
      <FormItem label="WHETHER_TO_CREATE_HLS" name="hlsSwitch">
        <SelectP data={Switch} enableintl />
        {/* <CheckButton true={1} false={0} /> */}
      </FormItem>
      {/* 纵横比 */}
      <FormItem label="ASPECT_RATIO" name="aspectRatio">
        <Input />
      </FormItem>
      {/* 直播录制 */}
      <FormItem label="LIVE_RECORDING" name="recordSwitch">
        {/* <CheckButton true={1} false={0} /> */}
        <SelectP data={Switch} enableintl />
      </FormItem>
      {/* 播放器 */}
      <FormItem label="PAGE_HOSTING_PLAYER" name="type">
        <SelectP data={SelectPlayer} enableintl />
      </FormItem>
      {/* 播放宽度*/}
      <FormItem name="wideValue">
        <SelectP data={SelectWidthSize} enableintl />
      </FormItem>
      {/* 倒计时钟 */}
      <FormItem label="DISPLAY_COUNTDOWN_CLOCK" name="countdownSwitch">
        {/* <CheckButton true={1} false={0} /> */}
        <SelectP data={Switch} enableintl />
      </FormItem>
      {/* 视频海报图片*/}
      <FormItem
        label="A_B"
        labelValues={{
          name1: "DISPLAY_IN_PLAYER",
          name2: "POSTER_IMAGE",
        }}
        name="posterAddress"
      >
        <Input />
        {/* <ImageView /> */}
      </FormItem>
      {/* 播放器中显示水印 */}
      <FormItem
        label="A_B"
        labelValues={{
          name1: "DISPLAY_IN_PLAYER",
          name2: "WATERMARK",
        }}
        name="posterSwitch"
      >
        {/* <CheckButton true={1} false={0} /> */}
        <SelectP data={Switch} enableintl />
      </FormItem>
      {/* 水印图片 */}
      <FormItem label="WATERMARK_IMAGE" name="playMicroLabelAddress">
        <Input />
      </FormItem>
      {/* 播放器水印上的位置*/}
      <FormItem
        label="A_B_C"
        labelValues={{
          name1: "CHOOSE",
          name2: "WATERMARK",
          name3: "POSITION_IN_PLAYER",
        }}
        name="microLabelLocation"
        inline
      >
        <SelectP data={Radio} enableintl />
      </FormItem>
      {/* 托管页面*/}
      <FormItem label="HOSTED_HUB" name="hostingSwitch">
        {/* <CheckButton true={1} false={0} /> */}
        <SelectP data={Switch} enableintl />
      </FormItem>
      {/* 托管页面标题*/}
      <FormItem label="PAGE_TITLE" name="title">
        <Input />
      </FormItem>
      {/* 托管页面描述*/}
      <FormItem label="DESCRIBE" name="description">
        <Input.TextArea />
      </FormItem>
      {/* 托管页面水印*/}
      <FormItem label="HOST_LOGO" name="microLabelAddress">
        <Input />
      </FormItem>
      {/* 托管页面背景图 */}
      <FormItem label="HOST_PAGE_BACKGROUND_IMAGE" name="backgroundAddress">
        <Input />
      </FormItem>
    </section>
  );
};
export default NodeForm;
