import React, { FC, lazy } from "react";
import LayoutPlx from "@/common/layout";
import lazyLoad from "@/common/helpers/lazyLoad";
import { RouteObject, useRoutes } from "react-router-dom";
import { Redirect } from "@/common/utils/utilsX";
// 普通路由匹配
const GuideRouter: Array<RouteObject> = [
  {
    element: <LayoutPlx />,
    children: [
      {
        path: "/guide-overview",
        element: lazyLoad(lazy(() => import("@/pages/guide/adminGuide/index"))),
      },
    ],
  },
  {
    path: "*",
    element: <Redirect to="/guide-overview" />,
  },
];
const GuideRouterP: FC = () => {
  return useRoutes(GuideRouter);
};
export default GuideRouterP;
