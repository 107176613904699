import { BehaviorSubject } from "rxjs";
// import { IPermissionTreeNodes } from "@/store/account/interface";
import { IMenuLv2 } from "../interface";
// import accountService from "@/store/account/service";
/**
 * menu
 */
class Menu {
  readonly list$ = new BehaviorSubject<IMenuLv2[]>([]);
  constructor() {
    this.list$.subscribe((token) => {});
  }
  readonly initMenu = () => {
    //  const a =  accountService.info$.value?.roleCodes;
    // console.log(a);
  };
  readonly loadMenu = (list: IMenuLv2[]) => {
    this.list$.next(list);
  };
}
const menuService = new Menu();
export default menuService;
