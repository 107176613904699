import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import UploadPicOnlyOne from "@/common/form/uploadFile/uploadPicOnlyOne";
import { FC } from "react";
import { SelectPlayer } from "../selectData";
import CountdownModel from "./countdownModel";
import SelectSize from "./SelectSize";
import WaterMark from "./WaterMark";
interface IProps {
  hidden: boolean;
  isRequired?:boolean;
}

const PlaySet: FC<IProps> = ({ hidden,isRequired }) => {

  return (
    <section hidden={hidden}>
      {/* 请选择播放器 */}
      <FormItem
        label="PLEASE_SELECT_PLAYER"
        name="type"
        rules={[{ required: isRequired, message: "Please select gender!" }]}
        initialValue={1}
      >
        <SelectP data={SelectPlayer} enableintl />
      </FormItem>
      {/* 播放器宽度 */}
      <FormItem label="PLAYER_WIDTH" noStyle name="wideType">
        <SelectSize isRequired={isRequired}/>
      </FormItem>
      {/* 显示倒计时时钟 */}
      <FormItem label="DISPLAY_COUNTDOWN_CLOCK" inline name="countdownSwitch">
        <CountdownModel />
      </FormItem>
      {/* 播放器中显示海报图像 */}
      <FormItem
        label="A_B"
        labelValues={{
          name1: "DISPLAY_IN_PLAYER",
          name2: "POSTER_IMAGE",
        }}
        name="posterAddress"
        inline
      >
        <UploadPicOnlyOne />
      </FormItem>
      <FormItem name="posterSwitch">
        <WaterMark/>
      </FormItem>
    </section>
  );
};
export default PlaySet;
