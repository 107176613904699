import useJoiner from "@/hooks/useJoiner";
import React, { FC, useMemo } from "react";

interface IProps {
  start: string;
  end: string;
  total: string;
  hide: boolean;
}

const FooterDetail: FC<IProps> = ({ start, end, total, hide }) => {
  const joiner = useJoiner();
  const message = useMemo(
    () =>
      joiner([
        { id: "OF_TEXT", intl: true },
        { id: start, intl: false },
        { id: "TILL_NO", intl: true },
        { id: end, intl: false },
        { id: "OF_RECORD", intl: true },
        { id: "OF_TOTAL", intl: true },
        { id: total, intl: false },
        { id: "OF_ROW", intl: true },
      ]),
    [end, joiner, start, total]
  );
  return <div style={{ color: "#A7A7A7" }}>{!hide && message}</div>;
};
export default FooterDetail;
