import CheckButton from "@/common/form/checkButton";
import FormItem from "@/common/form/FormItem/item";
import UploadPicOnlyOne from "@/common/form/uploadFile/uploadPicOnlyOne";
import useIntlDep from "@/hooks/useIntlDep";
import { FC, useCallback } from "react";
import RadioP from "./positionsRadio";
interface IProps {
  value?: number;
}
const WaterMark: FC<IProps> = ({ value }) => {
  const handleCheck = useCallback((e: 0 | 1) => {}, []);
  const IndText = useIntlDep();

  return (
    <>
      {/* 添加视频水印 */}
      <FormItem
        label="ADD_VIDEO_WATERMARK"
        name="posterSwitch"
        initialValue={0}
        inline
        tiptype="tip"
        tipText="WHEN_TURNED_ON"
      >
        <CheckButton true={1} false={0} onChange={handleCheck} />
      </FormItem>
      {value === 1 && (
        <>
          <FormItem
            label="WATERMARK_IMAGE"
            name="playMicroLabelAddress"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("posterSwitch") === 1 && !value) {
                    return Promise.reject(
                      new Error(IndText({ id: "WATERMARK_IMAGE_IS_MANDATORY" }))
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            inline
          >
            <UploadPicOnlyOne />
          </FormItem>
          <FormItem
            label="A_B_C"
            labelValues={{
              name1: "CHOOSE",
              name2: "WATERMARK",
              name3: "POSITION_IN_PLAYER",
            }}
            name="microLabelLocation"
            inline
            rules={[{ required: true }]}
            initialValue={1}
          >
            <RadioP />
          </FormItem>
        </>
      )}
    </>
  );
};
export default WaterMark;
