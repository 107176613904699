import useResource from "@/hooks/useResource";
import useVisible from "@/hooks/useVisible";
import React, { FC } from "react";
import { CSSProperties } from "react";
import ModalP from ".";
import { INormalEvent, ITrigger } from "../interface";
// import { INormalEvent } from "../template/interface";

interface IProps {
  id: number;
  title: React.ReactNode;
  submit?: ITrigger;
  okText?: string;
  submitBtns?: INormalEvent[];
  afterClose?: ITrigger;
  size?: "large" | "small" | "extra_large";
  style?: CSSProperties;
  width?: number | string;
  showFotterBtn?: boolean;
  children: React.ReactNode;
}

/**
 * 如果不传submit，则隐藏foot
 * @param props
 */
const ModalID: FC<IProps> = (props) => {
  const [visible, changeVisible] = useVisible();

  useResource(props.id, changeVisible);
  return (
    <ModalP
      title={props.title}
      width={props.width}
      visible={visible}
      showFotterBtn={props.showFotterBtn}
      size={props.size}
      onCancel={changeVisible}
      onSubmit={props.submit}
      okText={props.okText}
      submitBtns={props.submitBtns}
      afterClose={props.afterClose}
    >
      {props.children}
    </ModalP>
  );
};

export default ModalID;
