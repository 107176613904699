import React, { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Redirect } from "@/common/utils/utilsX";
import Login from "@/pages/login";
import LoginPlus from "@/pages/loginPlus";
import { RouteObject } from "../interface";
const LoginRouter: Array<RouteObject> = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/login-plus",
    element: <LoginPlus />,
  },
  {
    path: "*",
    element: <Redirect to="/login" />,
  },
];
const LoginRouterP: FC = () => {
  return useRoutes(LoginRouter);
};
export default LoginRouterP;
