import { useCallback, useEffect, useMemo } from "react";
import { Subject} from "rxjs";
import { throttleTime } from "rxjs/operators";
import { ITrigger } from "@/common/interface";

/**
 * 防抖，1s
 * @param submit
 */
const useSubmitEvent = (submit?: ITrigger) => {
  const event$ = useMemo(() => new Subject(), []);
  useEffect(() => {
    const sub = event$.pipe(throttleTime(1000)).subscribe((a) => {
      submit && submit();
    });
    return () => sub.unsubscribe();
  }, [submit, event$]);

  return useCallback(() => {
    event$.next(true);
  }, [event$]);
};

export default useSubmitEvent;
