// import { filterMenuByAuth } from "@/common/utils/utils";
import useUserDetail from "@/hooks/useUserDetail";
import { useEffect, useState } from "react";
import menuService from "./service";
/**
 * 从deiltel获取当前角色可以使用的menu
 * @returns
 */
const useMenu = () => {
  const [list, setList] = useState(menuService.list$.value);
  const userInfo = useUserDetail();
  useEffect(() => {
    const sub = menuService.list$.subscribe((_list) => {
      if (userInfo) {
        setList(userInfo.permissionTreeNodes);
      }
    });
    return () => sub.unsubscribe();
  }, [userInfo]);

  return list;
};

export default useMenu;
