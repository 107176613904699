
// import makeAsyncScriptLoader from "react-async-script";
  declare global {
  interface Window {
    recaptchaOptions: any; 
  }
}
 export default  window.recaptchaOptions = {
  useRecaptchaNet: true,
};
