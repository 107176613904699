import { INormalEventBindId } from "@/common/template/interface";
import messageService from "@/store/messages";

/**
 * 新增按钮
 */
export const normalBtns: INormalEventBindId = (id) => [
  {
    text: "ADD",
    event() {
      messageService.createEvent(id);
    },
  },
];
