import IntlDep from "@/hooks/intl/IntlDep";
import useUILoading from "@/hooks/intl/useLoading";
import useEventId from "@/hooks/useEventId";
import useIntlDep from "@/hooks/useIntlDep";
import useResourceData from "@/hooks/useResourceData";
import useUserDetail from "@/hooks/useUserDetail";
import messageService from "@/store/messages";
import EInfoType from "@/store/messages/infoType";
import reqServicePlx from "@/store/request/reqServicePlx";
import { useTableStyleSize } from "@/styles";
import { Button, Col, Pagination, Row, Table, Tooltip } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import * as queryString from "query-string";
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BehaviorSubject, from, Subject } from "rxjs";
import { debounceTime, skip, switchMap } from "rxjs/operators";
import CustomSearch from "../customSearch";
import IconTip from "../icon/tip";
import { filterTempByAuth, generateKey, reqAnalysis } from "../utils/utils";
import { ellopsisOnCell, ellopsisRender } from "../utils/utilsX";
import { Filter } from "./components/filters";
import FooterDetail from "./components/footerDetail";
import { createOptList } from "./components/optList";
import "./index.less";
import {
  IBatchEvent,
  IColumnsTypeP,
  IExpandableModule,
  IFilterModule,
  IMapT,
  INormalEvent,
  IOperationConfig,
  IPageResult,
  IQueryModule,
  ISearchPage,
} from "./interface";

interface ITemplateProps {
  /** 自动加载url查询参数功能-静态不可修改 */
  enableAutoLoadQueryParams?: boolean;
  /** 整个组件的标题 */
  title?: string;
  /** 搜索功能 */
  search?: boolean;
  /** 列表数据 */
  config: IColumnsTypeP<any>[];
  /** 表格操作  */
  optList?: IOperationConfig;
  /** 每行数据的key */
  rowId?: string;
  /** 普通按钮-新增等，不受制于表格选择 */
  normalBtns?: INormalEvent[];
  /** 模块id */
  id: number;
  /** 批量选择 */
  batchBtns?: IBatchEvent[];
  /** className */
  removePadding?: boolean;
  /** 中间层 */
  middleNode?: React.ReactNode;
  /** 额外搜索参数 */
  moreFilters?: IMapT<string | number>;
  /** 指定table的size */
  forceStyle?: {
    size: "small" | "large";
    fixHeight?: number;
  };
  isRealTime?: boolean;
  /** 支持在table的页脚插入按钮 */
  footLfEl?: React.ReactNode;
  /** row的className计算值 */
  rowClassName?: string | ((record: any, index?: number) => string);
  /** 数据刷新后的通知, 注意此处因为性能问题，需要函数引用固定 */
  dataCallback?: (dataList: any[], pages: number) => void;
  /** scroll 配合固定头尾列决定 */
  scroll?: {
    x?: number;
    y?: number;
  };
}

interface IPageParams extends ISearchPage {
  keyword: string;
  // searchPage: ISearchPage;
  /** 高级搜索 */
  filters: any;
  moreFilters: any;
  /** 此次刷新是否显示loading */
  __disableLoading?: boolean;
}
const Template: FC<
  ITemplateProps & IQueryModule & IFilterModule & IExpandableModule
> = (props) => {
  const { current: filterInitialValue } = useRef(props.filterInitialValue);
  const { current: forceStyle } = useRef(props.forceStyle);
  const [btnSize = "small", tableSize, defaultPageSize] =
    useTableStyleSize(forceStyle);
  const { current: isRealTime } = useRef(props.isRealTime);
  const { current: dataCallback } = useRef(props.dataCallback);
  // 如果有强制大小限定
  const rowKey = props.rowId;
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [selectRows, setSelectRows] = useState<any[]>([]);
  // 表格loading
  const [loading, open, close] = useUILoading();
  // 表格数据
  const [tableData, setTableData] = useState<any[]>([]);
  // 表格数据流
  const tableData$ = useMemo(() => new Subject<any[]>(), []);

  const [pagination, setPagination] = useState<any>({
    defaultCurrent: 1,
    pageSize: defaultPageSize,
    total: defaultPageSize,
    hideOnSinglePage: true,
  });

  // 过滤id
  const filterIdProps = props.filterId;
  const filterIdGenerate = useEventId();

  const filterId = useMemo(() => {
    return filterIdProps || filterIdGenerate;
  }, [filterIdProps, filterIdGenerate]);

  const { current: initMoreFilters } = useRef(props.moreFilters);
  const moreFilters = props.moreFilters;

  const params$ = useMemo(
    () =>
      new BehaviorSubject<IPageParams>({
        keyword: "",
        page: 1,
        pageSize: defaultPageSize || 10,
        filters: {},
        moreFilters: initMoreFilters || {},
        __disableLoading: false,
      }),
    [defaultPageSize, initMoreFilters]
  );

  // 自动加载搜索条件
  const { current: enableAutoLoadQueryParams } = useRef(
    props.enableAutoLoadQueryParams
  );

  // 拟合初始值和url搜索值
  const mixFilterInitialValue: IMapT<string> | undefined = useMemo(() => {
    // 如果开启自动搜索
    let value = undefined;
    // 加载设置的-默认参数
    if (filterInitialValue) {
      value = { ...filterInitialValue };
    }
    // 加载url查询参数 - 强于默认参数
    const urls = window.location.href.split("?");
    if (enableAutoLoadQueryParams && urls.length > 1) {
      urls.shift();
      const urlStr = urls.join("&");
      const queryParams = queryString.parse(urlStr);
      if (queryParams) {
        const _value: any = { ...queryParams };
        // 处理数字
        for (const key in _value) {
          if (_value.hasOwnProperty(key)) {
            const _number = parseInt(_value[key]);
            if (!isNaN(_number)) {
              _value[key] = _number;
            }
          }
        }
        value = { ...value, ..._value };
      }
    }
    return value;
  }, [filterInitialValue, enableAutoLoadQueryParams]);

  // 如果设置过滤初始值，则跳过默认首次订阅行为
  const params$$ = useMemo(() => {
    return !!mixFilterInitialValue ? params$.pipe(skip(1)) : params$;
  }, [params$, mixFilterInitialValue]);

  const details = useMemo(() => {
    const { current, pageSize, total } = pagination;
    const { _current, _pageSize, _total } = {
      _current: current || 1,
      _pageSize: pageSize || 1,
      _total: total || 0,
    };
    const mul = _current * _pageSize;
    return {
      start: `${mul - _pageSize + 1}`,
      end: mul > _total ? `${total}` : `${mul}`,
      total: `${total}`,
      hide: _total <= 0,
    };
  }, [pagination]);

  const { current: clearSearch } = useRef(() => {
    let _reset: Function | null = null;
    return (reset?: Function) => {
      if (reset) {
        _reset = reset;
      }
      return () => {
        if (_reset) {
          _reset();
        }
      };
    };
  });

  // 搜索框的查询清空事件，通过resetEvent(reset) 注册清空事件，
  // 通过resetEvent()() 执行注册事件
  const { current: resetEvent } = useRef(clearSearch());

  /**
   * 新增搜索条件
   * disableLoading 表示此次刷新是否触发loading
   * */
  const submit = useCallback(
    (value: any, key?: keyof IPageParams, disableLoading = false) => {
      const nextValue = { ...params$.value };
      if (key && key === "moreFilters") {
        nextValue.page = 1;
      }
      // 高级搜索，清空keyWord字段
      if ("filters" in value) {
        // 通知search,清空搜索字段
        resetEvent()();
        // 提交数据清空搜索字段
        nextValue.keyword = "";
      }
      nextValue.__disableLoading = disableLoading;
      const _nextValue = { ...nextValue, ...value };

      params$.next(_nextValue);
    },
    [params$, resetEvent]
  );

  // 更多筛选条件
  useEffect(() => {
    if (initMoreFilters && moreFilters && initMoreFilters !== moreFilters) {
      submit({ moreFilters }, "moreFilters");
    }
  }, [initMoreFilters, moreFilters, submit]);

  // ref 保证引用不会改变
  const { current: queryData } = useRef(props.queryData);
  const { current: queryDataFunction } = useRef(props.queryDataFunction);
  const { current: queryBaseSearchData } = useRef(props.queryBaseSearchData);
  // 查询Promise
  const queryEvent = useCallback(
    (data: any) => {
      if (queryData) {
        const config = queryData(data);
        if (config) {
          return reqServicePlx<IPageResult<any>>(config);
        }
        throw Error("template的queryData请求config为null");
      }
      if (queryBaseSearchData) {
        try {
          const _data = {
            baseSearchForm: {
              page: data.page,
              keyword: data.keyword,
              pageSize: data.pageSize,
            },
            ...data,
          };
          delete _data.keyword;
          delete _data.page;
          delete _data.pageSize;
          const config = queryBaseSearchData(_data);
          if (config) {
            return reqServicePlx<IPageResult<any>>(config);
          }
          throw Error("template的queryBaseSearchData请求config为null");
        } catch (err) {
          throw Error(`template的queryBaseSearchData请求出错,详情为：${err}`);
        }
      }
      if (queryDataFunction) {
        return queryDataFunction(data);
      }
      throw Error("template没有加载数据获取模块");
    },
    [queryData, queryDataFunction, queryBaseSearchData]
  );

  useLayoutEffect(() => {
    const sub = params$$
      .pipe(
        switchMap((params) => {
          if (!params.__disableLoading) {
            open();
          }
          let { filters, moreFilters, ...paramObj } = params;
          paramObj = { ...paramObj, ...filters, ...moreFilters };
          //
          delete paramObj.__disableLoading;
          return from(queryEvent(paramObj));
        })
      )
      .subscribe((data) => {
        try {
          const result = reqAnalysis(data);
          if (result) {
            let resultData: any[] = [];
            let pages = 0;
            // 分页数据
            if (typeof result.pages !== "undefined") {
              setPagination({
                total: result.total,
                current: params$.value.page,
                hideOnSinglePage: true,
                pageSize: params$.value.pageSize,
              });
              resultData = result.records;
              pages = result.pages;
              // 不分页数据
            } else if (Array.isArray(result)) {
              setPagination({
                total: result.length,
                current: 1,
                hideOnSinglePage: true,
                pageSize: result.length,
              });
              resultData = result;
              pages = result.length;
            } else {
              throw Error("数据请求异常！");
            }
            tableData$.next(resultData);
            if (dataCallback) {
              dataCallback(resultData, pages);
            }
          } else {
            throw Error("接口数据请求失败");
          }
        } catch (error) {
          console.error(error);
        }
        if (!params$.value.__disableLoading) {
          close();
        }
      });
    return () => sub.unsubscribe();
  }, [
    params$$,
    params$,
    queryEvent,
    open,
    close,
    tableData$,
    dataCallback,
    rowKey,
  ]);

  useEffect(() => {
    const sub = tableData$.subscribe((resultData) => {
      // 更新选中
      if (rowKey) {
        const ids: string[] = [];
        const rows: any = [];
        selectIds.forEach((id) => {
          const idx = resultData.findIndex((z) => z[rowKey] === id);
          if (~idx) {
            ids.push(id);
            rows.push(resultData[idx]);
          }
        });
        setSelectIds(ids);
        setSelectRows(rows);
      }
      // 转换值
      const _resultData = !!rowKey
        ? resultData
        : generateKey(resultData, "row_key");
      setTableData(_resultData);
    });
    return () => sub.unsubscribe();
  }, [selectIds, rowKey, tableData$, props]);

  // 表格onchange事件
  const tableOnChange = useCallback(
    (
      page: TablePaginationConfig,
      _: any,
      sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
      const st = Array.isArray(sorter) ? sorter[0] : sorter;
      const searchPageOld = params$.value;
      const searchPage = {
        desc: st.order === "ascend" ? 0 : 1,
        page: searchPageOld.page,
        pageSize: searchPageOld.pageSize,
        sort: !!st.order ? st.field || "" : "",
      };
      submit(searchPage);
    },
    [submit, params$]
  );

  // 分页修改
  const pageOnChange = useCallback(
    (page: number, pageSize?: number | undefined) => {
      // 当前值
      const searchPage = { ...params$.value };
      searchPage.page = page;
      searchPage.pageSize = pageSize || searchPage.pageSize;
      submit(searchPage);
    },
    [params$, submit]
  );

  const userInfo = useUserDetail();

  const tableRowConfig: any = useMemo(() => {
    let _config = props.config;
    if (userInfo) {
      _config = filterTempByAuth(props.config, userInfo?.roleCodes);
    }
    const conf = _config.map((cof) => {
      const _cof: any = { ...cof };
      if (typeof _cof.title === "string" && !cof.tipMessage) {
        _cof.title = <IntlDep id={cof.title} />;
      }
      if (typeof _cof.title === "string" && cof.tipMessage) {
        _cof.title = (
          <span>
            <IntlDep id={cof.title} />
            <Tooltip title={<IntlDep id={cof.tipMessage} />}>
              <span>
                <IconTip />
              </span>
            </Tooltip>
          </span>
        );
      }
      if (!_cof.render) {
        _cof.render = ellopsisRender;
      }
      if (!_cof.onCell) {
        _cof.onCell = ellopsisOnCell;
      }
      return _cof;
    });
    if (!props.optList || !userInfo) {
      return conf;
    }
    // 使用权限对操作进行过滤
    const optList: any[] = [];
    props.optList.forEach((item) => {
      // if (userInfo.roleCodes.includes(IUserType.CUSTOMER)) {  暂时保留
      //   //客户权限设置
      //   optList.push(item);
      // } else {
      optList.push(item);
      // }
    });
    if (optList.length < 1) {
      return conf;
    }
    const optConf = createOptList(optList);
    optConf && conf.push(optConf as any);
    return conf;
  }, [props, userInfo]);

  const reloadTable = useCallback(() => {
    if (params$.value.__disableLoading) {
      params$.next({
        ...params$.value,
        __disableLoading: false,
      });
    } else {
      params$.next(params$.value);
    }
  }, [params$]);

  useResourceData(EInfoType.list_reload, props.id, reloadTable);

  // 实时刷新
  useEffect(() => {
    if (isRealTime) {
      const sub = tableData$.pipe(debounceTime(5000)).subscribe(() => {
        // messageService.reloadList(id);
        params$.next({
          ...params$.value,
          __disableLoading: true,
        });
      });
      return () => sub.unsubscribe();
    }
  }, [tableData$, params$, isRealTime]);

  const batchBtnsProps = props.batchBtns;
  const rowSelection = useMemo(() => {
    if (Array.isArray(batchBtnsProps) && batchBtnsProps.length > 0) {
      return {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
          setSelectIds(selectedRowKeys);
          setSelectRows(selectedRows);
        },
      };
    }
  }, [batchBtnsProps]);

  // 行展开
  const expandable = useMemo(() => {
    if (props.expandedRowRender || props.rowExpandable) {
      return {
        expandedRowRender: props.expandedRowRender,
        rowExpandable: props.rowExpandable,
      };
    }
  }, [props.expandedRowRender, props.rowExpandable]);

  const loadText = useIntlDep();

  // 批量操作
  const batchEvent = useCallback(
    (event: IBatchEvent) => {
      const text = loadText({
        id: event.description.text,
        values: event.description.textValue,
      });
      const trigger = function () {
        const params = event.generateParams(selectIds, selectRows);
        if (!params) {
          return null;
        }
        from(reqServicePlx(params)).subscribe((res) => {
          if (res.isSuccess) {
            // 刷新表格
            if (!event.disableAutoReload) {
              reloadTable();
            }
            // 检查最终回调
            event.callback && event.callback();
          }
        });
      };
      messageService.confirm(text, trigger);
    },
    [selectIds, selectRows, reloadTable, loadText]
  );

  // 普通操作和批量操作按钮
  const btns: React.ReactNode[] = [];
  // 高级搜索
  if (props.filter && !props.filterHide) {
    btns.push(
      <Button
        size={btnSize}
        onClick={() => messageService.createEvent(filterId)}
        key="tmp-filters"
      >
        <IntlDep id="FILTER_TITLE" />
      </Button>
    );
  }
  // 普通操作
  if (props.normalBtns && props.normalBtns.length > 0) {
    props.normalBtns.map((btn) => {
      const _btn = !btn.hide && (
        <Button key={btn.text} size={btnSize} onClick={btn.event}>
          <IntlDep id={btn.text} values={btn.textValue} />
        </Button>
      );
      if (_btn) {
        btns.push(_btn);
      }
      return true;
    });
  }
  if (props.batchBtns && props.batchBtns.length > 0) {
    props.batchBtns.map((btn) => {
      const _btn2 = (
        <Button
          disabled={selectIds.length < 1}
          key={btn.text}
          size={btnSize}
          onClick={() => batchEvent(btn)}
        >
          <IntlDep id={btn.text} values={btn.textValue} />
        </Button>
      );
      if (_btn2) {
        btns.push(_btn2);
      }
      return true;
    });
  }

  // 显示分页跳转
  const showQuickJumper = useMemo(() => {
    if (pagination.total && pagination.pageSize) {
      return pagination.total > pagination.pageSize * 6;
    }
    return false;
  }, [pagination]);

  return (
    <section className="comp-template">
      {props.title && (
        <p className={!props.removePadding ? "stream-page-row" : undefined}>
          <IntlDep id={props.title} />
        </p>
      )}
      {props.search && (
        <div
          className={`bottom-15 ${
            !props.removePadding ? "stream-page-row" : undefined
          }`}
        >
          <CustomSearch
            keyName="keyword"
            buttonName="SEARCH_QUERY"
            submit={(data) => submit({ keyword: data.keyword })}
            otherBtns={btns}
            reset={resetEvent}
          />
        </div>
      )}
      {props.filter && (
        <Filter
          id={filterId}
          configs={props.filter}
          initialValue={mixFilterInitialValue}
          submit={(filters) => {
            submit({ filters: filters });
          }}
        />
      )}
      {props.middleNode}
      <Table
        size={tableSize}
        className={!props.removePadding ? "stream-page-row" : undefined}
        pagination={false}
        showSorterTooltip={false}
        columns={tableRowConfig}
        dataSource={tableData}
        // 实时刷新禁用loading图标
        loading={loading}
        rowKey={props.rowId || "row_key"}
        rowSelection={rowSelection}
        expandable={expandable}
        onChange={tableOnChange}
        rowClassName={props.rowClassName}
        scroll={props.scroll}
      />
      <div
        style={{ marginTop: 8 }}
        className={!props.removePadding ? "stream-page-row" : undefined}
      >
        <Row align="middle">
          <FooterDetail {...details} />
          <Col flex={1}>{!!props.footLfEl && props.footLfEl}</Col>
          <Col>
            <Pagination
              onChange={pageOnChange}
              {...pagination}
              size={tableSize === "small" ? "small" : "default"}
              showQuickJumper={showQuickJumper}
              showSizeChanger
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Template;
