// import { AsyncSelect } from "@/common/fastRender/select";
import { transformFlow } from "@/common/echarts/util";
import IconFont from "@/common/icon";
import { IFormComponent } from "@/common/interface";
import { reqAndRunCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail, { useIsAdmin } from "@/hooks/useUserDetail";
import userAPI from "@/store/apis/account/user";
// import { IUserType } from "@/store/account/interface";
// import userAPI from "@/store/apis/account/user";
import transcodingGroupAPI from "@/store/apis/node/transcodingGroup";
import vodTypeAPI from "@/store/apis/node/vodType";
import reqServicePlx from "@/store/request/reqServicePlx";
import { Button, Table, Upload } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { forkJoin } from "rxjs";
import FormItem from "../FormItem/item";
import SelectP from "../SelectP";
import "./uploadVideo.less";
interface IProps {
  multiple?: boolean;
  accept?: string;
}
const UploadFileComponent: FC<IFormComponent & IProps> = ({
  onChange,
  accept,
}) => {
  const [fileTable, setFileTable] = useState<any[]>([]);
  const userInfo = useUserDetail();
  const [customerList, setCustomerList] = useState<any[]>();
  const textDep = useIntlDep();
  const isAdmin = useIsAdmin();
  // const [userId, setUserId] = useState<any>();
  const [userSelectId, setUserSelectId] = useState<any>();
  useEffect(() => {
    if (userInfo) {
      if (isAdmin) {
        const config = userAPI.findAll({}, {});
        reqAndRunCallback(config, (res) => {
          if (Array.isArray(res)) {
            // if (res.length) {
            //   setUserSelectId(res[0].id);
            // }
            setCustomerList(res);
          }
        });
      } else {
        setUserSelectId(userInfo.id);
      }
    }
  }, [isAdmin, userInfo]);

  const [transcodingList, setTranscodingList] = useState<any[]>([]);
  const [vodTypeList, setVodTypeList] = useState<any[]>([]);
  const [defaultVodType, setDefaultVodType] = useState<string>("");
  useEffect(() => {
    if (userSelectId) {
      forkJoin([
        reqServicePlx<any[]>(
          transcodingGroupAPI.FindList({ userId: userSelectId }, {})
        ),
        reqServicePlx<any[]>(vodTypeAPI.FindList({ userId: userSelectId }, {})),
      ]).subscribe((resArr) => {
        //获取转码组
        if (resArr[0].isSuccess && resArr[0].result) {
          const defaultId = resArr[0].result[0].id;
          setDefaultVodType(defaultId);
          const spliceArr = resArr[0].result;
          const TranscodingGList = spliceArr.filter(
            (item) => item.id !== defaultId
          );
          setTranscodingList([resArr[0].result[0], ...TranscodingGList]);
        }
        // 获取分类列表
        if (resArr[1].isSuccess && resArr[1].result) {
          setVodTypeList([{ id: 0, name: "默认分类" }, ...resArr[1].result]);
        }
      });
    }
  }, [userSelectId]);
  // 禁止默认的上传行为
  const beforeUpload = useCallback(
    (data: { name: any; type: any; uid: any; size: any }) => {
      if (!userInfo) {
        return false;
      }
      const { name, type, uid, size } = data;
      const format = name.split(".").at(-1);
      setFileTable((list) =>
        list.concat([
          {
            name,
            type: type ? type : `video/${format}`,
            uid,
            size,
            file: data,
          },
        ])
      );
      return false;
    },
    [userInfo]
  );

  // 移除
  const removeFile = useCallback((data: { uid: any }) => {
    setFileTable((list) => list.filter((file) => file.uid !== data.uid));
  }, []);

  useEffect(() => {
    onChange && onChange(fileTable);
  }, [onChange, fileTable]);

  const changeVidoeType = useCallback(
    (text: any, rowData: { uid: any }) => {
      fileTable.forEach((item) => {
        if (item.uid === rowData.uid) {
          item.vodTypeId = text;
        }
      });
    },
    [fileTable]
  );
  const changeTranscoder = useCallback(
    (text: any, rowData: { uid: any }) => {
      fileTable.forEach((item) => {
        if (item.uid === rowData.uid) {
          item.groupId = text;
        }
      });
    },
    [fileTable]
  );
  const columns = [
    {
      key: "name",
      title: textDep({ id: "NAME" }),
      dataIndex: "name",
      width: 100,
    },
    {
      key: "type",
      title: textDep({ id: "FORMAT" }),
      dataIndex: "type",
    },
    {
      key: "size",
      title: textDep({ id: "VIDEO_FORMAT" }),
      dataIndex: "size",
      render:(num:number)=>transformFlow(num,1024)
    },
    {
      key: "name",
      title: textDep({ id: "TRANSCODING_GROUP" }),
      dataIndex: "name",
      render: (_t: string, row: any) => {
        return (
          <FormItem initialValue={defaultVodType} name="groupId" noStyle>
            <SelectP
              data={transcodingList}
              // defaultValue={defaultVodType}
              style={{ width: 150 }}
              onChange={(e) => changeTranscoder(e, row)}
            />
          </FormItem>
        );
      },
    },
    {
      key: "name",
      title: textDep({ id: "VIDEO_CATEGORIES" }),
      dataIndex: "name",
      render: (_t: string, row: any) => (
        <FormItem initialValue={0} name="vodTypeId" noStyle>
          <SelectP
            data={vodTypeList}
            style={{ width: 150 }}
            onChange={(e) => changeVidoeType(e, row)}
          />
        </FormItem>
      ),
    },
    {
      title: textDep({ id: "OPERATION" }),
      key: "action",
      render: (_: any, row: any) => (
        <span
          onClick={() => {
            removeFile(row);
          }}
          style={{ cursor: "pointer" }}
        >
          <IconFont type="icona-Component3447" />
        </span>
      ),
    },
  ];

  return (
    <section>
      {isAdmin && customerList && (
        <FormItem
          hidden={fileTable.length !== 0}
          name="userId"
          // initialValue={userSelectId}
          label="SELECT_CUSTOMER"
        >
          <SelectP
            // onChange={setUserSelectId}
            data={customerList}
          />
        </FormItem>
      )}
      {fileTable.length === 0 ? (
        <div className="upload-video-copm">
          <p>support: mp4、flv、avi、rmvb、ts、mkv、mov、m4v、rm、wmv</p>
          <div style={{ textAlign: "center" }}>
            <Upload fileList={[]} multiple={false} beforeUpload={beforeUpload} 
              accept='.wmv,.asf,.asx,.rm,.rmvb,.mp4,.3gp,.mov,.m4v,.avi,.dat,.mkv,.flv,.vob,.qlv,.ts'
            >
              <Button type="primary">
                <IconFont
                  type="iconic_cloud_upload_24px"
                  style={{
                    position: "relative",
                    fontSize: 18,
                  }}
                />
                <IntlDep id="UPLOAD_A_VIDEO" />
              </Button>
            </Upload>
          </div>
        </div>
      ) : (
        <div>
          {/* <div>
            <Upload fileList={[]} multiple={false} beforeUpload={beforeUpload}>
              <Button type="primary">
                <IconFont
                  type="iconic_cloud_upload_24px"
                  style={{
                    position: "relative",
                    fontSize: 18,
                  }}
                />
                <IntlDep id="UPLOAD_A_VIDEO" />
              </Button>
            </Upload>
          </div> */}
          <Table
            rowKey={(record) => record.uid}
            pagination={false}
            columns={columns}
            dataSource={fileTable}
          />
        </div>
      )}
    </section>
  );
};

export default UploadFileComponent;
