import useUserDetail from "@/hooks/useUserDetail";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getMenuUri } from "../utils/utils";
import BeforRouter from "./BeforRouter";
// 无需过滤的路由
const noNeedAuthList: Array<string> = [
  "/login",
  "/login-plus",
  "/guide-overview",
];
const AuthRouter = (props: { children: JSX.Element }) => {
  const userInfo = useUserDetail();
  const [urlList, setUrlList] = useState<Array<string>>();
  const { pathname } = useLocation();
  // const menus = useMenu();
  useEffect(() => {
    if (userInfo) {
      setUrlList([
        ...getMenuUri(userInfo.permissionTreeNodes),
        "/",
        "/login",
        "/login-plus",
      ]);
    }
  }, [userInfo]);

  if (noNeedAuthList.includes(pathname)) {
    return props.children;
  }
  if (!urlList) {
    return null;
  }
  const beforeEachChange = (to: any, next: any, from: any) => {
    // console.log(urlList);
    if (urlList.indexOf(pathname) === -1) {
      if (!from || to.path === from?.path) {
        next("/live-management");
      } else {
        next(from?.path);
      }
    } else {
      next();
    }
  };
  return (
    <BeforRouter beforeEach={beforeEachChange}>{props.children}</BeforRouter>
  );
};
export default AuthRouter;
