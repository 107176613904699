import { useMemo, useState } from "react";
/**
 * @returns silder的宽度
 */
export const useSideWidth = () => {
  const [width] = useState(335);
  return width;
};


/**
 * 带有边框的实际内容宽度
 * */
 export const useWidthWithSide = (width=30) => {
  const sideWidth = useSideWidth();

  return useMemo(() => {
      return 1920 - sideWidth - width;
  }, [sideWidth, width])
}