import GlobalComp from "@/common/confirm";
import ErrorPage from "@/pages/error";
import Routers from "@/router";
import "@/styles/index.less";
import { Component, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { unstable_HistoryRouter as Router } from "react-router-dom";
import useLanguage, { languagePkg } from "./locale";
import historyService from "./store/history";
import useWebSiteInfo from "./store/webSiteInfo/useWebSiteInfo";
// import AuthRouter from "./common/helpers/authRouter";
const rootTitle = document.getElementById("root-title");
const rootFavicon = document.getElementById("root-favicon") as any;

function App() {
  const [languagePackage] = useLanguage();
  const webSiteInfo = useWebSiteInfo();
  useEffect(() => {
    if (webSiteInfo && rootTitle && rootFavicon) {
      rootTitle.innerText = webSiteInfo.siteName;
      rootFavicon.href = webSiteInfo.faviconAddress;
    }
  });
  if (!languagePackage && !languagePkg) {
    return null;
  }
  return (
    <Router history={historyService}>
      <IntlProvider messages={languagePackage} locale="en">
        <GlobalComp />
        <Routers />
      </IntlProvider>
    </Router>
  );
}

class AppContainer extends Component {
  state = {
    isError: false,
  };
  render() {
    if (this.state.isError) {
      return <ErrorPage />;
    }
    return <App />;
  }
  componentDidCatch() {
    this.setState({
      isError: true,
    });
  }
}

export default AppContainer;
