import { AxiosRequestConfig } from "axios";

class VersionAPI {
  setSystemVersion = (params: {}, data: IversionForm) => {
    const config: AxiosRequestConfig = {
      url: "/version/set_system_version",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  getSystemVersion = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/version/get_system_version",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
}
const versionAPI = new VersionAPI();
export default versionAPI;

/** list的请求参数*/
interface IversionForm {
  version: string;
}
