import moment from "moment";

/**
 * 处理form表单提交数据
 * @param: data 直播数据
 * @return: 返回匹配form表单提交接口的对象
 */
export const formatData = (data: any) => {
  const id = data.liveId;
  Object.getOwnPropertyNames(data).forEach((key: string) => {
    if (
      [
        "description",
        "microLabelAddress",
        "shareIconSwitch",
        "title",
        "backgroundAddress",
      ].includes(key)
    ) {
      let obj = { [key]: data[key] };
      data.hostingForm = {
        ...data.hostingForm,
        ...obj,
      };
      delete data[key];
    }
    if (
      [
        "countdownDate",
        "countdownSwitch",
        "countdownTimezone",
        "microLabelLocation",
        "posterAddress",
        "posterSwitch",
        "type",
        "wideType",
        "wideValue",
        "playMicroLabelAddress",
        "heightValue",
      ].includes(key)
    ) {
      let obj = { [key]: data[key] };
      data.playerForm = {
        ...data.playerForm,
        ...obj,
      };
      if (key === "countdownSwitch" && typeof key === "object") {
        const _countdownSwitch = { ...data[key] };
        data.playerForm = {
          ...data.playerForm,
          ..._countdownSwitch,
        };
      }
      if (key === "playMicroLabelAddress") {
        let obj = {
          microLabelAddress: data[key],
        };
        data.playerForm = {
          ...data.playerForm,
          ...obj,
        };
        delete data.playerForm.playMicroLabelAddress;
      }
      if (
        key === "wideValue" &&
        data.playerForm.wideType === 1 &&
        data["aspectRatio"]
      ) {
        let obj = {
          heightValue: calculateWidth(
            data.playerForm.wideValue,
            data["aspectRatio"]
          ),
        };
        data.playerForm = {
          ...data.playerForm,
          ...obj,
        };
      }
      delete data[key];
    }
    data.id = id;
    data.hlsSwitch = 1;
  });
  return data;
};
export const calculateWidth = (heightValue: number, proportion: string,symbol:string="X") => {
  const proportion1 = parseInt(proportion.split(symbol)[0]);
  const proportion2 = parseInt(proportion.split(symbol)[1]);
  const height = Math.ceil(heightValue / (proportion1 / proportion2));
  if (height<240) {
    return 240;
  }
  return height;
};

// 接口获取到的元素，转为表单元素
export const dataformat = (res: any, data: any = {}): any => {
  const { hostingEntity, liveEntity, playerEntity } = res;

  playerEntity.playMicroLabelAddress = playerEntity.microLabelAddress;
  delete playerEntity.microLabelAddress;
  liveEntity.pushStreamingDomain = res.pushStreamingDomain;
  liveEntity.pullStreamingDomain = res.streamingDomainName;
  const _data = {
    ...hostingEntity,
    ...liveEntity,
    ...playerEntity,
    ...data,
  };
  if (_data.createDate) {
    _data.createDate = moment(_data.createDate);
  }
  if (_data.countdownDate) {
    _data.countdownDate = moment(_data.countdownDate);
  }
  return _data;
};
