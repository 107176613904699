import IconFont from "@/common/icon";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { IMenuLv2 } from "../interface";
import CompMenu from "./menu";
import { RightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import IntlDep from "@/hooks/intl/IntlDep";

interface IProps {
  menu: IMenuLv2;
  isSelected: (menu: IMenuLv2) => boolean;
}
const SubMenu: FC<IProps> = (props) => {
  const [open, setOpen] = useState<boolean | null>(null);
  const [childIselect, dispatch] = useReducer(
    (_: any, action: any) => (action === "selected" ? true : false),
    false
  );

  const selected = useMemo(() => {
    if (open === null) {
      return props.menu.childTreeNodeList.some((it) => props.isSelected(it));
    }
    return open;
  }, [open, props]);
  useEffect(() => {
    const selected = props.menu.childTreeNodeList
      .map((item) => {
        return props.isSelected(item);
      })
      .includes(true);
    if (selected) {
      dispatch("selected");
    } else {
      dispatch("dsa");
    }
  }, [props]);
  const clickEvent = useCallback(() => {
    setOpen(!selected);
  }, [selected]);

  return (
    <ol className="silder-submenu-pat">
      <li
        onClick={clickEvent}
        style={childIselect ? { fontWeight: 500 } : {}}
        className={childIselect ? "selected" : ""}
      >
        <Row
          wrap={false}
          style={{ overflow: "hidden" }}
          justify="start"
          align="middle"
        >
          {props.menu.icon && (
            <Col span={7}>
              <div className="menu-icon-rd">
                <div className="stream-side-icon-bg">
                  <span></span>
                </div>
                <IconFont
                  style={{ fontSize: 18 }}
                  type={props.menu.icon}
                  className="str-icon"
                />
              </div>
            </Col>
          )}
          <Col span={14}>
            <span>
              {props.menu.language ? <IntlDep id={props.menu.language} /> : ""}
            </span>
          </Col>
          <Col span={3}>
            <RightOutlined
              style={{ fontSize: 14 }}
              className="icon"
              rotate={selected ? 90 : 0}
            />
          </Col>
        </Row>
      </li>
      {selected &&
        props.menu.childTreeNodeList.map((child, idx) => {
          return (
            <Fragment key={idx}>
              {child.language && (
                <div key={child.routeAddress} style={{ paddingLeft: 90 }}>
                  <CompMenu
                    key={child.routeAddress}
                    {...child}
                    selectd={props.isSelected(child)}
                  />
                </div>
              )}
            </Fragment>
          );
        })}
    </ol>
  );
};

export default SubMenu;
