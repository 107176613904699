import { ISearchPage } from "@/common/template/interface";
import { AxiosRequestConfig } from "axios";
import { IVodTypeForm } from "./interface";

class VodTypeAPI {
  /* 点播视频类型列表 */
  FindPage = (params: IFindPageParams, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/vod/type/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 点播分类新增 */
  Create = (params: IVodTypeCreateParams, data: IVodTypeForm) => {
    const config: AxiosRequestConfig = {
      url: "/vod/type/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /* 点播分类详情 */
  Detail = (params: IVodTypeDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/vod/type/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /* 点播分类修改 */
  Update = (params: IVodTypeUpdateParams, data: IVodTypeForm) => {
    const config: AxiosRequestConfig = {
      url: "/vod/type/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * FindList
   * 生成请求参数
   */
  FindList = (params: IVodTypeListParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/vod/type/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * delete
   * 生成请求参数
   * 获取当前用户点播域名个数
   */
  Delete = (params: IVodTypeDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/vod/type/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
}
const vodTypeAPI = new VodTypeAPI();
export default vodTypeAPI;

interface IFindPageParams {}
interface IVodTypeDetailParams {
  id: number;
}
interface IVodTypeDeleteParams {
  id: number;
}
interface IVodTypeUpdateParams {}
interface IVodTypeCreateParams {}
interface IVodTypeListParams {
  userId?:number
}
