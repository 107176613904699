import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

/**
 * useUnmounted
 * @returns boolean类型
 * 组件是否被卸载
 */
export const useUnmounted = () => {
  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);
  return unmountedRef.current;
};
/**
 * @method useAsyncState
 * 防止对已卸载组件进行React状态更新。
 */
export const useAsyncState = <S>(
  initialState?: S | (() => S)
): [S | undefined, Dispatch<SetStateAction<S>>] => {
  const unmountedRef = useUnmounted();
  const [state, setState] = useState(initialState);
  const setAsyncState = useCallback(
    (s: any) => {
      if (unmountedRef) return;
      setState(s);
    },
    [unmountedRef]
  );
  return [state, setAsyncState];
};
