import {
  ICallback,
  IDisplayText,
  IModule,
  ISelectItem,
} from "@/common/interface";
import useResource from "@/hooks/useResource";
import useOpen from "@/hooks/useOpen";
import { Col, Form, Row, Tag } from "antd";
import ModalP from "@/common/modal";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fastRender, IRenderConfig } from "@/common/fastRender";
import { IMapT } from "../interface";
import { FormattedMessage } from "react-intl";
import pageCacheService from "@/resource/page";
import { useForm } from "antd/lib/form/Form";
import {
  filterTempByAuth,
  queryValueFromList,
} from "@/common/utils/utils";
import { Subject } from "rxjs";
import useAsyncDicts from "@/hooks/useAsyncDict";
import IntlDep from "@/hooks/intl/IntlDep";
import useIntlDep from "@/hooks/useIntlDep";
import toolService from "@/store/request/tool";
import useUserDetail from "@/hooks/useUserDetail";

export interface IFilter {
  /** 提示 */
  configs: IRenderConfig[];
  /** 提交事件 */
  submit: ICallback<any>;
  /** 初始值 */
  initialValue?: IMapT<number | string>;
}

const logger = toolService.createDebug(true, "高级搜索");

/**
 * 重构
 * 使用订阅机制
 * data
 * config
 * formData
 * @param props
 */
export const Filter: FC<IFilter & IModule> = (props) => {
  // 缓冲调度模块
  const [cacheStore, addKeyToAsyncStore] = useAsyncDicts();
  const loadText = useIntlDep();
  const userInfo = useUserDetail();
  // 提交
  const { configs, submit: submitProps, initialValue } = props;

  const { current: formInitialValue } = useRef(initialValue);
  const _configs = useMemo(() => {
    if (userInfo) {
      return filterTempByAuth(configs, userInfo?.roleCodes);
    }
    return configs;
  }, [configs,userInfo]);
  const submit = useCallback(
    (data: any) => {
      const _data = { ...data };
      // 当主属性未填写时，忽略准确搜索属性
      _configs.forEach((config) => {
        if (config.exactKey) {
          if (typeof _data[config.name] === "undefined") {
            if (_data[config.exactKey]) {
              delete _data[config.exactKey];
            }
          }
        }
      });
      if (Array.isArray(_data.timer)) {
        const timer: [moment.Moment, moment.Moment] = _data.timer;
        _data.startDate = timer[0].format("YYYY/MM/DD");
        _data.endDate = timer[1].format("YYYY/MM/DD");
        delete _data.timer;
      }
      logger("正在提交过滤条件：", _data);
      submitProps(_data);
    },
    [submitProps, _configs]
  );

  const submitData$ = useMemo(() => new Subject<IMapT<any>>(), []);
  useEffect(() => {
    const sub = submitData$.subscribe((res) => {
      // nodename ,nodeName 自动转成nodeName
      const value = { ...res };
      if (res.nodename) {
        value.nodeName = res.nodename;
        delete value.nodename;
      }
      submit(value);
    });
    return () => sub.unsubscribe();
  }, [submitData$, submit]);

  const [formData] = useForm();
  const [visible, open, close] = useOpen();

  // 表单数据
  const [filterFormData, setFilterFormData] = useState<IMapT<any>>({});

  // 展示数据
  const displayData: Array<IDisplayText & { name: string }> = useMemo(() => {
    const lables: Array<IDisplayText & { name: string }> = [];
    _configs.forEach((config) => {
      const {
        name,
        text,
        textValue,
        defaultValue,
        config: selectConfig,
      } = config;
      const value = filterFormData[name];
      if (value && value !== defaultValue) {
        let label: IDisplayText & { name: string };
        switch (config.type) {
          case "select":
            const _text = queryValueFromList(config.data, value, selectConfig);
            label = {
              text: {
                text,
                textValue,
              },
              value: loadText({ id: _text }),
              name: config.name,
            };
            break;
          case "select-async":
            let options: ISelectItem[] = pageCacheService.read(
              config.listcacheid
            );
            // 将选项加载到缓冲区
            if (!options) {
              // 自动缓冲开启
              pageCacheService.setItem(
                config.listcacheid,
                config.queryConfig({}, {}),
                true
              );
              addKeyToAsyncStore(config.listcacheid);
              options = cacheStore[config.listcacheid];
            }
            label = {
              text: {
                text,
                textValue,
              },
              value: !!options
                ? queryValueFromList(options, value, selectConfig)
                : value,
              name: config.name,
            };
            break;
          case "timer":
            const timer: [moment.Moment, moment.Moment] = value;
            label = {
              text: {
                text,
                textValue,
              },
              value: `${timer[0].format("YYYY/MM/DD")}至${timer[1].format(
                "YYYY/MM/DD"
              )}`,
              name: config.name,
            };
            break;
          default:
            label = {
              text: {
                text,
                textValue,
              },
              value: value || value.toString(),
              name: config.name,
            };
        }
        lables.push(label);
        // 附加准确搜索, 并且开启
        if (config.exactKey && filterFormData[config.exactKey]) {
          lables.push({
            text: {
              text: "EXACT_SEACH_A",
              textValue: {
                _name: loadText({
                  id: label.text.text,
                  values: label.text.textValue,
                }),
              },
            },
            value: loadText({ id: "YES" }),
            name: config.exactKey,
          });
        }
      }
    });
    return lables;
  }, [filterFormData, _configs, loadText, cacheStore, addKeyToAsyncStore]);

  // 打开高级搜索，同步当前过滤条件
  const openEvent = useCallback(() => {
    formData.setFieldsValue(filterFormData);
    open();
  }, [open, filterFormData, formData]);
  useResource(props.id, openEvent);

  const submitEvent = useCallback(() => {
    const newValue = formData.getFieldsValue();
    if (newValue) {
      Object.keys(newValue).forEach((key) => {
        if (typeof newValue[key] === "undefined") {
          delete newValue[key];
        }
      });
    }
    setFilterFormData(newValue);
    submitData$.next(newValue);
    close();
  }, [formData, submitData$, close]);

  //
  useEffect(() => {
    if (initialValue) {
      // 延迟加载防止订阅流程未加载完毕
      setTimeout(() => {
        setFilterFormData(initialValue);
        submitData$.next(initialValue);
      }, 80);
    }
  }, [initialValue, submitData$, formData]);

  const removeFilter = useCallback(
    (key: string) => {
      const newValue = { ...filterFormData };
      delete newValue[key];
      setFilterFormData(newValue);
      submitData$.next(newValue);
    },
    [filterFormData, submitData$]
  );

  return (
    <div className="stream-page-row">
      <ModalP
        title={<IntlDep id="FILTER_TITLE" />}
        visible={visible}
        onCancel={close}
        onSubmit={submitEvent}
        destroyOnClose={false}
        afterClose={() => formData.resetFields()}
        size="small"
      >
        <div className="comp-advanced-search">
          <Form
            form={formData}
            layout="vertical"
            initialValues={formInitialValue}
          >
            {_configs.map((config) => fastRender(config))}
          </Form>
        </div>
      </ModalP>
      {displayData.length > 0 && (
        <div style={{ padding: "10px 0" }}>
          <Row>
            <Col>
              <FormattedMessage id="SELECTED" />:
            </Col>
            <Col style={{ paddingLeft: "8px" }}>
              {displayData.map((filter) => (
                <Tag
                  key={filter.name}
                  closable
                  color="default"
                  onClose={() => removeFilter(filter.name)}
                >
                  <IntlDep
                    id={filter.text.text}
                    values={filter.text.textValue}
                  />
                  :{filter.value}
                </Tag>
              ))}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
