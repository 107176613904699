// import SelectP from "@/common/select";
import {
  ICallback,
  IRoleAuth,
  ISelectItem,
  ISelectOptionConfig
} from "@/common/interface";
// import { AsyncSelect } from "@/common/async/select";
import { IMap } from "@/common/template/interface";
import IntlDep from "@/hooks/intl/IntlDep";
import { Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";
import { XOR } from "ts-xor";
import FormCheckBox from "../form/checkbox/checkbox";
import FormItem from "../form/FormItem/item";
// import FormItem from "antd/lib/form/FormItem";
import SelectP from "../form/SelectP";
import { AsyncSelect } from "./select";
// import IntlDep from "@/common/intl";
// import FormItem from "../form/item";
// import FormCheckBox from "../formComponents/checkbox";

const { RangePicker } = DatePicker as any;

export const fastRender = (config: IRenderConfig, size?: any) => {
  if (
    ![
      "input",
      "textArea",
      "checkBox",
      "checkButton",
      "select",
      "timer",
      "select-async",
    ].includes(config.type)
  ) {
    return null;
  }
  let el = null;
  switch (config.type) {
    case "input":
      el = <Input size={size} placeholder={config.placeholder} />;
      break;
    case "select":
      el = (
        <SelectP
          size={size}
          emptyOption
          placeholder={config.placeholder}
          data={config.data}
          listcacheid={config.listcacheid}
          config={config.config}
          enableintl
          onChange={config.onChange}
        />
      );
      break;
    case "select-async":
      el = (
        <AsyncSelect
          listcacheid={config.listcacheid}
          size={size}
          emptyOption
          placeholder={config.placeholder}
          queryConfig={config.queryConfig}
          config={config.config}
          onChange={config.onChange}
        />
      );
      break;
    case "timer":
      el = <RangePicker size={size} />;
      break;
    case "textArea":
      el = (
        <Input.TextArea
          placeholder={config.placeholder}
          rows={config.rows || 2}
        />
      );
      break;
    default:
      el = <Input type="checkbox" />;
  }

  // nodeName 在dom中是一个不可修改属性名
  const name = config.name === "nodeName" ? "nodename" : config.name;

  const result = (
    <Form.Item
      key={name}
      name={name}
      hidden={config.hide}
      label={
        !!config.text && <IntlDep id={config.text} values={config.textValue} />
      }
    >
      {el}
    </Form.Item>
  );

  //精确搜索
  if (config.exactKey) {
    return (
      <Row gutter={40} key={config.name} align="middle">
        <Col span={16}>{result}</Col>
        <Col
          span={8}
          style={{
            paddingTop: 8,
          }}
        >
          <FormItem noStyle name={config.exactKey}>
            <FormCheckBox>
              <IntlDep id="PRECISE_SEARCH" />
            </FormCheckBox>
          </FormItem>
        </Col>
      </Row>
    );
  }
  return result;
};

interface IBasic {
  /** 属性key, 表单提交属性 */
  name: string;
  /** 属性文案 */
  text: string;
  /** 属性附加 */
  textValue?: IMap;
  /** 默认值 */
  defaultValue?: string | number;
  /** 隐藏域 */
  hide?: boolean;
  /** 精确选择部分 */
  exactKey?: string;
}

/**
 * 输入框
 */
interface IInput {
  type: "input";
  /** 数字 */
  number?: boolean;
  placeholder?: string;
}

interface ITextArea {
  type: "textArea";
  rows?: number;
  placeholder?: string;
}

type IItem1 = XOR<IInput, ITextArea>;

/**
 * checkbox
 */
interface ICheckBox {
  type: "checkBox";
}
type IItem2 = XOR<IItem1, ICheckBox>;

/**
 * 按钮
 */
interface ICheckButton {
  type: "checkButton";
}
type IItem3 = XOR<IItem2, ICheckButton>;

/**
 * 下拉框
 */
interface ISelectorSync {
  type: "select";
  data: ISelectItem[] | string[];
  listcacheid: string;
  config?: ISelectOptionConfig;
  onChange?: ICallback<any>;
}

interface ISelectorAsync {
  listcacheid: string;
  type: "select-async";
  queryConfig: (params: any, body: any) => any;
  config?: ISelectOptionConfig;
  onChange?: ICallback<any>;
}

type ISelector = XOR<ISelectorSync, ISelectorAsync>;

type IItem4 = XOR<IItem3, ISelector>;

interface ITimer {
  type: "timer";
}

type IItem5 = XOR<IItem4, ITimer>;

/**
 * 高级搜索配置
 */
export type IRenderConfig = IItem5 & IBasic & IRoleAuth;
