import { ISelectItem } from "@/common/interface";

export const SelectPlayer: ISelectItem[] = [
  { name: "ORIGINAL_H5_PLAYER", id: 1 },
];

export const SelectWidth: ISelectItem[] = [
  { name: "FIXED_WIDTH", id: 1 }, //固定宽度
  { name: "CUSTOM_WIDTH", id: 2 }, //自定义宽度
];

export const SelectWidthSize: ISelectItem[] = [
  { name: "1080px", id: 1 },
  { name: "1280px", id: 2 },
  { name: "1440px", id: 3 },
];

export const SelectPercentum: ISelectItem[] = [
  { name: "60%", id: 1 },
  { name: "80%", id: 2 },
  { name: "100%", id: 3 },
];

export const BillingWay: ISelectItem[] = [
  { name: "TRAFFIC", id: 1 },
  { name: "PEAK_BANDWIDTH", id: 2 },
  { name: "BROADCAST_TIME", id: 3 },
];

export const Switch: ISelectItem[] = [
  { name: "OPEN", id: 1 },
  { name: "CLOSE", id: 0 },
];

export const Radio: ISelectItem[] = [
  {name:"-",id:0},
  { name: "TOP_LEFT_CORNER", id: 1 },
  { name: "TOP_RIGHT_CORNER", id: 2 },
  { name: "LOWER_LEFT_CORNER", id: 3 },
  { name: "LOWER_RIGHT_CORNER", id: 4 },
];

export const aspectRatio: ISelectItem[] = [
  { name: "16:9", id: "16X9" },
  { name: "4:3", id: "4X3" },
];

export const LiveType:ISelectItem[] = [
  {name:"RTMP",id:0},
  {name:"SRT",id:1}
]
