import { IMenuLv2 } from "@/common/layout/sider/interface";

/**token */
export type ITokenStr = string;

export enum ELogin {
  /**初始状态 */
  UNSET = "usr-unSet",
  /** 登录有效 */
  LOGINSU = "usr-loginsu",
  /** 登录失效 */
  LOGINOUT = "usr-loginout",
}

/** 用户类型 */
export enum IUserType {
  CUSTOMER = "customer",
  ADMIN = "admin",
  SUPERADMIN = "super_admin",
  SALE = "sale",
}

export interface IPermissionTreeNodes {
  id: number;
  parentId: number;
  icon: string;
  language: string;
  routeAddress: string;
  name: string;
  isChild: number;
  childTreeNodeList?: IPermissionTreeNodes[];
}

export interface IPermissionOption {
  id: number;
  name: string;
  uri: string;
  icon: string;
  parentId: number;
  isChild: 1 | 0;
}

export type IPermissions = IPermissionOption | IPermissionTreeNodes;

/**用户详情 */
export interface IAccountInfo {
  createDate: string;
  email: string;
  id: number;
  locale: string;
  name: string;
  password: string | null;
  permissions: string[];
  phone: string;
  roleCodes: IUserType[];
  roleNames: string[];
  status: number;
  timezone: string;
  username: string;
  lastLoginDate: string;
  permissionTreeNodes: IMenuLv2[];
  permissionUri: string[];
}
