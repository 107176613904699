import useIntlDep from "./useIntlDep";


const useJoiner = () => {
    const loadtext = useIntlDep();

    const joiner = (msgList: TmsgList[]) => {
        return msgList.map((t) => t.intl ? loadtext({ id: t.id }) : t.id).join("")
    }

    return joiner
}
export default useJoiner

type TmsgList = { intl: boolean, id: string }