import {
  ICallback,
  IHocModule,
  ISelectItem,
  ISelectOptionConfig,
} from "@/common/interface";
import resourceService from "@/resource";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import SelectP from "../form/SelectP";
import { IAsyncConfig } from "./interface";
import { reqAndRunCallback } from "../utils/utils";
import { useAsyncState } from "@/hooks/useAsyncState";

interface IProps extends IAsyncConfig {
  onChange?: ICallback<any>;
  size?: "large" | "small";
  emptyOption?: boolean;
  placeholder?: string;
  config?: ISelectOptionConfig;
  style?: React.CSSProperties;
  /** 用于附加静态值 */
  staticData?: ISelectItem[];
  tipId?: number;
  disabled?: boolean;
}

/**
 * 接口数据可以缓存
 * @param props
 */
export const AsyncStatic: FC<
  IProps & { loader?: any; children: (data: any) => React.ReactNode }
> = (props) => {
  const [optList, setOptList] = useState<any[]>([]);

  useEffect(() => {
    if (optList.length < 1) {
      const config = props.queryConfig({}, {});
      const data$ = resourceService.pipe(config, props.loader);
      const sub = data$.subscribe((res: string | any[]) => {
        if (Array.isArray(res)) {
          const staticData = props.staticData || [];
          setOptList(res.concat(staticData));
        }
      });
      return () => sub.unsubscribe();
    }
  }, [props, optList]);

  return <>{props.children(optList)}</>;
};
/**
 * 接口数据仅在这个页面缓存
 * @param props
 */
const AsyncModule: FC<IProps & IHocModule> = (props) => {
  const { current: queryConfig } = useRef(props.queryConfig);
  const { current: staticDataProps } = useRef(props.staticData);
  const { current: loader } = useRef(props.loader);
  const [optList, setOptList] = useAsyncState([]);
  useEffect(() => {
    let isUnmount = false;
    reqAndRunCallback(queryConfig({}, {}), (res) => {
      if (res && !isUnmount) {
        setOptList(res);
      }
    });
    return () => {
      isUnmount = true;
    };
  }, [queryConfig, setOptList]);

  const { T, queryConfig: _, loader: _2, ...restProps } = props;
  const _data = useMemo(() => {
    if (!optList) {
      return staticDataProps || [];
    }
    const _optList = loader ? loader(optList) : optList;
    return _optList.concat(staticDataProps || []);
  }, [staticDataProps, optList, loader]);
  return <T {...restProps} data={_data} />;
};

export const AsyncSelect: FC<IProps> = (props) => {
  return <AsyncModule {...props} T={SelectP} />;
};
