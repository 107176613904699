import { IFormComponent } from "@/common/interface";
import { reqAndRunCallback } from "@/common/utils/utils";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail from "@/hooks/useUserDetail";
import fileAPI from "@/store/apis/lookup/file";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Image, Modal, notification, Space, Upload } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
interface IProps {
  accept?: string;
  maxCount?: number;
  config?: {};
}
const formData = new FormData();
const UploadPicOnlyOne: FC<IFormComponent & IProps> = ({
  onChange,
  maxCount = 1,
  accept,
  value,
}) => {
  const [flag, setFlag] = useState<boolean>(true);
  const { current: change } = useRef(onChange);
  const loadtext = useIntlDep();
  const [fileData, setFileData] = useState<string>();
  const [zhanshi, setZhanshi] = useState<string>("");
  const userInfo = useUserDetail();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  useEffect(() => {
    if (value) {
      setFileData(value);
    }
  }, [value]);
  const changeFile = useCallback(
    (data: { file: any }) => {
      if (!flag) {
        return null;
      }
      formData.append("file", data.file);
      setFileData("");
      const config = fileAPI.uploadPic({ userId: userInfo?.id }, formData);
      reqAndRunCallback(config, (res) => {
        const imgUrl = `${res.path}`;
        change?.(imgUrl);
        setFileData(res.url);
        setZhanshi(res.url);
        formData.delete("file");
      });
    },
    [flag, change, userInfo]
  );
  const beforeUpload = useCallback(
    (data: { size: number; type: string }) => {
      setFlag(true);
      const isJPG = data.type === "image/jpg";
      const isJPEG = data.type === "image/jpeg";
      const isGIF = data.type === "image/gif";
      const isPNG = data.type === "image/png";
      if (!(isJPG || isJPEG || isGIF || isPNG)) {
        setFlag(false);
        notification.error({
          message: loadtext({ id: "IMAGE_FORMAT" }),
          duration: 2,
        });
        return false;
      }
      const isLt3M = data.size / 1024 / 1024 < 3;
      if (!isLt3M) {
        setFlag(false);
        notification.error({
          message: loadtext({ id: "IMAGE_SIZE" }),
          duration: 2,
        });
        return false;
      }
      return false;
    },
    [loadtext]
  );

  const previewImage = useCallback(() => {
    // console.log(fileData);
    setPreviewVisible(true);
  }, []);

  return (
    <Space size="large">
      {/* <Col> */}
      <Upload
        maxCount={maxCount}
        accept={accept}
        onChange={changeFile}
        beforeUpload={beforeUpload}
        fileList={[]}
      >
        <Button icon={<UploadOutlined />}>Select the file</Button>
      </Upload>
      {/* </Col> */}
      {(zhanshi || fileData) && (
        <>
          <Button type="link" onClick={previewImage}>
            {loadtext({ id: "PREVIEW" })}
          </Button>
          <Modal
            visible={previewVisible}
            title={loadtext({ id: "PREVIEW_IMAGE" })}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
          >
            {/* {`${window.location.protocol}${fileData}`} */}
            <Image
              style={{ width: "100%" }}
              alt={fileData}
              src={`${window.location.protocol}${zhanshi || fileData}`}
            />
          </Modal>
        </>
      )}
    </Space>
  );
};

export default UploadPicOnlyOne;
export const isValidUrl = (string: string) => {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(string);
};
