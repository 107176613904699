import useIntlDep from "@/hooks/useIntlDep";
import { CopyFilled } from "@ant-design/icons";
import { notification, Tooltip } from "antd";
import { CSSProperties, FC, ReactNode, useCallback, useMemo } from "react";
import { IFormComponent } from "../interface";

interface IProps{
    // 复制提醒文字
    title?: string;
    // 文本宽度
    width?: number;
    // 提示文案
    tip?: ReactNode;
    // 实际复制文案 默认为value的值
    copyContext?: string;
}

const ClipBoardContext: FC<IFormComponent & IProps> = props => {
    const title = props.title || "EMPTY"
    const width = props.width;
    const loadText = useIntlDep();
    const tip = props.tip;
    const value = props.value;
    const copyContext = props.copyContext;
    const copyValue = useMemo(() => {
        return copyContext || value;
    }, [value, copyContext])

    const copy = useCallback(() => {
        const selection = window.getSelection();
        if(selection){
            const inputEl = document.createElement("input");
            inputEl.setAttribute("value", copyValue);
            document.body.append(inputEl);
            inputEl.select();
            const successful = document.execCommand("copy");
            document.body.removeChild(inputEl);
            if (successful) {
                notification.success({
                    message: loadText({ id: "CLIP_SUCCESS", values: {
                            name: title
                        }}),
                    duration: 0.7
                })
            }
        }
    }, [loadText, title, copyValue])

    const style = useMemo(() => {
        if(width){
            const maxWidth = width - 20
            const _style: CSSProperties = {
                maxWidth,
                display: "inline-block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                verticalAlign: "top",
            }
            return _style;
        }
        return undefined;
    }, [width])

    return <Tooltip title={tip}>
        <span style={style} onDoubleClick={copy}>
            {props.value}
        </span>
        <CopyFilled className="comp-clip-icon" onClick={copy} />
    </Tooltip>
}

export default ClipBoardContext;
