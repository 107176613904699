import webSiteInfoService from "@/store/webSiteInfo/service";
import { useEffect, useState } from "react";
import { ISiteInfo } from "./interface";

/**
 * 当前网站基本信息
 */
const useWebSiteInfo = () => {
  const [state, setState] = useState<ISiteInfo | null>(
    webSiteInfoService.siteInfo$.value
  );
  useEffect(() => {
    webSiteInfoService.siteInfo$.subscribe((value) => {
      setState(value);
    });
  }, []);
  return state;
};

export default useWebSiteInfo;
 