import { IRenderConfig } from "@/common/fastRender";
import ViewForm from "@/common/form/view";
import ModalID from "@/common/modal/id";
import Template from "@/common/template";
import { IColumnsTypeP, IOperationConfig } from "@/common/template/interface";
import { ColCostomerLiveStatus } from "@/common/utils/columns";
import { reqAndRunCallback, reqDeleteCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useEventId, { getEventId } from "@/hooks/useEventId";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail, { useIsAdmin } from "@/hooks/useUserDetail";
import { IUserType } from "@/store/account/interface";
import userAPI from "@/store/apis/account/user";
import domainApi from "@/store/apis/node/domain";
import liveAPI from "@/store/apis/node/live";
import messageService from "@/store/messages";
import reqServicePlx from "@/store/request/reqServicePlx";
import { Button, Form, notification, Spin } from "antd";
import Layout from "antd/lib/layout/layout";
import { AxiosRequestConfig } from "axios";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { from } from "rxjs";
import AddForm from "./addForm";
import FooterP from "./components/FooterP";
import View from "./form/view";
import { dataformat, formatData } from "./formatData";
import { INodeTypeSelect } from "./interface";
import { default as liveService, default as LiveService } from "./Service";
import { reloadLiveTemp } from "./store";
import { normalBtns } from "./table";
import { transformOptions } from "@/common/utils/utilsX";
import { LiveType } from "./form/selectData";
export const TempId = getEventId();
const LiveStreaming: FC = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const formId = useEventId();
  const tableId = useEventId();
  const userInfo = useUserDetail();
  const [liveId, setLiveId] = useState<number>(0); //直播id
  const [mode, setMode] = useState<number>(0); //是否修改
  const [userId, setUserId] = useState<number>(0); //用户id
  const [current, setCurrent] = useState<number>(0); //表单下标
  const IndText = useIntlDep();
  const [pushDomain, setPushDomain] = useState<any>([]);
  const [pullDomain, setPullDomain] = useState<any>([]);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  // 表单查询参数和接口
  const query = useCallback((params: any) => {
    const baseSearchForm = {
      page: params.page,
      pageSize: params.pageSize,
      desc: params?.desc,
      sort: params?.sort,
      keyword: params?.keyword,
    };
    const _params = { ...params, baseSearchForm };
    delete _params.pageSize;
    delete _params.page;
    delete _params.keyword;
    return liveAPI.LiveList({}, _params);
  }, []);
  /**
   * 是否是管理员账户
   */
  const isAdmin = useIsAdmin();
  // 表单数据完成后执行函数处理最终数据和数据提交
  const onFinish = useCallback(
    (values: any) => {
      if (isSubmited) {
        return null;
      }
      console.log("onFinish前", values);
      const data = formatData({ ...values });
      console.log("onFinish后", data);
      if (!userInfo) {
        return null;
      }
      let _data;
      if (!isAdmin) {
        _data = {
          ...(data as any),
          userId: userId ? userId : userInfo.id,
        };
      } else {
        _data = data;
      }
      let config;
      if (mode === 1) {
        config = liveAPI.Update({}, { ..._data, id: liveId }); //hls流开关隐藏，但是提交数据时依旧为开
      } else {
        config = liveAPI.Create({}, _data);
      }
      setIsSubmited((into: boolean) => {
        return true;
      });
      from(reqServicePlx(config)).subscribe((res) => {
        if (res.isSuccess) {
          messageService.createEvent(formId);
          reloadLiveTemp();
          setMode(0);
        }
        setIsSubmited((into: boolean) => {
          return false;
        });
      });
    },
    [formId, userInfo, liveId, mode, userId, isAdmin, isSubmited]
  );
  // 表单下一步事件
  const nextSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        if (current === 3) {
          let data = form.getFieldsValue();
          if (data.countdownDate) {
            // 进行时间转换
            data.countdownDate = data.countdownDate.format("YYYY-MM-DD HH:mm");
          }
          onFinish(data);
          setUserId(0);
        } else {
          setCurrent(current + 1);
        }
      })
      .catch((errorInfo) => {
        const { errorFields } = errorInfo;
        if (current === 0) {
          try {
            errorFields.forEach((item: any) => {
              if (
                [
                  "appName",
                  "pushStreamingDomain",
                  "pullStreamingDomain",
                ].includes(item.name[0])
              ) {
                const erroeMessage = { message: "校验不通过" };
                throw erroeMessage;
              } else {
                setCurrent(current + 1);
              }
            });
          } catch (e) {}
        }
        if (current === 1) {
          try {
            errorFields.forEach((item: any) => {
              if (
                ["transcodingId", "cutSliceTime", "aspectRatio"].includes(
                  item.name[0]
                )
              ) {
                const erroeMessage = { message: "校验不通过" };
                throw erroeMessage;
              } else {
                setCurrent(current + 1);
              }
            });
          } catch (e) {}
        }
        if (current === 2) {
          try {
            errorFields.forEach((item: any) => {
              if (
                [
                  "wideValue",
                  "heightValue",
                  "playMicroLabelAddress",
                  "microLabelLocation",
                ].includes(item.name[0])
              ) {
                const erroeMessage = { message: "校验不通过" };
                throw erroeMessage;
              } else {
                setCurrent(current + 1);
              }
            });
          } catch (e) {}
        }
      });
  }, [current, form, onFinish]);
  // 关闭表单事件
  const deleteForm = useCallback(() => {
    form.resetFields();
    setCurrent(0);
    setMode(0);
    setUserId(0);
    setIsSubmited(false);
  }, [form]);
  // 表单上一步或取消事件
  const goCancel = useCallback(() => {
    // 修改时，禁止进入直播新增
    if (mode === 1 && current === 1) {
      notification.warning({
        message: IndText({ id: "LIVE_AND_DOMAIN_NOT_MODIFIED" }), //提示修改时不能修改直播名称和域名
        duration: 0.7,
      });
      return;
    }
    // 取消
    if (mode === 0 && current === 0) {
      messageService.createEvent(formId);
      setIsSubmited((res) => {
        return false;
      });
      return;
    }
    // 修改时表单打开在视频源和转码设置
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(0);
    }
  }, [current, mode, formId, IndText]);

  useEffect(() => {
    if (!isAdmin && userInfo) {
      const config = domainApi.chartDomainFlow(
        {},
        { userId: userInfo.id, types: [1] }
      );
      reqAndRunCallback(config, (res) => {
        setPushDomain(res);
      });
      const configs = domainApi.chartDomainFlow(
        {},
        { userId: userInfo.id, types: [2] }
      );
      reqAndRunCallback(configs, (res) => {
        setPullDomain(res);
      });
    }
  }, [isAdmin, userInfo]);
  // 表单头部文字
  const title: string = useMemo(() => {
    if (current === 3) {
      return "MANAGED_PAGE_SETTINGS";
    }
    if (current === 2) {
      return "EMBED_PLAYER_SETTINGS";
    }
    if (current === 1) {
      return "VIDEO_SOURCE_AND_TRANSCODING_SETTINGS";
    }
    return "LIVE_IN_NEW";
  }, [current]);

  const filters: IRenderConfig[] = [
    {
      text: "SERVER_NAME",
      type: "input",
      name: "appName",
    },
    {
      text: "CUSTOMER",
      type: "select-async",
      name: "userId",
      queryConfig: userAPI.findAll,
      listcacheid: "user-list-customer-live",
      role: [IUserType.ADMIN, IUserType.SUPERADMIN],
      onChange: (e: any) => {
        const config = domainApi.chartDomainFlow({}, { userId: e, types: [1] });
        reqAndRunCallback(config, (res) => {
          setPushDomain(res);
        });
        const configs = domainApi.chartDomainFlow(
          {},
          { userId: e, types: [2] }
        );
        reqAndRunCallback(configs, (res) => {
          setPullDomain(res);
        });
      },
    },
    {
      text: "LIVE_OR_SOURCE",
      type: "select",
      name: "pushStreamingDomainId",
      data: pushDomain,
      listcacheid: "push-streaming",
    },
    {
      text: "BROADCAST_DOMAINS",
      type: "select",
      name: "streamingDomainId",
      data: pullDomain,
      listcacheid: "pull-streaming",
    },
    {
      text: "STATUS",
      type: "select",
      name: "liveStatus",
      data: INodeTypeSelect,
      listcacheid: "live-status",
    },
  ];

  // 列表操作事件集合
  const optList: IOperationConfig = [
    [
      {
        text: "ENTER", //进入
        event: ({ id }) => {
          navigate(`/live-management/info`, {
            state: {
              id,
            },
          });
        },
      },
      {
        text: "VIEW", //查看
        event: (data) => {
          const config: AxiosRequestConfig = liveAPI.Detail(
            { id: data.id },
            {}
          );
          reqAndRunCallback(config, (res) => {
            if (res) {
              const _data = dataformat(res, data);
              messageService.view(tableId, _data);
            }
          });
        },
      },
    ],
    [
      {
        text: "CONFIGURATION", // 配置
        event: (data) => {
          setCurrent(1);
          const config = liveAPI.Detail({ id: data.id }, {});
          reqAndRunCallback(config, (res) => {
            if (res) {
              const _data = dataformat(res, data);
              liveService.setliveWideType(res.playerEntity.wideType); //获取到播放器宽度配置项，传递给子组件使用
              setUserId(_data.userId);
              setLiveId(_data.id);
              setMode(1);
              LiveService.setLiveCustomerId(_data.userId);
              form.setFieldsValue(_data);
              messageService.createEvent(formId);
            }
          });
        },
      },
    ],
    [
      {
        text: "COPY", // 复制
        event: (data) => {
          const config: AxiosRequestConfig = liveAPI.Detail(
            { id: data.id },
            {}
          );
          reqAndRunCallback(config, (res) => {
            if (res) {
              const _data = dataformat(res, data);
              const aName = _data.appName;
              _data.appName = "";
              _data.pushStreamingDomainId = null;
              _data.streamingDomainId = null;
              form.setFieldsValue(_data);
              messageService.createEvent(formId);
              notification.success({
                message:
                  IndText({ id: "LIVE" }) +
                  `[${aName}]` +
                  IndText({ id: "CONFIGURING_REPLICATION_SUCCESSFULLY" }),
              });
            }
          });
        },
      },
    ],
    [
      {
        text: "DELETE", // 删除
        event: (data) => {
          const config: AxiosRequestConfig = liveAPI.Delete(
            { id: data.id },
            {}
          );
          reqDeleteCallback(config, data.appName, () => {
            // reqAndRunCallback(config, () => {
            messageService.reloadList(TempId);
            // });
          });
        },
      },
    ],
  ];
  // 列表显示表头
  const columns: IColumnsTypeP<any>[] = [
    {
      key: "serverName",
      title: "SERVER_NAME",
      dataIndex: "appName",
      render: (item: string, row: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate(`/live-management/info`, {
                state: {
                  id: row.id,
                },
              });
            }}
          >
            {item}
          </Button>
        );
      },
    },
    {
      key: "liveType",
      dataIndex: "liveType",
      title: "LIVE_TYPE",
      render: transformOptions(LiveType, false),
    },
    {
      key: "customer",
      title: "CUSTOMER",
      dataIndex: "userName",
      role: [IUserType.ADMIN, IUserType.SUPERADMIN],
    },

    {
      key: "broadcastDomains",
      title: "BROADCAST_DOMAINS",
      dataIndex: "pushStreamingDomain",
    },
    {
      key: "liveOrSource",
      title: "LIVE_OR_SOURCE",
      dataIndex: "streamingDomain",
    },
    {
      key: "createDate",
      title: "CREATION_TIME",
      dataIndex: "createDate",
    },

    ColCostomerLiveStatus(),
  ];
  return (
    <Layout className="stream-page">
      {/* 列表 */}
      <Template
        queryData={query}
        filter={filters}
        search
        normalBtns={normalBtns(formId)}
        id={TempId}
        rowId="id"
        config={columns}
        optList={optList}
      />
      {/* 表单弹窗 */}
      <ModalID
        id={formId}
        title={<IntlDep id={title} />}
        size="large"
        afterClose={deleteForm}
      >
        <Spin spinning={isSubmited} size="large">
          <Form onFinish={onFinish} layout="vertical" form={form}>
            <AddForm current={current} mode={mode} form={form} />
            {/* {current !== 4 && ( */}
            {/* <FormItem> */}
            <FooterP
              size="large"
              okText={current !== 3 ? "NEXT_STEP" : "APPLY"}
              cancelText={current === 0 ? "" : "PREVIOUS_STEP"}
              submit={nextSubmit}
              cancel={goCancel}
            />
            {/* </FormItem> */}
            {/* )} */}
          </Form>
        </Spin>
        {/* 表单 */}
      </ModalID>
      {/* 查看显示弹窗 */}
      <ViewForm id={tableId} title="SETTINGS" config={View} />
    </Layout>
  );
};

export default LiveStreaming;
