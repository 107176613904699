import { ISearchPage } from "@/common/template/interface";
import { AxiosRequestConfig } from "axios";
import { IDomainCreateForm, IDomainSetCertForm } from "./interface";

/**
 * @class NodeAPI
 * @description
 * @return 返回request的config
 */
class DomainAPI {
  /**
   * doaminList
   * 生成请求参数
   */
  domainList = (params: IDomainListParams, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/domain/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   * findall
   * 生成请求参数
   */
  findAll = (params: IFindAllParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/domain/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  // /**
  //  * chartDomain
  //  * 生成请求参数
  //  */
  // chartDomain = (params: {}, data: {}) => {
  //   const config: AxiosRequestConfig = {
  //     url: "/domain/domain_list",
  //     method: "get",
  //     params,
  //     data,
  //   };
  //   config.headers = {};
  //   return config;
  // };

  /**
   * chartDomain
   * 生成请求参数
   *
   */
  chartDomainFlow = (params: {}, data: IChartDomainFlow) => {
    const config: AxiosRequestConfig = {
      url: "/domain/domain_list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * nodeAdd
   * 生成请求参数
   */
  createDomain = (params: IDomainCreateParams, data: IDomainCreateForm) => {
    const config: AxiosRequestConfig = {
      url: "/domain/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   */
  Detail = (params: IDomainDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/domain/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   */
  Delete = (params: IDomainDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/domain/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  /**
   * userDetail
   * 生成请求参数
   */
  Auth = (params: IDomainAuthParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/domain/auth/url",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  UpdateAuth = (params: IDomainCreateParams, data: IDomainUpdateAuth) => {
    const config: AxiosRequestConfig = {
      url: "/domain/auth/update_auth_info",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * DomainSetCert
   * 生成请求参数
   */
  DomainSetCert = (params: {}, data: IDomainSetCertForm) => {
    const config: AxiosRequestConfig = {
      url: "/domain/set_cert",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
  /**
   *直播预览播放地址
   */
  PlayerUrl = (params: {}, data: IGetPalyUrl) => {
    const config: AxiosRequestConfig = {
      url: "/domain/auth/get_player_url",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  getPlayerAllUrl = (params: {}, data: IGetPalyUrl) => {
    const config: AxiosRequestConfig = {
      url: "/domain/auth/get_all_play_url",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * DomainEnableSsl
   * 生成请求参数
   * 直播开启ssl鉴权
   */
  DomainEnableSsl = (params: IDomainEnableSslForm, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/domain/enable_ssl",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * DomainEnableSsl
   * 生成请求参数
   * 直播关闭ssl鉴权
   */
  DomainDisableSsl = (params: IDomainDisableSslForm, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/domain/disable_ssl",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  // 查询是否存在点播
  /* 获取当前用户点播域名个数 */
  FindDomainVodCounts = (params: IFindDomainVodCountsParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/domain/find_domain_vod_counts",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * GetWhiteInfo
   * 查找白名单信息
   */
  GetWhiteInfo = (params: IGetWhiteInfoParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/domain/auth/get_white_info",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  // PUT 设置白名单信息  /api/domain/auth/set_white_info 传参：

  /**
   * SetWhiteInfo
   * 设置白名单信息
   */
  SetWhiteInfo = (params: {}, data: ISetWhiteInfoData) => {
    const config: AxiosRequestConfig = {
      url: "/domain/auth/set_white_info",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };
}
const domainAPI = new DomainAPI();
export default domainAPI;
interface IDomainEnableSslForm {
  id: number;
}
interface IDomainDisableSslForm {
  id: number;
}
/** list的请求参数*/
interface IDomainListParams {}

/** Detail的请求参数*/
interface IDomainDetailParams {
  id: number;
}
/** Auth的请求参数*/
interface IDomainAuthParams {
  liveId: number;
}
interface IGetPalyUrl {
  liveId: number;
  streamName: string;
}

/** FindAll的请求参数*/
interface IFindAllParams {
  domainId?: number;
}

/** add的请求参数*/
interface IDomainCreateParams {}

/** UpdateAuth的请求参数*/
interface IDomainUpdateAuth {
  liveId: number;
  tokenSwitch: number;
  tokenSecret?: string;
  tokenExpired?: string;
}

/** delete的请求参数*/
interface IDomainDeleteParams {
  id: number;
}

interface IChartDomainFlow {
  userId?: number;
  types?: number[];
}

interface IFindDomainVodCountsParams {
  userId: number;
}

/** Auth的请求参数*/
interface IDomainAuthParams {
  liveId: number;
}

/** Auth的请求参数*/
interface IDomainAuthParams {
  liveId: number;
}

interface IGetWhiteInfoParams {
  liveId: number;
}

interface ISetWhiteInfoData {
  liveId: number;
  whiteListSwitch: number;
  ips?: string[];
}
