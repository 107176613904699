import React from "react";
import LayoutPlx from "@/common/layout";
import lazyLoad from "@/common/helpers/lazyLoad";
import { Outlet, RouteObject } from "react-router-dom";

// 点播
const videoRouter: Array<RouteObject> = [
  {
    element: <LayoutPlx />,
    children: [
      {
        path: "video-on-demand",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: lazyLoad(
              React.lazy(() => import("@/pages/main/pages/VideoDemand/content"))
            ),
          },
          {
            path: "info",
            element: lazyLoad(
              React.lazy(
                () => import("@/pages/main/pages/VideoDemand/videoInfo")
              )
            ),
          },
        ],
      },
    ],
  },
];

export default videoRouter;
