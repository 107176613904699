import ModalP from "@/common/modal";
import IntlDep from "@/hooks/intl/IntlDep";
import useSubscribe from "@/hooks/useSubscribe";
import useVisible from "@/hooks/useVisible";
import EInfoType from "@/store/messages/infoType";
import React, { FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import icon from "./images/delete.png";
import { IDeleteInfo } from "./interface";

const Delete: FC = () => {
  const [visible, switchVisible] = useVisible();

  const [config, setConfig] = useState({
    text: "",
    event: () => {},
  });

  const loadConfirm = useCallback(
    (zconfig: IDeleteInfo) => {
      setConfig(zconfig);
      switchVisible();
    },
    [switchVisible]
  );

  useSubscribe(EInfoType.confirm_delete, loadConfirm);

  return (
    <ModalP
      size="small"
      title={<IntlDep id="DELETE"/>}
      visible={visible}
      onCancel={switchVisible}
      onSubmit={() => {
        config.event();
        switchVisible();
      }}
      okText="DELETE"
      zIndex={7000}
    >
      <div className="comp-global-dialog">
        <img style={{ height: 38 }} src={icon} alt="tip" />
        <p>
          <FormattedMessage
            id="DELETE_Q"
            values={{
              name: config.text,
            }}
          />
        </p>
      </div>
    </ModalP>
  );
};

export default Delete;
