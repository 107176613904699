import { AxiosRequestConfig } from "axios";
import { IAreaAddForm, IAreaSetNodeListForm } from "./interface";

/**
 * @class NodeAPI
 * @description
 * @return 返回request的config
 */
class AreaAPI {
  /**
   * List
   * 生成请求参数
   */
  areaList = (params: IAreaListParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/area/page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * findAll
   * 生成请求参数
   */
  findAll = (params: IFindAllParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/area/list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * nodeAdd
   * 生成请求参数
   */
  areaCreate = (params: IAreaAddParams, data: IAreaAddForm) => {
    const config: AxiosRequestConfig = {
      url: "/area/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * Detail
   * 生成请求参数
   */
  Detail = (params: IAreaDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/area/detail",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  AreaFindNodeList = (params: IAreaDetailParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/area/find_node_list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * Update
   * 生成请求参数
   */
  Update = (params: IAreaUpdateParams, data: IAreaAddForm) => {
    const config: AxiosRequestConfig = {
      url: "/area/update",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * AreaSetDefaultArea
   * 生成请求参数
   */
  AreaSetDefaultArea = (params: IAreaSetDefaultAreaParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/area/set_default_area",
      method: "put",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * AreaSetNodeList
   * 生成请求参数
   */
  AreaSetNodeList = (params: {}, data: IAreaSetNodeListForm) => {
    const config: AxiosRequestConfig = {
      url: "/area/set_node_list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * userDetail
   * 生成请求参数
   */
  Delete = (params: IAreaDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/area/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  AreaNodePage = (params: {}, data: IAreaNodePage) => {
    const config: AxiosRequestConfig = {
      url: "/area_node_relation/page",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  AreaNodeRelationDelete = (
    params: {},
    data: IAreaNodeRelationDeleteParams
  ) => {
    const config: AxiosRequestConfig = {
      url: "/area_node_relation/delete",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
  GetDefaultArea = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/area/get_default_area",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  // /area_node_relation/find_area_relation_node
  FindArearelationNode = (params: {}, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/area_node_relation/find_area_relation_node",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };



}
const areaAPI = new AreaAPI();
export default areaAPI;

/** list的请求参数*/
interface IAreaListParams {}

/** Detail的请求参数*/
interface IAreaDetailParams {
  id: number;
}

/** Update的请求参数*/
interface IAreaUpdateParams {}

/** add的请求参数*/
interface IAreaAddParams {}

/** delete的请求参数*/
interface IAreaDeleteParams {}

/**FindAll的请求参数 */
interface IFindAllParams {}

interface IAreaSetDefaultAreaParams {
  areaId: number;
}
interface IAreaNodePage {
  yrecord: string | undefined;
  baseSearchForm: baseSearchForm;
}
interface baseSearchForm {
  page: number;
  pageSize: number;
  keyword: string;
}

interface IAreaNodeRelationDeleteParams {
  areaId: number;
  id: number;
  nodeId: number;
  nodeType: string;
  yrecord: string;
}
