import React, { FC } from "react"
import Confirm from "./confirm";
import Delete from "./delete";
import ClipModal from "@/common/clipboard/modal";
import "./index.less";
import UploadModule from "@/common/confirm/upload";

const GlbModule:FC = () => {
    return <>
        <Delete />
        <Confirm />
        <ClipModal />
        <UploadModule />
    </>
}

export default GlbModule;
