import { BehaviorSubject, from } from "rxjs";
import lookAPI from "../apis/lookup/lookup";
import reqServicePlx from "../request/reqServicePlx";
import { ISiteInfo } from "./interface";
/**
 * 获取网站基本信息
 */
class WebSiteInfo {
  /**站点信息 */
  readonly siteInfo$ = new BehaviorSubject<ISiteInfo | null>(null);
  /**
   * 引导页是否显示
   */
  readonly showSiteGuide$ = new BehaviorSubject<boolean>(true);
  // constructor() {
    // v2不获取网站信息
    // this.queryInitSiteBasic();
  // }
  /**
   * @param status 引导页功能是否全部完成，完成则隐藏
   */
  modifySiteGuideStatus = (status: boolean) => {
    this.showSiteGuide$.next(status);
  };
  /**
   * 获取网站基本信息
   */
  queryInitSiteBasic = () => {
    const config = lookAPI.getSystemBasicInfo({}, {});
    from(reqServicePlx<any>(config, false)).subscribe((res) => {
      if (res.isSuccess) {
        this.siteInfo$.next(res.result);
      }    
    });
  };
}

const webSiteInfoService = new WebSiteInfo();
export default webSiteInfoService;
