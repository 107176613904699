import IntlDep from "@/hooks/intl/IntlDep";
import { Button, Modal } from "antd";
import React, { FC, useMemo } from "react";
import { INormalEvent, ITrigger } from "../interface";
import FooterP from "./footer";

interface IModal {
  size?: "small" | "normal" | "large" | "extra_large";
  title?: React.ReactNode | string;
  visible: boolean;
  onCancel: ITrigger;
  /** 没有submit函数表示隐藏foot */
  onSubmit?: ITrigger;
  okText?: string;
  zIndex?: number;
  afterClose?: ITrigger;
  destroyOnClose?: boolean;
  submitBtns?: INormalEvent[];
  width?: number | string;
  showFotterBtn?: boolean;
  children: React.ReactNode;
}


const ModalP: FC<IModal> = (props) => {
  const { destroyOnClose = true, size, showFotterBtn = true } = props;

  const width = useMemo(() => {
    if (props.width) {
      return props.width;
    }
    if (size === "large") {
      return 1200;
    }
    if (size === "small") {
      return 780;
    }
    if (size === "extra_large") {
      return 1250;
    }
    return 1080;
  }, [size, props.width]);

  let btnsEl: React.ReactNode[] = [];
  if (props.submitBtns) {
    btnsEl = props.submitBtns.map((btn, idx) => {
      return (
        <Button key={idx} onClick={btn.event}>
          <IntlDep id={btn.text} values={btn.textValue} />
        </Button>
      );
    });
  }
  return (
    <Modal
      title={props.title || ""}
      width={width}
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      maskClosable={false}
      zIndex={props.zIndex || 1000}
      destroyOnClose={destroyOnClose}
      afterClose={props.afterClose}
    >
      {props.children}
      {showFotterBtn && (
        <FooterP
          size="large"
          submit={props.onSubmit}
          cancel={props.onCancel}
          okText={props.okText}
        >
          {btnsEl}
        </FooterP>
      )}
    </Modal>
  );
};

export default ModalP;
