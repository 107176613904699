import { ISearchPage } from "@/common/template/interface";
import { AxiosRequestConfig } from "axios";
import { IVodDomainRelationForm } from "./interface";

/**
 * @class vod
 * @description
 * @return 返回request的config
 * 点播分发域名接口类
 */
class VodDomainRelationAPI {
  /**
   * vodList
   * 生成请求参数
   * 点播分发域名列表
   */
  FindPage = (params: IVodDomainRelationListParams, data: ISearchPage) => {
    const config: AxiosRequestConfig = {
      url: "/vod/domain/relation/list",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * Create
   * 生成请求参数
   * 点播分发域名新增
   */
  Create = (
    params: IVodDomainRelationCreateParams,
    data: IVodDomainRelationForm
  ) => {
    const config: AxiosRequestConfig = {
      url: "/vod/domain/relation/add",
      method: "post",
      params,
      data,
    };
    config.headers = {};
    config.headers["Content-Type"] = "application/json";
    return config;
  };

  /**
   * vodDetail
   * 生成请求参数
   * 点播分发域名详情
   */
  // Detail = (params: IVodDomainRelationDetailParams, data: {}) => {
  //   const config: AxiosRequestConfig = {
  //     url: "/vod/domain/relation/detail",
  //     method: "get",
  //     params,
  //     data,
  //   };
  //   config.headers = {};
  //   return config;
  // };

  /**
   * vodDomainList
   * 生成请求参数
   * 当前用户创建点播域名列表
   */
  vodDomainList = (params: IVodDomainListParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/vod/domain/relation/domain_list",
      method: "get",
      params,
      data,
    };
    config.headers = {};
    return config;
  };

  /**
   * vodDelete
   * 生成请求参数
   * 点播分发域名删除
   */
  Delete = (params: IVodDomainRelationDeleteParams, data: {}) => {
    const config: AxiosRequestConfig = {
      url: "/vod/domain/relation/delete",
      method: "delete",
      params,
      data,
    };
    config.headers = {};
    return config;
  };
}
const vodDomainRelationAPI = new VodDomainRelationAPI();
export default vodDomainRelationAPI;

interface IVodDomainRelationListParams {}
// interface IVodDomainRelationDetailParams {
//   id: number;
// }
interface IVodDomainRelationCreateParams {}
interface IVodDomainRelationDeleteParams {}
interface IVodDomainListParams {
  userId?: number;
}
