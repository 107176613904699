import FormItem from "@/common/form/FormItem/item";
import SelectP from "@/common/form/SelectP";
import { reqAndRunCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import recordAPI from "@/store/apis/node/record";
import { Col, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import NodesShowForm from "./NodesShowForm";
const AssociatedNode: FC = () => {
  const [yRecord, setYRecord] = useState<string>("");
  const [yRecordData, setYRecordData] = useState([]);
  useEffect(() => {
    const config = recordAPI.YRecordFindAll({}, {});
    reqAndRunCallback(config, (res) => {
      console.log(res);
      setYRecordData(res);
      res.forEach((item: any) => {
        if (item.key === "缺省节点组") {
          setYRecord(item.value);
        }
      });
    });
  }, []);

  return (
    <section style={{width:800}}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          {yRecord && (
            <FormItem label="NODE_GROUP" name="yrecord" initialValue={yRecord}>
              <SelectP
                data={yRecordData}
                config={{
                  idKey: "value",
                  textKey: "key",
                }}
                disabled
              />
            </FormItem>
          )}
        </Col>
        <Col span={24}>
          <div style={{ marginBottom: 8 }}>
            <Row wrap={false} gutter={20}>
              <Col span={12}>
                <FormattedMessage id="EDGE_NODE" />:
              </Col>
              <Col span={12}>
                <FormattedMessage id="SELECTED" />:
              </Col>
            </Row>
          </div>
          <FormItem
            name="edgeNodes"
            rules={[
              {
                required: true,
                message: (
                  <IntlDep
                    id="A_B"
                    values={{ name1: "EDGE_NODE", name2: "IS_MANDATORY" }}
                  />
                ),
              },
            ]}
          >
            <NodesShowForm type="edge" />
          </FormItem>
        </Col>
        <Col span={24} style={{marginBottom:20}}>
          <div style={{ marginBottom: 8 }}>
            <Row wrap={false} gutter={20}>
              <Col span={12}>
                <FormattedMessage id="ORIGIN_NODE" />:
              </Col>
              <Col span={12}>
                <FormattedMessage id="SELECTED" />:
              </Col>
            </Row>
          </div>
          <FormItem
            name="originNodes"
            rules={[
              {
                required: true,
                message: (
                  <IntlDep
                    id="A_B"
                    values={{ name1: "ORIGIN_NODE", name2: "IS_MANDATORY" }}
                  />
                ),
              },
            ]}
          >
            <NodesShowForm type="origin" />
          </FormItem>
        </Col>
      </Row>

      {/* 注释回源转发节点 */}
      {/* <div style={{ marginBottom: 8 }}>
        <Row wrap={false} gutter={20}>
          <Col span={12}>
            <FormattedMessage id="BACK_TO_THE_SOURCE_FORWARDING_NODE" />:
          </Col>
          <Col span={12}>
            <FormattedMessage id="SELECTED" />:
          </Col>
        </Row>
      </div> */}
      {/* <FormItem name="middleNodes">
        <NodesShowForm type="middle" />
      </FormItem> */}
    </section>
  );
};

export default AssociatedNode;
