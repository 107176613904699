// import { ISelectItem } from "@/common/interface";
import { reqAndRunCallback } from "@/common/utils/utils";
import userAPI from "@/store/apis/account/user";
import { useEffect, useState } from "react";
import { useIsAdmin } from "./useUserDetail";
/**
 *
 * @returns 只有管理员才可以查看，返回值为[所有用户的列表,第一个用户的id]
 */
const useCustomerList = () => {
  const [customerList, setCustomerList] = useState<any[]>();
  const isAdmin = useIsAdmin();
  useEffect(() => {
    if (isAdmin) {
      const config = userAPI.findAll({}, {});
      reqAndRunCallback(config, (res) => {
        if (Array.isArray(res)) {
          setCustomerList(res);
        }
      });
    }
  }, [isAdmin]);
  return customerList;
};

export default useCustomerList;
