import { IFormComponent, ITrigger } from "@/common/interface";
import React, { FC } from "react";
import IconClose from "./images/close.png";
// import { IFormComponent } from "../interface";
import IconOpen from "./images/open.png";
import "./index.less";

const CheckButton: FC<
  IFormComponent & {
    true: number | string;
    false: number | string;
    disable?: boolean;
    onClick?: ITrigger;
  }
> = (props) => {
  const click = () => {
    if (props.disable) {
      return;
    }
    if(props.onClick){
      props.onClick()
    }
    if (props.onChange) {
      props.onChange(!(props.value === props.true) ? props.true : props.false);
    }
  };

  const cls = props.disable ? "cdn-check-btn" : "cdn-check-btn cdn-pt-hover";
  return (
    <div className={cls} onClick={click}>
      {props.value === props.true ? (
        <img src={IconOpen} alt="check-true" />
      ) : (
        <img src={IconClose} alt="check-false" />
      )}
    </div>
  );
};

export default CheckButton;
