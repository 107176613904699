// import SelectP from "@/common/form/SelectP";
import FormP from "@/common/FormP";
import ModalID from "@/common/modal/id";
// import { langList } from "@/common/timezoneList";
import { reqAndRunCallback } from "@/common/utils/utils";
import IntlDep from "@/hooks/intl/IntlDep";
import useEventId from "@/hooks/useEventId";
import useIntlDep from "@/hooks/useIntlDep";
import useUserDetail from "@/hooks/useUserDetail";
import AddForm from "@/pages/main/pages/LiveStreaming/addForm";
import FooterP from "@/pages/main/pages/LiveStreaming/components/FooterP";
import { formatData } from "@/pages/main/pages/LiveStreaming/formatData";
import { reloadLiveTemp } from "@/pages/main/pages/LiveStreaming/store";
import FormDistributionDomain from "@/pages/main/pages/VideoDemand/SettingsManagement/DistributionDomain/form";
import {  IUserType } from "@/store/account/interface";
// import accountService from "@/store/account/service";
// import userAPI from "@/store/apis/account/user";
import liveAPI from "@/store/apis/node/live";
import vodDomainRelationAPI from "@/store/apis/node/vodDomain";
import messageService from "@/store/messages";
import { Form, Steps } from "antd";
// import { AxiosRequestConfig } from "axios";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
const CustomerGuide: FC = () => {
  const [current, setCurrent] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const formId = useEventId();
  const domainId = useEventId();
  const IntlDepText = useIntlDep();
  const [form] = Form.useForm();
  const userInfo = useUserDetail();
  /* 生成ReactNode函数 */
  const createButton = (
    str1: string,
    str2: string,
    str3: string,
    num: number
  ) => {
    return num !== 1 ? (
      <>
        <span style={{ fontSize: 12 }}>{str1}</span>
        <span
          style={{
            marginLeft: "5px",
            padding: 0,
            fontSize: 12,
            textDecoration: "underline",
            cursor: "pointer",
            marginRight: 5,
          }}
          onClick={() => createLive(num)}
        >
          {str2}
        </span>

        {str3 !== "" && (
          <>
            <span>/</span>
            <span
              style={{
                marginLeft: "5px",
                padding: 0,
                fontSize: 12,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => createDemand(num)}
            >
              {str3}
            </span>
          </>
        )}
      </>
    ) : (
      <>
        <span style={{ fontSize: 12 }}>{str1}</span>
        <span
          style={{
            marginLeft: "5px",
            padding: 0,
            fontSize: 12,
            cursor: "pointer",
            marginRight: 5,
          }}
        >
          {str2}
        </span>

        {str3 !== "" && (
          <>
            <span>/</span>
            <span
              style={{
                marginLeft: "5px",
                padding: 0,
                fontSize: 12,
                cursor: "pointer",
              }}
            >
              {str3}
            </span>
          </>
        )}
      </>
    );
  };
  /*步骤点击函数 */
  const createLive = useCallback(
    (currents: number) => {
      console.log(currents, currentStep);
      if (currents > currentStep) {
        return;
      }
      switch (currents) {
        case 0: {
          messageService.createEvent(formId);
          break;
        }
        case 1: {
          setCurrentStep(2);
          console.log("完成");
          break;
        }
      }
    },
    [formId, currentStep]
  );
  /* 创建点播 */
  const createDemand = useCallback(
    (num: number) => {
      messageService.createEvent(domainId);
    },
    [domainId]
  );
  // 表单数据完成后执行函数处理最终数据和数据提交
  const onFinish = useCallback(
    (values: any) => {
      const data = formatData({ ...values });
      if (!userInfo) {
        return null;
      }
      let _data = { ...data, userId: userInfo.id };
      const config = liveAPI.Create({}, _data);
      console.log(config);
      reqAndRunCallback(config, () => {
        messageService.createEvent(formId);
        reloadLiveTemp()
        setCurrentStep(2);
      });
    },
    [formId, userInfo]
  );

  /* 步骤条生成数据 */
  const steps = [
    // 创建边缘节点
    {
      title: createButton(
        `${IntlDepText({ id: "FIRST_STEP" })}`,
        `${IntlDepText({ id: "LIVE" })}`,
        `${IntlDepText({ id: "ON_DEMAND" })}`,
        0
      ),
      description: `${IntlDepText({ id: "CREATING_EDGE_NODES" })}`,
    },
    {
      title: createButton(
        `${IntlDepText({ id: "SECOND_STEP" })}`,
        `${IntlDepText({ id: "COMPLETE" })}`,
        "",
        1
      ),
      description: `${IntlDepText({ id: "COMPLETE" })}`,
    },
  ];
  // 表单头部文字
  const title: string = useMemo(() => {
    if (current === 3) {
      return "MANAGED_PAGE_SETTINGS";
    }
    if (current === 2) {
      return "EMBED_PLAYER_SETTINGS";
    }
    if (current === 1) {
      return "VIDEO_SOURCE_AND_TRANSCODING_SETTINGS";
    }
    return "LIVE_IN_NEW";
  }, [current]);
  // 关闭表单事件
  const deleteForm = useCallback(() => {
    form.resetFields();
    setCurrent(0);
  }, [form]);
  // 表单下一步事件
  const nextSubmit = useCallback(() => {
    if (current === 3) {
      let data = form.getFieldsValue();
      if (data.countdownDate) {
        // 进行时间转换
        data.countdownDate = data.countdownDate.format("YYYY-MM-DD HH:mm");
      }
      onFinish(data);
    } else {
      setCurrent(current + 1);
    }
  }, [current, form, onFinish]);
  // 表单上一步或取消事件
  const goCancel = useCallback(() => {
    if (current === 0) {
      messageService.createEvent(formId);
    }
    // 修改时表单打开在视频源和转码设置
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(0);
    }
  }, [current, formId]);

  const submitDomain = useCallback(
    (data: any) => {
      if (userInfo) {
        const _data = {
          ...data,
          userId: userInfo.roleCodes.includes(IUserType.CUSTOMER)
            ? userInfo.id
            : data.userId,
        };
        const config = vodDomainRelationAPI.Create({}, _data);
        reqAndRunCallback(config, () => {
          messageService.createEvent(domainId);
          setCurrentStep(2);
        });
      }
    },
    [userInfo, domainId]
  );
  // const changeLanguage = useCallback(
  //   (data: any) => {
  //     if (!userInfo) {
  //       return;
  //     }
  //     const language = {
  //       locale: data
  //     }
  //     const _data: IAccountInfo & { password: any } = { ...userInfo, ...language };
  //     delete _data.password;
  //     const config: AxiosRequestConfig = userAPI.Update({}, _data);
  //     reqAndRunCallback(config, () => {
  //       accountService.RefreshLanguage();
  //     });
  //   },
  //   [userInfo]
  // );
  return (
    <section
      style={{
        padding: "10px 40px",
        backgroundColor: "#fff",
        marginBottom: 10,
      }}
      className="stream-page-row stream-page"
    >
      {/* <Row >
        <Col span={20}></Col>
        <Col span={4}>
          <Col span={5}>
            <SelectP style={{ width: 150, borderRadius: 8 }} defaultValue={userInfo?.locale} enableintl={false} data={langList} onChange={changeLanguage} />
          </Col>
        </Col>
      </Row> */}
      <p style={{ fontSize: 12 }}>
        <FormattedMessage id="DEAR_USERS_CREATE_LIVE_OR_DOMAIN" />
      </p>
      <Steps
        className="stepsBox"
        current={currentStep}
        labelPlacement="vertical"
      >
        {steps.map((item) => (
          <Steps.Step key={item.description} title={item.title} />
        ))}
      </Steps>
      {/* 表单弹窗 */}
      <ModalID
        id={formId}
        title={<IntlDep id={title} />}
        size="large"
        afterClose={deleteForm}
      >
        {/* 表单 */}
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <AddForm current={current} form={form} />
          {current !== 4 && (
            <FooterP
              size="large"
              okText={current !== 3 ? "NEXT_STEP" : "APPLY"}
              cancelText={current === 0 ? "" : "PREVIOUS_STEP"}
              submit={nextSubmit}
              cancel={goCancel}
            />
          )}
        </Form>
      </ModalID>
      <FormP
        title="DISTRIBUTION_OF_DOMAIN_NAME"
        disableTitleFix
        id={domainId}
        submitEvent={submitDomain}
      >
        <FormDistributionDomain id={domainId} />
      </FormP>
    </section>
  );
};

export default CustomerGuide;
