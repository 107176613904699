import { reqAndRunCallback } from "@/common/utils/utils";
import userAPI from "@/store/apis/account/user";
import { useCallback, useEffect, useState } from "react";
// import "../utils/re";
interface UseReCaptchaReturn {
  reCaptchaLoaded: boolean;
  generateReCaptchaToken: (action: string) => Promise<string>;
}
const windows: any = window;
// const RECAPTCHA_KEY = "6LdOq-4lAAAAAPOI_SS3F77ze-SRYstYS7bXclQ3";
const RECAPTCHA_TOKEN = "RECAPTCHA_TOKEN";
const showBadge = () => {
  if (!windows.grecaptcha) {
    return;
  }
  windows.grecaptcha.ready(() => {
    const badge = document.getElementsByClassName(
      "grecaptcha-badge"
    )[0] as HTMLElement;
    if (!badge) return;
    badge.style.display = "block";
    badge.style.zIndex = "1";
  });
};

const hideBadge = () => {
  if (!windows.grecaptcha) {
    return;
  }
  windows.grecaptcha.ready(() => {
    const badge = document.getElementsByClassName(
      "grecaptcha-badge"
    )[0] as HTMLElement;
    if (!badge) return;
    badge.style.display = "none";
  });
};

const useReCaptcha = (): UseReCaptchaReturn => {
  const [reCaptchaLoaded, setReCaptchaLoaded] = useState<boolean>(false);
  const [recaptchaKey, setRecaptchaKey] = useState<string>();
  useEffect(() => {
    const config = userAPI.findRecaptchaKey({}, {});
    reqAndRunCallback(config, (res) => {
      setRecaptchaKey(res);
    });
  }, []);
  useEffect(() => {
    if (typeof windows === "undefined") return;
    if (windows.grecaptcha) {
      showBadge();
      setReCaptchaLoaded(true);
      return;
    }
    const script = document.createElement("script");
    script.async = true;

    if (recaptchaKey) {
      if (reCaptchaLoaded) {
        script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
      } else {
        script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${recaptchaKey}`;
      }
    }
    script.addEventListener("load", () => {
      setReCaptchaLoaded(true);
      showBadge();
    });
    document.body.appendChild(script);
  }, [reCaptchaLoaded, recaptchaKey]);

  useEffect(() => hideBadge, []);

  const generateReCaptchaToken = useCallback(
    (action: string): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        if (!reCaptchaLoaded) return reject(new Error("ReCaptcha not loaded"));
        if (typeof windows === "undefined" || !windows.grecaptcha) {
          setReCaptchaLoaded(false);
          return reject(new Error("ReCaptcha not loaded"));
        }
        if (recaptchaKey) {
          windows.grecaptcha.ready(() => {
            windows.grecaptcha
              .execute(recaptchaKey, { action })
              .then((token: string) => {
                sessionStorage.setItem(RECAPTCHA_TOKEN, token);
                resolve(token);
              });
          });
        }
      });
    },
    [reCaptchaLoaded, recaptchaKey]
  );

  return { reCaptchaLoaded, generateReCaptchaToken };
};

export default useReCaptcha;
