import { FC, useEffect, useState } from "react";
import { Layout } from "antd";
import { useSideWidth } from "@/hooks/useLayoutWidth";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { IMenuLv2 } from "./interface";
import CompMenu from "./menu";
import { filterMenusList } from "@/common/utils/utils";
import "./menu.less";
import SubMenu from "./subMenu/subMenu";
const AntSide = Layout.Sider;
interface IProps {
  list: IMenuLv2[];
}
const Sider: FC<IProps> = (props) => {
  const sidewidth = useSideWidth();
  const location = useLocation();
  const [menus, setMenus] = useState<IMenuLv2[]>(props.list);
  useEffect(() => {
    const newMenus = props.list.filter(
      (menu) => menu.routeAddress !== "personal"
    );
    setMenus(filterMenusList(newMenus));
  }, [props.list]);
  const isSelected = useCallback(
    (menu: any) => {
      return (
        !!menu.routeAddress && location.pathname.indexOf(menu.routeAddress) > -1
      );
    },
    [location]
  );
  if (props.list.length < 1) {
    return null;
  }

  return (
    <div>
      <AntSide
        width={sidewidth}
        style={{ backgroundColor: "#fff" }}
        className="stream-side cdn-scroll"
      >
        <ol className="stream-lv1-ol">
          {menus.map((menu) => {
            if (
              !menu.childTreeNodeList ||
              (menu.childTreeNodeList && !menu.childTreeNodeList.length)
            ) {
              return (
                <CompMenu
                  key={menu.routeAddress}
                  {...(menu as any)}
                  selectd={isSelected(menu)}
                />
              );
            }
            return (
              <SubMenu key={menu.name} menu={menu} isSelected={isSelected} />
            );
          })}
        </ol>
      </AntSide>
    </div>
  );
};

export default Sider;
