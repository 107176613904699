import { ISelectItem } from "@/common/interface";

export enum INodeTypeE {
  EDGE = "edge", // 直播边缘节点
  MIDDLE = "middle", // 直播回源转发节点
  ORIGIN = "origin", // 直播源节点
  // EDGEPUSH = "edge-push", // 边缘推流节点
  VODORIGIN = "vod-origin", // 点播源节点
  VODTRANSCODE = "vod-transcode", // 点播转码节点
}

export const INodeTypeSelect: ISelectItem[] = [
  { id: INodeTypeE.EDGE, name: "LIVE_EDGE_NODE" }, // 直播边缘节点
  { id: INodeTypeE.MIDDLE, name: "LIVE_BROADCAST_BACK" }, // 直播回源z转发节点
  { id: INodeTypeE.ORIGIN, name: "LIVE_ORIGIN" }, // 直播源节点
  // { id: INodeTypeE.EDGEPUSH, name: "LIVE_TRANSCODING_NODE" }, // 边缘推流节点
  { id: INodeTypeE.VODORIGIN, name: "HLS_DISTRIBUTES_THE_SOURCE_NODE" }, // HLS分发源节点
  { id: INodeTypeE.VODTRANSCODE, name: "VOD_TRANSCODING_NODE" }, // 点播转码节点
];

export const CONST_NODE_LINETYPE_LIST: ISelectItem[] = [
  {
    id: 1,
    name: "MAINLAND_CHINA_ROUTE",
  },
  {
    id: 2,
    name: "INTERNATIONAL_ROUTE",
  },
];

export enum IIDC {
  AWS = "AWS", 
  GOOLE = "Goole", 
  IBM = "IBM", 
  ALIYUN = "Aliyun", 
  MICROSOFT = "Microsoft", 
}

export const IIDCSelect: ISelectItem[] = [
  { id: INodeTypeE.EDGE, name: "AWS" }, 
  { id: INodeTypeE.MIDDLE, name: "GOOLE" }, 
  { id: INodeTypeE.ORIGIN, name: "IBM" }, 
  { id: INodeTypeE.VODORIGIN, name: "ALIYUN" }, 
  { id: INodeTypeE.VODTRANSCODE, name: "MICROSOFT" }, 
];

export const ISliceTimeSource:ISelectItem[] = [
  {id:1,name:'USE_TRANSCODER_SETTINGS'},
  {id:2,name:'DATE_CUSTOMIZE'},
]
export const ISliceTimeSourceOnlyPainting:ISelectItem[] = [
  {id:2,name:'DATE_CUSTOMIZE'},
]



