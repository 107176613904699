import { Tag } from "antd";
import { FormattedMessage } from "react-intl";
import { IColumnsTypeP } from "../template/interface";
import { getStatusProps } from "./utils";

// 运行状态
export const NodeStatusEnum = [
  {
    id: 0,
    name: "UNERECTED", //未安装
    color: "#F0B16A",
  },
  // f50
  {
    id: 1,
    name: "ENABLE", //启用
    color: "#96DFB0",
  },
  {
    id: 2,
    name: "INSTALL_IN_PROGRESS", //安装中
    color: "#91d5ff",
  },
  {
    id: 3,
    name: "INSTALLATION_FAILED", //安装失败
    color: "#f50",
  },
];

/**
 * 启用禁用1,0,-1
 */
export const ColNodeStatus: (width?: number) => IColumnsTypeP<any> = (
  width = 90
) => ({
  key: "status",
  title: "STATUS",
  dataIndex: "status",
  fixed: "left",
  width: 150,
  render: (text: any) => {
    const item = getStatusProps(text, NodeStatusEnum);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});

export const ColNodeStatusNodeInstall: (
  width?: number
) => IColumnsTypeP<any> = (width = 90) => ({
  key: "status",
  title: "STATUS",
  dataIndex: "status",
  width,
  render: (text: any) => {
    const item = getStatusProps(text, NodeStatusEnum);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});
// 运行状态
export const CostomerStatusEnum = [
  {
    id: 0,
    name: "DISABLE",
    color: "#f50",
  },
  {
    id: 1,
    name: "ENABLE",
    color: "#96DFB0",
  },
];

/**
 * 客户管理启用禁用1,0
 */
export const ColCostomerStatus: (width?: number) => IColumnsTypeP<any> = (
  width = 75
) => ({
  key: "status",
  title: "STATUS",
  dataIndex: "status",
  width,
  render: (text: any) => {
    const item = getStatusProps(text, CostomerStatusEnum);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});

// 运行状态
export const CostomerStatusLive = [
  {
    id: 0,
    name: "DISABLE",
    color: "#f50",
  },
  {
    id: 1,
    name: "NOT_PUSH_FLOW",
    color: "#f0ad4e",
  },
  {
    id: 2,
    name: "PUSHING_FLOW",
    color: "#87d068",
  },
];

/**
 * 直播状态:1正常,0禁用，2推流中
 */
export const ColCostomerLiveStatus: (width?: number) => IColumnsTypeP<any> = (
  width = 75
) => ({
  key: "status",
  title: "STATUS",
  dataIndex: "liveStatus",
  width,
  render: (text: any) => {
    const item = getStatusProps(text, CostomerStatusLive);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});

/**
 * 入站状态:1正常,0禁用，2推流中
 */
export const ColLiveInboundStatus: (width?: number) => IColumnsTypeP<any> = (
  width = 75
) => ({
  key: "status",
  title: "INBOUND",
  dataIndex: "liveStatus",
  width,
  fixed: "left",
  render: (text: any) => {
    const item = getStatusProps(text, CostomerStatusLive);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});

// 运行状态
export const RecordStatusEnum = [
  {
    id: -1,
    name: "DISABLE",
    color: "#f50",
  },
  {
    id: 0,
    name: "ABNORMAL",
    color: "#F0B16A",
  },
  {
    id: 1,
    name: "NORMAL",
    color: "#96DFB0",
  },
];

/**
 * 启用禁用1,0,-1
 */
export const ColRecordStatus: (width?: number) => IColumnsTypeP<any> = (
  width = 75
) => ({
  key: "status",
  title: "STATUS",
  dataIndex: "status",
  width,
  render: (text: any) => {
    const item = getStatusProps(text, RecordStatusEnum);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});

// 点播状态运行状态
export const VideoStatusEnum = [
  {
    id: 0,
    name: "DISABLE",
    color: "#f50",
  },
  {
    id: 1,
    name: "ENABLE",
    color: "#96DFB0",
  },
];

/**
 * 点播状态启用禁用1,0,-1
 */
export const ColVideoStatus: (width?: number) => IColumnsTypeP<any> = (
  width = 75
) => ({
  key: "status",
  title: "STATUS",
  dataIndex: "status",
  width,
  render: (text: any) => {
    const item = getStatusProps(text, VideoStatusEnum);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});

// 运行状态
export const VodDomainRelationEnum = [
  {
    id: 0,
    name: "DISABLE",
    color: "#f50",
  },
  {
    id: 1,
    name: "ENABLE",
    color: "#96DFB0",
  },
];

/**
 * 点播域名管理（分发域名）ssl是否开启
 */
export const ColVodDomainRelationStatus: (
  width?: number
) => IColumnsTypeP<any> = (width = 75) => ({
  key: "sslEnable",
  title: "SSL",
  dataIndex: "sslEnable",
  width,
  render: (text: any) => {
    const item = getStatusProps(text, VodDomainRelationEnum);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});

// 运行状态
export const DomainSslStatusEnum = [
  {
    id: 0,
    name: "DISABLE",
    color: "#f50",
  },
  {
    id: 1,
    name: "ENABLE",
    color: "#96DFB0",
  },
];

/**
 * 域名管理ssl启用禁用1,0
 */
export const DomainSslStatus: (width?: number) => IColumnsTypeP<any> = (
  width = 75
) => ({
  key: "sslEnable",
  title: "WHETHER_TO_ENABLE_SSL",
  dataIndex: "sslEnable",
  width,
  render: (text: any) => {
    const item = getStatusProps(text, CostomerStatusEnum);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});

// 是否缺省
export const AreaDefaultEnum = [
  {
    id: 0,
    name: "NO",
    color: "#f50",
  },
  {
    id: 1,
    name: "YES",
    color: "#96DFB0",
  },
];

/**
 * 是否缺省0,1
 */
export const ColAreaDefault: (width?: number) => IColumnsTypeP<any> = (
  width = 75
) => ({
  key: "defaultArea",
  title: "WHETHER_THE_DEFAULT",
  dataIndex: "defaultArea",
  fixed: "right",
  width,
  render: (text: any) => {
    if (!text) {
      return (
        <Tag color={"#f50"}>
          <FormattedMessage id={"NO"} />
        </Tag>
      );
    }
    return (
      <Tag color={"#96DFB0"}>
        <FormattedMessage id={"YES"} />
      </Tag>
    );
  },
});

// 是否缺省
export const RecordDefaultEnum = [
  {
    id: 0,
    name: "NO",
    color: "#f50",
  },
  {
    id: 1,
    name: "YES",
    color: "#96DFB0",
  },
];

/**
 * 节点组是否缺省0,1
 */
export const ColRecordDefault: (width?: number) => IColumnsTypeP<any> = (
  width = 75
) => ({
  key: "defaultYRecord",
  title: "WHETHER_THE_DEFAULT",
  dataIndex: "defaultYRecord",
  width,
  render: (text: any) => {
    if (!text) {
      return (
        <Tag color={"#f50"}>
          <FormattedMessage id={"NO"} />
        </Tag>
      );
    }
    return (
      <Tag color={"#96DFB0"}>
        <FormattedMessage id={"YES"} />
      </Tag>
    );
  },
});

// 直播拉流配置运行状态
export const LivePullFlowConfigStatusEnum = [
  {
    id: 0,
    name: "DISABLE",
    color: "#f50",
  },
  {
    id: 1,
    name: "ENABLE",
    color: "#96DFB0",
  },
];

/**
 * 直播拉流配置启用禁用1,0,-1
 */
export const LivePullFlowConfigStatus: (
  width?: number
) => IColumnsTypeP<any> = (width = 75) => ({
  key: "status",
  title: "STATUS",
  dataIndex: "status",
  width,
  render: (text: any) => {
    const item = getStatusProps(text, LivePullFlowConfigStatusEnum);
    if (!item) {
      return "-";
    }
    return (
      <Tag color={item.color}>
        <FormattedMessage id={item.name} />
      </Tag>
    );
  },
});
