import React, { useEffect, memo, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { INext, IRouterProps, IFrom } from "./interface";
let from: IFrom;
const BeforRouter = ({ beforeEach, children }: IRouterProps) => {
  const navigate = useNavigate();
  const { pathname: path, ...rest } = useLocation();
  const next: INext = (to) => {
    if (to && typeof to === "string") {
      to.startsWith("/") ? navigate(to) : navigate(`${path}/${to}`);
    }
  };
  useEffect(() => {
    beforeEach({ ...rest, path }, next, from);
    return () => {
      from = { ...rest, path };
    };
  });
  if (!children) {
    throw new Error("未传入路由");
  }
  return <Fragment> {children && children}</Fragment>;
};
export default memo(BeforRouter);
