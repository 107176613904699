import { RouteObject, useRoutes } from "react-router-dom";
import live from "./live";
import app from "./app";
import monitoring from "./monitoring";
import video from "./video";
import publicRouter from "./publicRouter";
import { Redirect } from "@/common/utils/utilsX";
import { FC } from "react";
// import React from "react";

// const NotFundPage = React.lazy(() => import("@/pages/notFundPage");



const allRouter: RouteObject[] = [
  ...live,
  ...app,
  ...monitoring,
  ...video,
  ...publicRouter,
  // {
  //   path: "404",
  //   element: <NotFundPage />,
  // },
  {
    path: "*",
    element: <Redirect to="live-management" />,
  },
];

const AllRouterP: FC = () => {
  return useRoutes(allRouter);
};

export default AllRouterP;
